import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import PersonIcon from '@mui/icons-material/Person';
import Tune from '@mui/icons-material/Tune';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsContentKey } from '@/components/sections/settings/SettingsView.interface';
import SettingsListItem from '@/components/sections/settings/preferences/SettingsListItem';

interface ProfileMenuProps {
  handleItemClick: (item: SettingsContentKey) => void;
}

const SettingsMenu: React.FC<ProfileMenuProps> = ({ handleItemClick }) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined">
      <List>
        <SettingsListItem
          icon={<PersonIcon />}
          secondary={t('settings.details.description')}
          onClick={() => handleItemClick('details')}
          primary={t('settings.details.title')}
        />
        <Divider variant="middle" />
        <SettingsListItem
          icon={<Tune />}
          secondary={t('settings.preference.description')}
          onClick={() => handleItemClick('preference')}
          primary={t('settings.preference.title')}
        />
        <Divider variant="middle" />
        <SettingsListItem
          icon={<NotificationsPausedIcon />}
          secondary={t('settings.notifications.description')}
          onClick={() => handleItemClick('notifications')}
          primary={t('settings.notifications.title')}
        />
      </List>
    </Card>
  );
};

export default SettingsMenu;

import Chip from '@mui/material/Chip';
import React from 'react';

import {
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { ReceiptOrderResult } from '@/services/receiptService.interface';
import { addressFormatter } from '@/utils/addressFormatter';
import { formatDate } from '@/utils/date-utils/formatDate';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { terminalMap } from '@/utils/posMapping';

const ReceiptHeader: React.FC<{ receiptDetails: ReceiptOrderResult }> = ({
  receiptDetails,
}) => {
  const { orderDate, barcode, store, terminalId } = receiptDetails;

  return (
    <>
      <StyledTitle sx={{ textAlign: 'center' }}>
        {formatDate(orderDate)}
      </StyledTitle>
      <StyledDescription sx={{ textAlign: 'center' }}>
        {emptyValueReplacement(barcode?.displayValue)}
      </StyledDescription>
      <StyledDescription sx={{ textAlign: 'center' }}>
        {emptyValueReplacement(store.name)}
      </StyledDescription>
      <StyledDescription sx={{ textAlign: 'center' }}>
        {addressFormatter(store)}
      </StyledDescription>
      {terminalMap.get(terminalId) && (
        <StyledDescription sx={{ textAlign: 'center', marginTop: '.5rem' }}>
          {' '}
          <Chip
            component="span"
            label={terminalMap.get(terminalId)}
            size="small"
          />{' '}
        </StyledDescription>
      )}
    </>
  );
};

export default ReceiptHeader;

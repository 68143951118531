import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ReceiptTypeChip from '@/components/sections/loyalty/receipts/ReceiptTypeChip';
import DetailItem from '@/components/shared/mui-helpers/DetailItem';
import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';
import { StyledTitle } from '@/components/shared/mui-helpers/customComponentStyles';
import theme from '@/configs/themeConfig';
import { ReceiptSummaryResult } from '@/services/receiptService.interface';
import { formatDateWithTimestamp } from '@/utils/date-utils/formatDateWithTimestamp';
import { currencyFormatter } from '@/utils/priceFormatter';

interface ReceiptItemProps {
  receipt: ReceiptSummaryResult;
  isSelected: boolean;
  onSelect: (receiptId: string) => void;
}

const ReceiptListItem: React.FC<ReceiptItemProps> = ({
  receipt,
  isSelected,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <HoverListItem
      onClick={() => onSelect(receipt.id)}
      sx={{
        backgroundColor: isSelected
          ? alpha(theme.palette.primary.main, 0.1)
          : 'inherit',
        borderRadius: '4px',
        padding: '8px',
        cursor: 'pointer',
      }}
    >
      <Stack
        direction="column"
        spacing={1}
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledTitle>
            {`${receipt.storeName} - ${currencyFormatter(receipt.amount, receipt.currency)}`}
          </StyledTitle>
          <ReceiptTypeChip type={receipt.type} />
        </Box>

        <DetailItem
          label={t('generic.dateLabel')}
          value={formatDateWithTimestamp(receipt.date)}
        />
        <DetailItem
          label={t('receipt.discountLabel')}
          value={currencyFormatter(receipt.memberDiscount, receipt.currency)}
        />
      </Stack>
    </HoverListItem>
  );
};

export default ReceiptListItem;

import { ArrowBack, ContentCopy, Error } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import React, { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '@/utils/copyToClipboard';

interface FallbackProps {
  error: Error;
}

const Fallback: FC<FallbackProps> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Stack spacing={4} alignItems="center" sx={{ gap: 4 }}>
        <Error sx={{ fontSize: 64, mb: 2 }} color="primary" />

        <Typography variant="h4">
          {t('errorBoundary.title', 'Oh snap, something went wrong!')}
        </Typography>

        <Accordion
          elevation={0}
          sx={{
            maxWidth: '100vw',
            width: '960px',
            minWidth: '20vw',
            margin: 3,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            sx={{ backgroundColor: '#e06c75', color: 'white' }}
            aria-controls="error-content"
            id="error-header"
          >
            <Typography>
              {t('errorBoundary.errorDetails', 'Error details')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#313131', color: 'white' }}>
            <Box
              display="flex"
              sx={{ marginBottom: 2 }}
              justifyContent="space-between"
            >
              <Box display="flex" sx={{ gap: 4 }}>
                <Box>
                  <Typography variant="overline">
                    {t('errorBoundary.errorType', 'Type')}
                  </Typography>
                  <Typography>{error.name}</Typography>
                </Box>

                <Box>
                  <Typography variant="overline">
                    {t('errorBoundary.errorMessage', 'Message')}
                  </Typography>
                  <Typography>{error.message}</Typography>
                </Box>
              </Box>

              <Button
                variant="contained"
                sx={{ backgroundColor: '#e06c75' }}
                startIcon={<ContentCopy />}
                onClick={() =>
                  copyToClipboard(
                    `${error.name} - ${error.message}\n${error.stack}\nCause: ${error.cause}`,
                  )
                }
              >
                {t('errorBoundary.copyToClipboard', 'Copy to clipboard')}
              </Button>
            </Box>

            <Paper
              elevation={3}
              sx={{ padding: 2, marginTop: 2, backgroundColor: '#1e1e1e' }}
            >
              <Typography
                variant="h6"
                sx={{ color: '#e06c75', marginBottom: 2 }}
              >
                {t('errorBoundary.errorStackTrace', 'Stack trace')}
              </Typography>
              <Box
                component="pre"
                sx={{
                  backgroundColor: '#1e1e1e',
                  padding: 2,
                  borderRadius: 1,
                  overflowX: 'auto',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  color: '#d4d4d4',
                  lineHeight: '1.5',
                }}
              >
                <code>{error.stack || error.message || error.toString()}</code>
              </Box>
            </Paper>
            {Boolean(error.cause) && (
              <Typography sx={{ fontFamily: 'Monospace' }}>
                {JSON.stringify(error.cause)}
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>

        <Box display="flex" sx={{ gap: 2 }}>
          {Boolean(history.length) && (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => history.back()}
            >
              {t('errorBoundary.goBack', 'Go back to the previous page')}
            </Button>
          )}

          <Button component="a" href="/" variant="contained">
            {t('errorBoundary.goHomepage', 'Return to homepage')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export const AppErrorBoundaryProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

export default AppErrorBoundaryProvider;

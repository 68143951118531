import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SVGProps } from 'react';

interface Props {
  primaryColor?: string;
  themeAware?: boolean;
  borderlessScale?: boolean;
}

export type BrandLogoProps = Props & SVGProps<SVGSVGElement>;

export type BrandIconProps = Omit<Props, 'borderlessScale'> &
  Omit<SvgIconProps, 'color'>;

export const DEFAULT_BRAND_LOGO_HEIGHT = 36;

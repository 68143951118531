import { useAccount } from '@azure/msal-react';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import React, { JSX, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsContentKey } from '@/components/sections/settings/SettingsView.interface';
import SettingsMenu from '@/components/sections/settings/menu/SettingsMenu';
import AppNotifications from '@/components/sections/settings/preferences/AppNotifications';
import AppPreference from '@/components/sections/settings/preferences/AppPreference';
import ProfileDetails from '@/components/sections/settings/profile/ProfileDetails';
import { StyledHeader } from '@/components/shared/mui-helpers/customComponentStyles';

const SettingsView: React.FC = () => {
  const [selectedItem, setSelectedItem] =
    useState<SettingsContentKey>('details');
  const account = useAccount();
  const { t } = useTranslation();

  if (!account) {
    throw new Error('No active account found');
  }

  const agentInfo = useMemo(
    () => ({
      name: account.name ?? 'Agent Name',
      roles: account.idTokenClaims?.roles ?? [],
      email: account.username,
      salaryId: String(account.idTokenClaims?.salaryId ?? ''),
    }),
    [account],
  );

  const handleItemClick = (item: SettingsContentKey) => {
    setSelectedItem(item);
  };

  const detailsContent: Record<SettingsContentKey, JSX.Element> = {
    details: <ProfileDetails agentInfo={agentInfo} />,
    preference: <AppPreference />,
    notifications: <AppNotifications />,
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack sx={{ width: '100%', maxWidth: '1440px', padding: 3 }}>
        <Box pb={3}>
          <StyledHeader>{t('settings.title')}</StyledHeader>
        </Box>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={4}>
          <Box sx={{ flex: 1 }}>
            <SettingsMenu handleItemClick={handleItemClick} />
          </Box>
          <Box sx={{ flex: 2 }}>{detailsContent[selectedItem]}</Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SettingsView;

import Box from '@mui/material/Box';
import React from 'react';

import DetailRow from '@/components/sections/loyalty/offers/offer/DetailRow';

interface DetailRowsProps {
  details: { label: string; value: React.ReactNode }[];
}

const DetailRows: React.FC<DetailRowsProps> = ({ details }) => {
  const half = Math.ceil(details.length / 2);
  const leftColumn = details.slice(0, half);
  const rightColumn = details.slice(half);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ flex: 1 }}>
        {leftColumn.map(({ label, value }, index) => (
          <DetailRow key={`${index}-${label}`} label={label} value={value} />
        ))}
      </Box>
      <Box sx={{ flex: 1 }}>
        {rightColumn.map(({ label, value }, index) => (
          <DetailRow key={`${index}-${label}`} label={label} value={value} />
        ))}
      </Box>
    </Box>
  );
};

export default DetailRows;

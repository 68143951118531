import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import { BrandKey, isBrandKey } from '@/configs/brandConfig.interface';
import appState from '@/state/appState';
import { SearchType, checkLoyaltyInputType } from '@/utils/checkInputType';

const useSearchHandler = (
  determineSearchType: (input: string) => SearchType,
) => {
  const { settings } = useSettingsContext();
  const { t } = useTranslation();
  const customerBrand = appState.customer.brand.value;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedBrand, setSelectedBrand] = useState<BrandKey>(settings.brand);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.EMAIL);
  const [searchParams] = useSearchParams();
  const [validationError, setValidationError] = useState<string>('');

  useEffect(() => {
    if (customerBrand) {
      setSelectedBrand(customerBrand);
    }
  }, [customerBrand]);

  useEffect(() => {
    const brand = searchParams.get('brand');
    const email = searchParams.get('email');
    const memberId = searchParams.get('memberId');
    const orderId = searchParams.get('orderId');
    const gigyaId = searchParams.get('gigyaId');

    const searchTypes = [
      { value: email, type: SearchType.EMAIL },
      { value: memberId, type: SearchType.MEMBER_ID },
      { value: orderId, type: SearchType.ORDER_ID },
      { value: gigyaId, type: SearchType.GIGYA_ID },
    ];

    if (isBrandKey(brand)) {
      setSelectedBrand(brand);
    }

    const foundSearch = searchTypes.find(({ value }) => Boolean(value));

    if (foundSearch) {
      setSearchQuery(foundSearch?.value ?? '');
      setSearchType(foundSearch.type);
    }

    if (isBrandKey(brand) && foundSearch) {
      const type = checkLoyaltyInputType(foundSearch.value!);
      if (type && type !== SearchType.INVALID) {
        appState.loyaltySearch.setSearch(
          brand,
          foundSearch.value!,
          foundSearch.type,
        );
      } else if (type && type === SearchType.INVALID) {
        setValidationError(t('messages.invalidEmailOrUserID'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchQuery(input);
    const determinedType = determineSearchType(input);
    setSearchType(determinedType);

    if (
      determinedType === SearchType.ORDER_ID ||
      determinedType === SearchType.GIGYA_ID ||
      determinedType === SearchType.MEMBER_ID ||
      determinedType === SearchType.EMAIL
    ) {
      setValidationError('');
    }
  };

  const handleSearchClick = () => {
    if (searchType === SearchType.INVALID) {
      setValidationError(t('messages.invalidEmailOrUserID'));
      return;
    }
    appState.loyaltySearch.setSearch(selectedBrand, searchQuery, searchType);
  };

  return {
    selectedBrand,
    setSelectedBrand,
    searchQuery,
    setSearchQuery,
    searchType,
    setSearchType,
    validationError,
    handleInputChange,
    handleSearchClick,
  };
};

export default useSearchHandler;

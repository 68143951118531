import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

import { TextConstants } from '@/utils/TextConstant';

interface LoaderProps {
  loadingMessage?: string;
}

const Loader: React.FC<LoaderProps> = ({
  loadingMessage = TextConstants.MESSAGES.loading,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <CircularProgress />
      <Typography component="div">{loadingMessage} &hellip;</Typography>
    </Box>
  );
};

export default Loader;

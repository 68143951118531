import { FC } from 'react';

import BRLogo from '@/assets/logos/logo_br.svg?react';
import {
  BrandLogoProps,
  DEFAULT_BRAND_LOGO_HEIGHT,
} from '@/components/shared/logos/props';

const BrandLogoBR: FC<BrandLogoProps> = ({
  themeAware = true,
  primaryColor,
  borderlessScale = false,
  height = DEFAULT_BRAND_LOGO_HEIGHT,
  ...other
}) => {
  return <BRLogo {...other} height={height} />;
};

export default BrandLogoBR;

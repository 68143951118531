import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductHistoryItem from '@/components/sections/ecommerce/details/ProductHistoryItem';
import {
  StyledBorderCard,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { ProductHistory as IProductHistory } from '@/services/orderService.interface';

interface ProductHistoryProps {
  history: IProductHistory[];
  productId: string;
}

const ProductHistory: React.FC<ProductHistoryProps> = ({
  history,
  productId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTitle variant="h6" my={2}>
        {t('orderDetails.history.title')}
      </StyledTitle>
      <StyledBorderCard variant="outlined" sx={{ padding: 2 }}>
        {history.length > 0 ? (
          history.map((historyItem, index) => (
            <React.Fragment key={`${index}-${productId}`}>
              <ProductHistoryItem historyItem={historyItem} />
              {index !== history.length - 1 && (
                <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
              )}
            </React.Fragment>
          ))
        ) : (
          <Typography
            color="primary"
            variant="overline"
            display="block"
            align="center"
            sx={{ marginTop: '1rem' }}
          >
            {t('orderDetails.history.noProductHistory')}
          </Typography>
        )}
      </StyledBorderCard>
    </>
  );
};

export default ProductHistory;

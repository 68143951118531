import { Check, Close, Remove } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Consent as IConsent } from '@/services/customerService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';

interface Props {
  consent?: IConsent;
}

const Consent: FC<Props> = ({ consent }) => {
  const { t } = useTranslation();
  if (!consent) {
    return <Remove sx={{ verticalAlign: 'middle' }} />;
  }

  return (
    <Tooltip
      title={t('customerProgram.consentTooltip', {
        date: consent.lastConsentModified
          ? formatDate(consent.lastConsentModified)
          : '-',
        version: consent.docVersion,
      })}
    >
      {consent.isConsentGranted ? (
        <Check sx={{ verticalAlign: 'middle' }} />
      ) : (
        <Close sx={{ verticalAlign: 'middle' }} />
      )}
    </Tooltip>
  );
};

export default Consent;

const errorHandle = (notificationMessage: string) => {
  throw new Error(notificationMessage);
};

export const requestValidation = (articleId: string, siteIds: string[]) => {
  if (parseInt(articleId) < 0) {
    errorHandle('Negative tal er ikke tilladt!');
  } else if (Number.isNaN(parseInt(articleId))) {
    errorHandle('Ikke muligt at søge på tom Artikelnr');
  }
  if (siteIds?.[0] === undefined) {
    errorHandle('Ingen butikker valgt');
  } else if (siteIds.length > 120) {
    errorHandle('Vælg venligst mindre end 120 butikker');
  }
};

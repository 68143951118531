import { Box, CardContent, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReceiptHeader from '@/components/sections/loyalty/receipts/ReceiptHeader';
import ReceiptItemSkeleton from '@/components/sections/loyalty/receipts/ReceiptItemSkeleton';
import ReceiptOrderLines from '@/components/sections/loyalty/receipts/ReceiptOrderLines';
import ReceiptPayment from '@/components/sections/loyalty/receipts/ReceiptPayment';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { StyledBorderCard } from '@/components/shared/mui-helpers/customComponentStyles';
import { useReceiptDetails } from '@/services/receiptService';
import { ReceiptOrderResult } from '@/services/receiptService.interface';
import appState from '@/state/appState';

interface IReceiptItemProps {
  receiptId: string;
}

const ReceiptDetails: React.FC<IReceiptItemProps> = ({ receiptId }) => {
  const { t } = useTranslation();
  const customerBrand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;

  const [cachedReceipts, setCachedReceipts] = useState<{
    [key: string]: ReceiptOrderResult;
  }>({});

  const {
    data: receiptDetails,
    isLoading,
    isError,
    refetch,
  } = useReceiptDetails(
    receiptId,
    customerDetails?.uid!,
    customerBrand!,
    cachedReceipts[receiptId],
  );

  useEffect(
    () => {
      if (!customerDetails || !customerBrand) {
        console.warn('Missing customerUuid or customerBrand');
        enqueueSnackbar(t('messages.searchCustomerFirst'));
        return;
      }

      if (cachedReceipts[receiptId]) {
        appState.loyalty.currency.value = cachedReceipts[receiptId].currency;
      } else {
        refetch();
      }
    },
    [customerDetails, customerBrand, receiptId, cachedReceipts], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (receiptDetails) {
      appState.loyalty.currency.value = receiptDetails.currency;
      setCachedReceipts((prevState) => ({
        ...prevState,
        [receiptId]: receiptDetails,
      }));
    }
  }, [receiptDetails, receiptId]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
          textAlign: 'center',
        }}
      >
        <ReceiptItemSkeleton />
      </Box>
    );
  }

  if (isError || !receiptDetails) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
          textAlign: 'center',
        }}
      >
        <NoContentMessage infoMessage={t('receipt.noReceiptDetails')} />
      </Box>
    );
  }

  return (
    <Box>
      <StyledBorderCard>
        <CardContent>
          <ReceiptHeader receiptDetails={receiptDetails} />
          <Divider sx={{ my: 2 }} />
          <ReceiptOrderLines orderLines={receiptDetails.orderLines} />
          <Divider sx={{ my: 2 }} />
          <ReceiptPayment payments={receiptDetails.payments ?? []} />
        </CardContent>
      </StyledBorderCard>
    </Box>
  );
};

export default ReceiptDetails;

import Typography from '@mui/material/Typography';
import React from 'react';

import {
  StyledFlexBox,
  StyledTypography,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { currencyFormatter } from '@/utils/priceFormatter';

interface PaymentDetailProps {
  label: string;
  amount: number;
  currency: string;
}

const PaymentDetail: React.FC<PaymentDetailProps> = ({
  label,
  amount,
  currency,
}) => {
  return (
    <StyledFlexBox>
      <StyledTypography>{label}</StyledTypography>
      <Typography>{currencyFormatter(amount, currency)}</Typography>
    </StyledFlexBox>
  );
};

export default PaymentDetail;

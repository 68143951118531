export const TabIds = {
  CUSTOMER_PROFILE: 'customer' as const,
  WALLET: 'wallet' as const,
  MEMBER_OFFERS: 'offers' as const,
  PUNCH_CARDS: 'punchcards' as const,
  RECEIPTS: 'receipts' as const,
};

export const TabNames = {
  CUSTOMER_PROFILE: 'loyaltyTabs.customerProfile' as const,
  WALLET: 'loyaltyTabs.wallet' as const,
  MEMBER_OFFERS: 'loyaltyTabs.memberOffers' as const,
  PUNCH_CARDS: 'loyaltyTabs.punchCards' as const,
  RECEIPTS: 'loyaltyTabs.receipts' as const,
};

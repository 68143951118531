import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import NettoLogo from '@/assets/logos/logo_netto.svg?react';
import {
  BrandLogoProps,
  DEFAULT_BRAND_LOGO_HEIGHT,
} from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#1D2631';

const BrandLogoNetto: FC<BrandLogoProps> = ({
  themeAware = true,
  primaryColor,
  borderlessScale = false,
  height = DEFAULT_BRAND_LOGO_HEIGHT,
  ...other
}) => {
  const theme = useTheme();
  let color = primaryColor ?? DEFAULT_PRIMARY_COLOR;
  if (themeAware && !primaryColor && theme.palette.mode === 'dark') {
    color = '#fff';
  }

  const additionalProps: any = {};
  if (!borderlessScale) {
    additionalProps.viewBox = '0 -18 535 164';
  }

  return (
    <NettoLogo
      {...other}
      height={height}
      style={{ color, ...other.style }}
      {...additionalProps}
    />
  );
};

export default BrandLogoNetto;

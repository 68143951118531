import { computed, effect, signal } from '@preact/signals';

import { ICreditCardDetail } from '@/components/sections/loyalty/credit-cards/ICreditCardDetail';
import {
  CustomerDetailsByBrand,
  OffersData,
} from '@/services/customerService.interface';
import { ReceiptSummaryResult } from '@/services/receiptService.interface';
import { PunchCardResponse } from '@/services/tradeDriverService.interface';
import {
  WalletResult,
  WalletTransactionResult,
} from '@/services/walletService.interface';
import { default as customerState } from '@/state/customerState';
import {
  DEFAULT_REQUEST_STATUS,
  LoyaltyStateInterface,
} from '@/state/loyaltyState.interface';

export const createDefaultLoyaltyState = () => {
  const loyaltyState = {
    customer: {
      data: signal<CustomerDetailsByBrand | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    creditCard: {
      data: signal<ICreditCardDetail[] | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    useWallet: signal(false),
    wallet: {
      data: signal<WalletResult | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    walletTransactions: {
      data: signal<WalletTransactionResult | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    memberOffers: {
      data: signal<OffersData | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    punchCards: {
      data: signal<PunchCardResponse | undefined>(undefined),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    receipts: {
      data: signal<ReceiptSummaryResult[]>([]),
      request: signal<LoyaltyStateInterface>(DEFAULT_REQUEST_STATUS),
    },
    currency: signal<string>(''),
    isFetchingMemberData: computed(
      (): boolean =>
        loyaltyState.customer.request.value.isFetching ||
        loyaltyState.creditCard.request.value.isFetching ||
        loyaltyState.memberOffers.request.value.isFetching ||
        loyaltyState.punchCards.request.value.isFetching ||
        loyaltyState.receipts.request.value.isFetching ||
        loyaltyState.wallet.request.value.isFetching ||
        loyaltyState.walletTransactions.request.value.isFetching,
    ),
  };

  effect(() => {
    loyaltyState.useWallet.value = customerState.brand.value !== 'salling';
  });

  // Reset loyalty data when customer changes
  effect(() => {
    if (loyaltyState.customer.data.value) {
      loyaltyState.creditCard.data.value = undefined;
      loyaltyState.wallet.data.value = undefined;
      loyaltyState.walletTransactions.data.value = undefined;
      loyaltyState.memberOffers.data.value = undefined;
      loyaltyState.punchCards.data.value = undefined;
      loyaltyState.receipts.data.value = [];
    }
  });

  return loyaltyState;
};

const loyaltyState = createDefaultLoyaltyState();

export default loyaltyState;

import { Launch } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tracking } from '@/services/orderService.interface';

interface Props {
  tracking: Tracking;
}

export const ProductTrackingLinksButton: FC<Props> = ({ tracking }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openSelection = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (tracking.trackingUrls.length === 0) {
    return null;
  }

  const hasMultipleTrackingUrls = tracking.trackingUrls.length > 1;

  return (
    <>
      <Button
        id="tracking-button"
        size="small"
        variant="outlined"
        component={hasMultipleTrackingUrls ? Button : Link}
        href={hasMultipleTrackingUrls ? undefined : tracking.trackingUrls[0]}
        target={hasMultipleTrackingUrls ? undefined : '_blank'}
        endIcon={
          hasMultipleTrackingUrls ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <Launch fontSize="small" />
          )
        }
        onClick={hasMultipleTrackingUrls ? handleClick : undefined}
        disableElevation={hasMultipleTrackingUrls}
        aria-controls={openSelection ? 'tracking-menu' : undefined}
        aria-haspopup={hasMultipleTrackingUrls}
        aria-expanded={
          hasMultipleTrackingUrls
            ? openSelection
              ? 'true'
              : 'false'
            : undefined
        }
      >
        <Typography
          variant="button"
          sx={{
            maxWidth: '180px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {t('orderDetails.tracking.trackingUrl')}
        </Typography>
      </Button>
      <Menu
        id="tracking-menu"
        open={openSelection}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'tracking-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {tracking.trackingUrls.map((url, index) => (
          <MenuItem
            key={url}
            component={Link}
            href={url}
            target="_blank"
            disableRipple
            divider={index < tracking.trackingUrls.length - 1}
            onClick={handleClose}
          >
            <ListItemText>Delivery #{index + 1}</ListItemText>
            <Launch fontSize="small" sx={{ marginLeft: 1 }} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProductTrackingLinksButton;

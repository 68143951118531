import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ReceiptListItem from '@/components/sections/loyalty/receipts/ReceiptListItem';
import QuerySuspense from '@/components/shared/QuerySuspense';
import { StyledBorderCard } from '@/components/shared/mui-helpers/customComponentStyles';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { useReceiptsQuery } from '@/services/receiptService';
import { ReceiptSummaryResult } from '@/services/receiptService.interface';
import { containsSearchTerm } from '@/utils/containsSearchTerm';

interface IReceiptListProps {
  receipts: ReceiptSummaryResult[];
  onSelectReceipt: (receiptId: string) => void;
}

const ReceiptList: React.FC<IReceiptListProps> = ({
  receipts,
  onSelectReceipt,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReceipts, setFilteredReceipts] = useState(receipts);
  const [selectedReceiptId, setSelectedReceiptId] = useState(receipts[0]?.id);
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const queryReceiptId = urlParams.get('receiptId');

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useReceiptsQuery(receipts);

  useEffect(() => {
    const allReceipts = data.pages.flat();
    setFilteredReceipts(
      allReceipts.filter((receipt) => containsSearchTerm(receipt, searchTerm)),
    );
    setSelectedReceiptId(queryReceiptId ?? allReceipts[0]?.id);
  }, [queryReceiptId, data, searchTerm]);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        hasNextPage &&
        !isFetchingNextPage &&
        !searchTerm
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, searchTerm],
  );

  const lastReceiptElementRef = useIntersectionObserver(handleObserver, {
    root: null,
    rootMargin: '20px',
    threshold: 0,
  });

  const handleSelectReceipt = (receiptId: string) => {
    setSelectedReceiptId(receiptId);
    onSelectReceipt(receiptId);
  };

  return (
    <Box>
      <TextField
        label={t('receipt.search')}
        variant="outlined"
        sx={{ marginBottom: '1rem' }}
        size="small"
        fullWidth
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value.toLowerCase())}
      />

      <StyledBorderCard
        sx={{
          overflowY: 'auto',
          minHeight: {
            xs: '50vh',
            sm: '70vh',
            md: '100vh',
          },
        }}
      >
        <Stack spacing={1}>
          {filteredReceipts.map((receipt, index) => (
            <div
              key={receipt.id}
              ref={
                index === filteredReceipts.length - 1
                  ? lastReceiptElementRef
                  : null
              }
            >
              <ReceiptListItem
                receipt={receipt}
                isSelected={receipt.id === selectedReceiptId}
                onSelect={handleSelectReceipt}
              />
            </div>
          ))}
          <QuerySuspense
            query={{ isRefetching: isFetchingNextPage }}
          ></QuerySuspense>
          {!hasNextPage && (
            <Typography
              color="primary"
              variant="overline"
              display="block"
              align="center"
              sx={{ marginTop: '1rem' }}
            >
              {t('receipt.noMoreReceipts')}
            </Typography>
          )}
        </Stack>
      </StyledBorderCard>
    </Box>
  );
};

export default ReceiptList;

import { useMutation, useQuery } from '@tanstack/react-query';

import { appConfig } from '@/configs/appConfig';
import {
  CompensationAvailabilityResponse,
  ICompensationRequest,
} from '@/services/compensationService.interface';
import { performADRequest } from '@/utils/request-helpers/apiHelper';

export const ORDER_COMPENSATION_QUERY_KEY = 'order-compensation';

export const useOrderCompensationQuery = (
  enabled: boolean,
  brand: string | undefined,
  orderId: string | undefined,
) => {
  return useQuery({
    queryKey: [ORDER_COMPENSATION_QUERY_KEY, brand, orderId],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: enabled && Boolean(brand && orderId),
    queryFn: () => {
      return performADRequest<CompensationAvailabilityResponse, void>(
        'GET',
        `${appConfig.sgApiNextOrdersUrlV1}orders/${orderId}/compensation-availability`,
      );
    },
  });
};

export const useOrderCompensation = () => {
  return useMutation({
    mutationKey: [ORDER_COMPENSATION_QUERY_KEY],
    mutationFn: (params: {
      brand: string;
      orderId: string;
      request: ICompensationRequest;
    }) => {
      return performADRequest(
        'POST',
        `${appConfig.sgApiNextOrdersUrlV1}orders/${params.orderId}/compensate`,
        params.request,
      );
    },
  });
};

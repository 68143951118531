import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentSetDialogPaymentDetails from '@/components/sections/ecommerce/payment/PaymentSetDialogPaymentDetails';
import QuerySuspense from '@/components/shared/QuerySuspense';
import { usePaymentSetQuery } from '@/services/paymentService';
import { currencyFormatter } from '@/utils/priceFormatter';

interface Props {
  open: boolean;
  onClose: () => void;

  brand?: string | null;
  paymentSetId: string;
}

export const PaymentSetDialog: FC<Props> = ({
  open,
  onClose,
  brand,
  paymentSetId,
}) => {
  const { t } = useTranslation();
  const paymentSetQuery = usePaymentSetQuery(brand, paymentSetId, open);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('payment.dialog.title')}</DialogTitle>

      <QuerySuspense query={paymentSetQuery}>
        <DialogContent sx={{ minWidth: '380px' }}>
          {(paymentSetQuery.isError || paymentSetQuery.isRefetchError) && (
            <Stack spacing={1}>
              <DialogContentText>
                {t('payment.dialog.errorText')}
              </DialogContentText>
              <Button
                variant="outlined"
                onClick={() => paymentSetQuery.refetch()}
              >
                {t('payment.dialog.errorRetryButton')}
              </Button>
            </Stack>
          )}

          {paymentSetQuery.isSuccess && (
            <>
              {paymentSetQuery.data?.payments.map((payment) => (
                <PaymentSetDialogPaymentDetails
                  key={payment.id}
                  payment={payment}
                  currency={paymentSetQuery.data?.currency}
                  defaultExpanded={paymentSetQuery.data?.payments.length <= 1}
                />
              ))}

              <Divider sx={{ marginTop: '1rem', marginBottom: '.5rem' }} />

              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}
              >
                <Typography>{t('payment.dialog.totalAmount')}</Typography>
                <Typography sx={{ fontWeight: 'medium' }} color="primary">
                  {currencyFormatter(
                    paymentSetQuery.data?.amount || 0,
                    paymentSetQuery.data?.currency || 'DKK',
                  )}
                </Typography>
              </Stack>
            </>
          )}
        </DialogContent>
      </QuerySuspense>

      <DialogActions>
        <Button onClick={onClose}>{t('payment.dialog.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentSetDialog;

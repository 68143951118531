import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import theme from '@/configs/themeConfig';

interface InfoMessageProps {
  infoMessage?: string;
  height?: string;
}

const NoContentMessage: React.FC<InfoMessageProps> = ({
  infoMessage,
  height = '10em',
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '5em',
        height: height,
        maxHeight: 'min(90vh, 90vw)',
        flexGrow: 1,
        width: '100%',
        overflow: 'hidden',
        padding: '1em',
        boxSizing: 'border-box',
        color: theme.palette.primary.main,
      }}
    >
      <SearchIcon color="primary" sx={{ fontSize: 64, mb: 2 }} />
      <Typography color="primary" variant="h6" gutterBottom>
        {t('messages.noResults')}
      </Typography>
      <Typography color="primary" variant="body1" sx={{ mb: 3 }}>
        {infoMessage ?? t('messages.trySearching')}
      </Typography>
    </Box>
  );
};

export default NoContentMessage;

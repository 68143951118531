import { useMsal } from '@azure/msal-react';
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomerRemarkMutation } from '@/services/orderService';
import { OrderDetailsResult } from '@/services/orderService.interface';
import { convertToSapDateFormat } from '@/utils/date-utils/convertToSapDateFormat';
import { hashToColor } from '@/utils/hashToColor';

import './CustomerRemarks.css';

interface CustomerRemarksProps {
  anchor: string;
  selectedOrderDetails: OrderDetailsResult;
  onAddedNote?: () => void;
}

const CustomerRemarks: React.FC<CustomerRemarksProps> = ({
  anchor,
  selectedOrderDetails,
  onAddedNote,
}) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState<string>('');
  const [customerRemarks, setCustomerRemarks] = useState<string[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { instance } = useMsal();
  const { t } = useTranslation();

  const customerRemarkMutation = useCustomerRemarkMutation();

  useEffect(() => {
    if (selectedOrderDetails && selectedOrderDetails.customerRemarks) {
      setCustomerRemarks(selectedOrderDetails.customerRemarks);
    }
  }, [selectedOrderDetails]);

  const addCustomerRemark = () => {
    if (inputValue && selectedOrderDetails.brand) {
      const account = instance.getActiveAccount();
      const firstName = account?.name ? account.name.split(' ')[0] : '';
      const email = account?.username ? account.username : '';

      const remarkText = `${convertToSapDateFormat(new Date())} ${email} ${firstName} ${inputValue}`;

      customerRemarkMutation.mutate(
        {
          brand: selectedOrderDetails.brand,
          orderId: selectedOrderDetails.orderNumber,
          //erpOrderNumber in SAP has an additional "00" at the start
          erpOrderNumber: '00' + selectedOrderDetails.erpOrderNumber,
          customerRemarks: [remarkText],
        },
        {
          onSuccess: () => {
            setCustomerRemarks((prevRemarks) => [...prevRemarks, remarkText]);
            onAddedNote?.();
            setInputValue('');
          },
          onError: (error) => {
            enqueueSnackbar(t('orderDetails.remark.error'), {
              variant: 'error',
              autoHideDuration: 3000,
            });
            console.error('Error adding customer remark', error);
          },
        },
      );
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [customerRemarks]);

  return (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350,
      }}
      role="presentation"
    >
      <Stack
        spacing={2}
        sx={{
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          height: '100vh',
        }}
      >
        <List
          sx={{
            overflowY: 'auto',
          }}
        >
          {customerRemarks.map((message, index) => {
            const backgroundColor = hashToColor(
              message === 'string' ? message.substring(13) : '',
            );
            const fontColor = theme.palette.getContrastText(backgroundColor);

            return (
              <ListItem key={`${index}-${message}`}>
                <Box
                  key={index}
                  display="flex"
                  alignItems="flex-start"
                  mt={2}
                  maxWidth={'90%'}
                >
                  <Avatar
                    style={{
                      backgroundColor,
                      marginRight: '2rem',
                    }}
                    src="/broken-image.jpg"
                  />
                  <Box maxWidth={'80%'}>
                    <div
                      className={`speech-bubble ${
                        index % 2 === 0 ? 'dark-blue' : 'blue'
                      }`}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{ overflowWrap: 'break-word', color: fontColor }}
                      >
                        {message}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </ListItem>
            );
          })}
          <div ref={messagesEndRef}></div>
        </List>
        <TextField
          fullWidth
          placeholder={t('orderDetails.remark.addNote')}
          value={inputValue}
          sx={{
            color: 'black',
            background: 'white',
            margin: '0 !important',
            minWidth: '240px',
            maxWidth: '420px',
            position: 'sticky',
            bottom: 2,
          }}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addCustomerRemark();
            }
          }}
          slotProps={{
            input: {
              sx: { color: 'black' },
              endAdornment: (
                <IconButton
                  color="primary"
                  aria-label="Send"
                  onClick={addCustomerRemark}
                >
                  <SendIcon />
                </IconButton>
              ),
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default CustomerRemarks;

import Button from '@mui/material/Button';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosError, HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import React, { FC, PropsWithChildren } from 'react';

import { redirectToADLogin } from '@/utils/request-helpers/authentication';

const queryCache = new QueryCache({
  onError: (error) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === HttpStatusCode.Unauthorized) {
        enqueueSnackbar(t('authentication.sessionIssueSnackbar'), {
          variant: 'warning',
          preventDuplicate: true,
          persist: true,
          autoHideDuration: null,
          action: () => (
            <Button variant="contained" onClick={() => redirectToADLogin()}>
              {t('authentication.login')}
            </Button>
          ),
        });
      }
    }
  },
});
const queryClient = new QueryClient({ queryCache });

const ReactQueryProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;

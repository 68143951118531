import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface CaptionedTextProps {
  caption: string;
  text: string | number | null;
  sx?: React.CSSProperties;
}

const CaptionedText: React.FC<CaptionedTextProps> = ({ caption, text, sx }) => {
  return (
    <Box sx={{ ...sx }}>
      <Typography variant="body2">
        <strong>{caption}:</strong> {emptyValueReplacement(text)}
      </Typography>
    </Box>
  );
};

export default CaptionedText;

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { Role, RoleAccess } from '@/configs/userRolesConfig';
import { getADToken } from '@/utils/request-helpers/authentication';

/**
 * Obtain the Azure AD token and privileges for the currently active account.
 */
export const useAuthorizationHook = () => {
  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const [isEcomAuthorized, setIsEcomAuthorized] = useState<boolean | null>(
    null,
  );
  const [isLoyaltyAuthorized, setIsLoyaltyAuthorized] = useState<
    boolean | null
  >(null);
  const [userRoles, setUserRoles] = useState<Set<Role>>(new Set());

  useEffect(() => {
    const checkRoles = async () => {
      if (!isAuthenticated || inProgress !== InteractionStatus.None) {
        return;
      }

      const account = instance.getActiveAccount();
      if (!account) {
        setIsEcomAuthorized(false);
        setIsLoyaltyAuthorized(false);
        return;
      }

      try {
        const { accessToken } = await getADToken(account);

        const roles = new Set(account.idTokenClaims?.roles ?? []);

        const salaryId = account.idTokenClaims?.salaryId as string | undefined;

        const isEcomUserAuthorized = !roles.isDisjointFrom(
          new Set(RoleAccess.ECOMMERCE),
        );
        const isLoyaltyUserAuthorized = !roles.isDisjointFrom(
          new Set(RoleAccess.LOYALTY),
        );

        setAccessToken(accessToken);
        setIsEcomAuthorized(isEcomUserAuthorized);
        setIsLoyaltyAuthorized(isLoyaltyUserAuthorized);
        setAccountId(salaryId ?? account.localAccountId);
        setUserRoles(roles as Set<Role>);
      } catch (error) {
        console.error(error);
        setAccessToken(null);
        setIsEcomAuthorized(false);
        setIsLoyaltyAuthorized(false);
      }
    };

    if (isAuthenticated) {
      checkRoles();
    }
  }, [isAuthenticated, inProgress, instance]);

  const hasRole = (role: Role) => {
    return userRoles.has(role);
  };

  return {
    isEcomAuthorized,
    isLoyaltyAuthorized,
    accessToken,
    accountId,
    hasRole,
  };
};

import { Box } from '@mui/material';
import Stack from '@mui/system/Stack';
import React, { FC } from 'react';

import CreditCardList from '@/components/sections/loyalty/credit-cards/CreditCardList';
import { ICreditCardDetail } from '@/components/sections/loyalty/credit-cards/ICreditCardDetail';
import Consents from '@/components/shared/customer/Consents';
import CustomerOrganizations from '@/components/shared/customer/CustomerOrganizations';
import PlusMemberships from '@/components/shared/customer/PlusMemberships';
import PlusProfile from '@/components/shared/customer/PlusProfile';
import UserProfile from '@/components/shared/customer/UserProfile';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { BrandKey } from '@/configs/brandConfig.interface';
import { Profile, User } from '@/services/customerService.interface';

interface Props {
  user?: User;
  plusProfile?: Profile;
  creditCards?: ICreditCardDetail[];
  activeBrand?: BrandKey;
}

const CustomerDetails: FC<Props> = ({
  user,
  plusProfile,
  creditCards,
  activeBrand,
}) => {
  if (!user && !plusProfile) {
    return <NoContentMessage height="30em" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 4,
      }}
    >
      <Stack sx={{ maxWidth: '1440px', width: '100%' }} gap={2}>
        <Box display="flex" gap={2} sx={{ flexWrap: 'wrap' }}>
          <Stack sx={{ flex: 1 }} gap={2}>
            <Box
              sx={{
                display: 'flex',
                columnGap: 20,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <UserProfile user={user} />
              <PlusMemberships user={user} activeBrand={activeBrand} />
            </Box>

            <PlusProfile profile={plusProfile} />

            <Consents user={user} />
            <CustomerOrganizations customerDetails={user} />
          </Stack>

          <CreditCardList creditCard={creditCards} />
        </Box>
      </Stack>
    </Box>
  );
};

export default CustomerDetails;

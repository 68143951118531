import ReplayIcon from '@mui/icons-material/Replay';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RetryButtonProps {
  onClick: () => void;
}

const RetryButton: React.FC<RetryButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      size="small"
      startIcon={<ReplayIcon />}
      color="inherit"
    >
      {t('generic.retry')}
    </Button>
  );
};

export default RetryButton;

import { Theme, ThemeProvider } from '@mui/material/styles';
import React, { FC, PropsWithChildren } from 'react';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import { ThemeModes } from '@/components/providers/SettingsProvider.interface';
import { dark, light } from '@/configs/themeConfig';

const ReactThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { settings } = useSettingsContext();
  let theme: Theme;

  switch (settings.themeMode) {
    case ThemeModes.dark:
      theme = dark;
      break;
    case ThemeModes.system:
      theme = getSystemTheme();
      break;
    case ThemeModes.light:
      theme = light;
      break;
    default:
      theme = light;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const getSystemTheme = () => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  return mq.matches ? dark : light;
};

export default ReactThemeProvider;

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { batch } from '@preact/signals';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import CustomTabPanel from '@/components/sections/ecommerce/details/CustomTabPanel';
import CustomerAddress from '@/components/sections/ecommerce/details/CustomerAddress';
import OrderDetailsHeader from '@/components/sections/ecommerce/details/OrderDetailsHeader';
import ProductList from '@/components/sections/ecommerce/details/ProductList';
import CustomerRemarksFab from '@/components/sections/ecommerce/remarks/CustomerRemarksFab';
import QuerySuspense from '@/components/shared/QuerySuspense';
import CustomerDetails from '@/components/shared/customer/CustomerDetails';
import { useCustomerDetailsQuery } from '@/services/customerService';
import { useOrderQuery } from '@/services/orderService';
import eCommerceState from '@/state/eCommerceState';
import { SearchType } from '@/utils/checkInputType';

const OrderDetailsView: React.FC = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();

  const orderQuery = useOrderQuery(orderId);

  const customerEmail = searchParams.get('email');
  const customerQuery = useCustomerDetailsQuery(
    customerEmail ??
      orderQuery.data?.billingAddress.email ??
      orderQuery.data?.shippingAddress.email,
    false,
  );

  useEffect(() => {
    batch(() => {
      eCommerceState.context.query.value = orderId!;
      eCommerceState.context.type.value = SearchType.ORDER_ID;
    });
  }, [orderId]);

  const tabs = [
    {
      id: 'productOverviewTab',
      name: t('orderDetailsTabs.products'),
      content: (
        <Box key="productOverview">
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            sx={{ gap: '2rem' }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              {orderQuery.data && (
                <ProductList
                  products={orderQuery.data.products}
                  brand={orderQuery.data.brand}
                />
              )}
            </Box>
            <Box>
              {orderQuery.data && (
                <CustomerAddress
                  shippingAddress={orderQuery.data.shippingAddress}
                  billingAddress={orderQuery.data.billingAddress}
                />
              )}
            </Box>
          </Stack>
        </Box>
      ),
      failedRequests: false,
    },
    {
      id: 'customerDetailsTab',
      name: t('orderDetailsTabs.customerProfile'),
      content: (
        <Box key="CustomerDetails" p={3}>
          <QuerySuspense query={customerQuery}>
            <CustomerDetails
              user={customerQuery.data}
              activeBrand={orderQuery.data?.brand ?? undefined}
            />
          </QuerySuspense>
        </Box>
      ),
      failedRequests: false,
      disabled: !customerQuery.data,
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box p={3} sx={{ width: '100%', maxWidth: '1440px' }}>
        <QuerySuspense query={orderQuery}>
          <OrderDetailsHeader order={orderQuery.data!} />
          <CustomerRemarksFab order={orderQuery.data!} />
        </QuerySuspense>
        <CustomTabPanel
          tabs={tabs}
          activeBrand={orderQuery.data?.brand ?? undefined}
        />
      </Box>
    </Box>
  );
};

export default OrderDetailsView;

import { useMatch } from 'react-router-dom';

import brandConfig from '@/configs/brandConfig';
import { Brand, BrandKey } from '@/configs/brandConfig.interface';
import {
  eCommerceRoute,
  loyaltyRoute,
  stocksRoute,
  trackingRoute,
} from '@/routing';

export enum AppSection {
  Ecommerce = 'ecommerce',
  Loyalty = 'loyalty',
  Stock = 'stock',
  Tracking = 'tracking',
}

export const DEFAULT_SECTION = AppSection.Ecommerce;

const sections = {
  [AppSection.Loyalty]: {
    supportedBrands: brandConfig.getSupportedBrandsForLoyalty(),
  },
  [AppSection.Ecommerce]: {
    supportedBrands: brandConfig.getSupportedBrandsForOrders(),
  },
  [AppSection.Stock]: {
    supportedBrands: brandConfig.getSupportedBrandsForOrders(),
  },
  [AppSection.Tracking]: {
    supportedBrands: brandConfig.getSupportedBrandsForOrders(),
  },
};

export const isAppSection = (section: string): section is AppSection => {
  return section in sections;
};

export const toAppSection = (section: string): AppSection => {
  return isAppSection(section) ? section : DEFAULT_SECTION;
};

export const getSupportedBrands = (section: AppSection): Brand[] => {
  return sections[section].supportedBrands;
};

export const isSupportedBrand = (section: AppSection, brand: BrandKey) => {
  return getSupportedBrands(section).some((b) => b.key === brand);
};

const useAppSection = () => {
  const isLoyalty = useMatch(`${loyaltyRoute}/*`);
  const isEcommerce = useMatch(`${eCommerceRoute}/*`);
  const isStocks = useMatch(`${stocksRoute}/*`);
  const isTracking = useMatch(`${trackingRoute}/*`);

  let appSection: AppSection | undefined = undefined;
  if (isLoyalty) {
    appSection = AppSection.Loyalty;
  } else if (isEcommerce) {
    appSection = AppSection.Ecommerce;
  } else if (isStocks) {
    appSection = AppSection.Stock;
  } else if (isTracking) {
    appSection = AppSection.Tracking;
  }

  const inAppSection = <T>(map: { [section in AppSection]: T }):
    | T
    | undefined => {
    return appSection ? map[appSection] : undefined;
  };

  const isAppSection = (section: AppSection): boolean => {
    return appSection === section;
  };

  const supportedBrands = appSection ? getSupportedBrands(appSection) : [];

  return {
    appSection,
    isAppSection,
    inAppSection,
    supportedBrands,
  };
};

export default useAppSection;

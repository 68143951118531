import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';

interface ListItemWithIconProps {
  icon?: ReactNode;
  primary?: string | number;
  secondary?: ReactNode;
  onClick?: () => void;
}

const ListItemWithIcon: FC<ListItemWithIconProps> = ({
  icon,
  primary,
  secondary,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <HoverListItem onClick={onClick}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={primary}
        secondary={secondary}
        slotProps={{
          primary: {
            variant: 'caption',
            style: { color: 'darkgray' },
          },
          secondary: {
            variant: 'body1',
            style: { color: theme.palette.primary.main },
          },
        }}
      />
    </HoverListItem>
  );
};

export default ListItemWithIcon;

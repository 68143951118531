import { appConfig } from '@/configs/appConfig';
import { getBrand } from '@/configs/brandConfig';
import { BrandKey } from '@/configs/brandConfig.interface';
import {
  WalletResult,
  WalletTransactionResult,
} from '@/services/walletService.interface';
import { CustomError } from '@/utils/request-helpers/CustomError';
import { performADRequest } from '@/utils/request-helpers/apiHelper';

/**
 * Fetches wallet transactions for a specific customer.
 *
 * @param trackingId - The tracking ID of the customer.
 * @param brand - The brand to fetch wallet transactions for.
 * @returns A promise of wallet transactions.
 * @throws CustomError if the request fails.
 */
export const fetchWalletTransactions = async (
  trackingId: string,
  brand: BrandKey,
): Promise<WalletTransactionResult> => {
  if (!getBrand(brand).isWalletAvailable) {
    throw new Error('Wallet is not available for this brand');
  }
  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/wallets/${trackingId}/transactions`;
  try {
    return await performADRequest('GET', url);
  } catch (error: any) {
    throw CustomError.fromError(error, 'Failed to fetch transactions');
  }
};

/**
 * Fetches wallet details for a specific customer.
 *
 * @param trackingId - The tracking ID of the customer.
 * @param brand - The brand to fetch wallet details for.
 * @returns A promise of wallet details.
 * @throws CustomError if the request fails.
 */
export const fetchWalletDetails = async (
  trackingId: string,
  brand: BrandKey,
): Promise<WalletResult> => {
  if (!getBrand(brand).isWalletAvailable) {
    throw new Error('Wallet is not available for this brand');
  }
  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/wallets/${trackingId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error: any) {
    throw CustomError.fromError(error, 'Failed to fetch wallet details');
  }
};

import { t } from 'i18next';

import i18n from '@/configs/i18nConfig';

export const formatDateWithTimestamp = (
  orderTime: string | Date,
  locale: string = i18n.language,
) => {
  if (!orderTime) return t('generic.notAvailable');

  const date = new Date(orderTime);
  const formattedDate = date
    .toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace('.', '');
  const formattedTime = date
    .toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replaceAll('.', ':');

  return `${formattedDate} ${formattedTime}`;
};

import Typography from '@mui/material/Typography';
import React from 'react';

import {
  StyledFlexBox,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { currencyFormatter } from '@/utils/priceFormatter';

interface PaymentSummaryProps {
  label: string;
  amount: number;
  currency: string;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  label,
  amount,
  currency,
}) => {
  return (
    <StyledFlexBox>
      <Typography variant="h6">{label}</Typography>
      <StyledTitle>{currencyFormatter(amount, currency)}</StyledTitle>
    </StyledFlexBox>
  );
};

export default PaymentSummary;

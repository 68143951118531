import { Badge, Receipt, Update, Wallet } from '@mui/icons-material';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { CalendarIcon } from '@mui/x-date-pickers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CustomerIdTooltip from '@/components/sections/loyalty/customer/CustomerIdTooltip';
import HasWalletValue from '@/components/sections/loyalty/wallet/HasWalletValue';
import ListItemWithIcon from '@/components/shared/mui-helpers/ListItemWithIcon';
import { Profile } from '@/services/customerService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface Props {
  profile?: Profile;
}

const PlusProfile: FC<Props> = ({ profile }) => {
  const { t } = useTranslation();

  if (!profile) {
    return null;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        p: 2,
        paddingTop: 1,
        paddingBottom: 1,
      }}
    >
      <Box display="flex" sx={{ flex: 2 }}>
        <List dense sx={{ flex: 2 }}>
          <ListItemWithIcon
            icon={<Badge />}
            primary={t('customerProgram.membershipNrLabel')}
            secondary={
              <>
                {emptyValueReplacement(profile.membershipId)}{' '}
                <CustomerIdTooltip
                  membershipId={profile.membershipId}
                  trackingId={profile.trackingId}
                  cdcId={profile.cdcId}
                />
              </>
            }
          />

          <ListItemWithIcon
            icon={<CalendarIcon />}
            primary={t('customerProgram.signupDateLabel')}
            secondary={formatDate(profile.createdAt)}
          />

          <ListItemWithIcon
            icon={<Update />}
            primary={t('profile.headers.lastLockedAt')}
            secondary={
              profile.lastLockedAt ? formatDate(profile.lastLockedAt) : '-'
            }
          />
        </List>

        <List dense sx={{ flex: 2 }}>
          <ListItemWithIcon
            icon={<Wallet />}
            primary={t('profile.headers.hasWallet')}
            secondary={
              <HasWalletValue
                isWalletExisting={profile.hasWallet}
                isWalletBlocked={profile.isWalletBlocked}
                walletLastBlockedAt={profile.walletLastBlockedAt}
              />
            }
          />

          <ListItemWithIcon
            icon={<Receipt />}
            primary={t('profile.headers.useWalletForNextPurchase')}
            secondary={
              profile.useWalletOnPurchase ? t('generic.yes') : t('generic.no')
            }
          />
        </List>
      </Box>
    </Paper>
  );
};

export default PlusProfile;

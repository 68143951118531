import { BrandKey } from '@/configs/brandConfig.interface';
import { Locale } from '@/configs/i18nConfig';
import { AppSection } from '@/hooks/useAppSection';

export enum SettingKeys {
  LOCALE = 'locale',
  INITIAL_VIEW = 'initialView',
  BRAND = 'brand',
  THEME = 'theme',
  TICKET_NOTIFICATION_BEHAVIOR = 'ticketNotificationBehavior',
  TICKET_NOTIFICATION_EXPIRY_INTERVAL = 'ticketNotificationExpiryInterval',
  SIDEBAR = 'sidebar',
}

export type SidebarModes = 'open' | 'collapse';
export const SIDEBAR_OPTIONS: SidebarModes[] = ['open', 'collapse'];

export enum ThemeModes {
  dark = 'dark',
  light = 'light',
  system = 'system',
}

export enum TicketNotificationBehavior {
  PROMPT = 'prompt',
  SHOW = 'show',
  IGNORE = 'ignore',
}

export interface Settings {
  locale: Locale;
  brand: BrandKey;
  initialView: AppSection;
  themeMode: ThemeModes;
  ticketNotificationBehavior: TicketNotificationBehavior;
  ticketNotificationExpiryIntervalInMinutes: number;
  sidebar: SidebarModes;
}

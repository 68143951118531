import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LoyaltyCustomerExtendedActions from '@/components/sections/loyalty/header/LoyaltyCustomerExtendedActions';
import { StyledHeader } from '@/components/shared/mui-helpers/customComponentStyles';
import { getBrand } from '@/configs/brandConfig';
import { BrandKey } from '@/configs/brandConfig.interface';
import appState from '@/state/appState';
import { formatDateWithTimestamp } from '@/utils/date-utils/formatDateWithTimestamp';

export const LoyaltyCustomerHeader = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const brand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;

  if (!customerDetails) {
    return null;
  }

  const BrandLogo = getBrand(brand as BrandKey).logo;

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ marginLeft: 3, marginRight: 3, marginTop: 2, padding: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ gap: 3 }}>
            {!isMobile && (
              <>
                <BrandLogo />
                <Divider orientation="vertical" flexItem />
              </>
            )}

            <Stack spacing={1}>
              <StyledHeader sx={{ lineHeight: 1, marginBottom: 0 }}>
                {customerDetails?.firstName} {customerDetails?.lastName}
              </StyledHeader>
              <Typography
                sx={{ textDecorationStyle: 'dotted' }}
                color="textSecondary"
              >
                {customerDetails?.email}
              </Typography>
            </Stack>
          </Box>

          <LoyaltyCustomerExtendedActions />
        </Box>
      </Paper>

      {(customerDetails.comments?.length || 0) > 0 && (
        <Stack sx={{ marginLeft: 3, marginRight: 3, marginTop: 2 }} spacing={1}>
          {customerDetails.comments?.map((comment, index) => (
            <Alert
              key={`comment-${index}`}
              variant="filled"
              severity="error"
              sx={{ marginTop: 2 }}
              slotProps={{ message: { sx: { width: '100%' } } }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ flexWrap: 'wrap' }}
              >
                <span>
                  {t(
                    `profile.comments.dialog.commentCode.${comment.code}`,
                    comment.message,
                  )}
                </span>
                <Typography variant="caption">
                  {formatDateWithTimestamp(comment.createdAt)}
                </Typography>
              </Box>
            </Alert>
          ))}
        </Stack>
      )}
    </>
  );
};

export default LoyaltyCustomerHeader;

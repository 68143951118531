import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import { parseHtmlStringToReact } from '@/utils/parseHtmlStringToReact';

interface InfoIconButtonProps {
  description: string;
}

const InfoIconButton: React.FC<InfoIconButtonProps> = ({ description }) => (
  <Tooltip title={parseHtmlStringToReact(description)} arrow>
    <IconButton
      sx={{
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
      }}
    >
      <InfoIcon />
    </IconButton>
  </Tooltip>
);

export default InfoIconButton;

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { appConfig } from '@/configs/appConfig';
import {
  ReceiptOrderResult,
  ReceiptSummaryResult,
} from '@/services/receiptService.interface';
import appState from '@/state/appState';
import { CustomError } from '@/utils/request-helpers/CustomError';
import { performADRequest } from '@/utils/request-helpers/apiHelper';

/**
 * Fetches receipts for a specific customer
 *
 * @param gigyaId - The gigyaId of the customer.
 * @param brand - The brand to fetch receipts for.
 * @param pageSize - The number of receipts to fetch.
 * @param offset - The offset to start fetching receipts from.
 * @param fromDate - The date to start fetching receipts from.
 * @param toDate - The date to stop fetching receipts
 * @returns A promise of receipt summaries.
 */
export const fetchReceipts = async (
  gigyaId: string,
  brand: string,
  pageSize: number = 100,
  offset: number = 0,
  fromDate?: Date,
  toDate?: Date,
): Promise<ReceiptSummaryResult[]> => {
  const receiptUrl = new URL(
    `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${gigyaId}/receipts`,
  );

  receiptUrl.searchParams.append('limit', pageSize.toString());
  receiptUrl.searchParams.append('offset', offset.toString());

  if (fromDate) {
    receiptUrl.searchParams.append('fromDate', fromDate.toISOString());
  }

  if (toDate) {
    receiptUrl.searchParams.append('toDate', toDate.toISOString());
  }

  try {
    return await performADRequest('GET', receiptUrl.toString());
  } catch (error) {
    throw new Error(`Failed to fetch Receipts: ${error}`);
  }
};

/**
 * Fetches a receipt by its ID
 *
 * @param gigyaId - The gigyaId of the customer.
 * @param brand - The brand to fetch receipts for.
 * @param receiptId - The ID of the receipt to fetch.
 * @returns A promise of the receipt order.
 */
export const fetchReceiptById = async (
  gigyaId: string,
  brand: string,
  receiptId: string,
): Promise<ReceiptOrderResult> => {
  const receiptIdUrl = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${gigyaId}/receipts/${receiptId}`;

  try {
    return await performADRequest('GET', receiptIdUrl);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch Receipt by Id`);
  }
};

export const useReceiptsQuery = (initialReceipts: ReceiptSummaryResult[]) => {
  const customerBrand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;

  return useInfiniteQuery({
    queryKey: ['newReceipts', customerDetails?.uid],
    queryFn: ({ pageParam = 1 }) => {
      if (!customerDetails || !customerBrand)
        throw new Error('Customer details or brand is undefined');
      return fetchReceipts(
        customerDetails.uid,
        customerBrand,
        100,
        pageParam * 100,
      );
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage.length !== 0 ? pages.length + 1 : undefined,
    initialData: { pages: [initialReceipts], pageParams: [0] },
    enabled: false,
  });
};

export const useReceiptDetails = (
  receiptId: string,
  gigyaId: string,
  brand: string,
  initialData?: ReceiptOrderResult,
) => {
  return useQuery<ReceiptOrderResult>({
    queryKey: ['receiptDetails', receiptId],
    queryFn: () => fetchReceiptById(gigyaId, brand, receiptId),
    initialData,
    retry: 1,
    staleTime: 60000,
  });
};

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductDetailsPreview from '@/components/sections/ecommerce/details/ProductDetailsPreview';
import ProductDialog from '@/components/sections/ecommerce/details/ProductDialog';
import ProductLink from '@/components/sections/ecommerce/details/ProductLink';
import ProductPricePreview from '@/components/sections/ecommerce/details/ProductPricePreview';
import ProductStatus from '@/components/sections/ecommerce/details/ProductStatus';
import ProductTrackingLinksButton from '@/components/sections/ecommerce/details/ProductTrackingLinksButton';
import { StyledBorderCard } from '@/components/shared/mui-helpers/customComponentStyles';
import { algoliaClient } from '@/configs/algoliaConfig';
import { getAlgoliaIndex } from '@/configs/brandConfig';
import { BrandKey } from '@/configs/brandConfig.interface';
import {
  AlgoliaAttributes,
  useProductQuery,
} from '@/services/algoliaProductService';
import {
  Product,
  getItemCategoryDetails,
} from '@/services/orderService.interface';

interface ProductListProps {
  products: Product[];
  brand: BrandKey | null | undefined;
}

const ProductList: React.FC<ProductListProps> = ({ products, brand }) => {
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null,
  );
  const [productImage, setProductImage] = useState<string>('');

  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setSelectedProduct(null);
  };

  const indexName = getAlgoliaIndex(brand as BrandKey);

  const { data: imageData } = useProductQuery({
    client: algoliaClient,
    indexName: indexName,
    objectID: selectedProduct?.productId,
    attributesToRetrieve: [AlgoliaAttributes.PRIMARY_IMAGE_ID],
  });

  useEffect(() => {
    if (imageData) {
      setProductImage(imageData);
    }
  }, [imageData, selectedProduct]);

  return (
    <Stack spacing={2} mt={6}>
      <StyledBorderCard>
        {products.map((product, index) => {
          const itemCategory = getItemCategoryDetails(product.itemCategory);
          return (
            <React.Fragment key={`${index}-${product.productId}`}>
              <ListItem
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Stack sx={{ flex: '2 1 100%', overflow: 'hidden' }}>
                  <ProductStatus productHistory={product.history} />
                  <ProductDetailsPreview
                    product={product}
                    itemCategory={itemCategory}
                  />
                </Stack>

                <ProductPricePreview
                  totalPrice={product.totalPrice}
                  netPrice={product.netPrice}
                />

                <Divider
                  orientation="vertical"
                  sx={{ marginLeft: 1, marginRight: 1 }}
                  flexItem
                />

                <Stack
                  spacing={1}
                  sx={{
                    flex: '1 2 100%',
                    minWidth: '160px',
                    maxWidth: '220px',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setSelectedProduct(product)}
                  >
                    {t('orderDetails.product.details')}
                  </Button>
                  {brand && (
                    <ProductLink brand={brand} productId={product.productId}>
                      {t('orderDetails.product.site')}
                    </ProductLink>
                  )}

                  <ProductTrackingLinksButton tracking={product.tracking} />
                </Stack>
              </ListItem>
              {index < products.length - 1 && <Divider variant="fullWidth" />}
            </React.Fragment>
          );
        })}
      </StyledBorderCard>
      <ProductDialog
        selectedProduct={selectedProduct}
        handleCloseDialog={handleCloseDialog}
        productImage={productImage}
        brand={brand}
      />
    </Stack>
  );
};

export default ProductList;

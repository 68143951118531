import { CurrencyExchange, Payments } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompensationDialog from '@/components/sections/ecommerce/compensation/CompensationDialog';
import PaymentSetDialog from '@/components/sections/ecommerce/payment/PaymentSetDialog';
import {
  StyledBorderCard,
  StyledDescription,
  StyledHeader,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { Role } from '@/configs/userRolesConfig';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import { OrderDetailsResult } from '@/services/orderService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { priceFormatter } from '@/utils/priceFormatter';

interface OrderDetailsHeaderProps {
  order: OrderDetailsResult;
}

const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({ order }) => {
  const { t } = useTranslation();
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isCompensationDialogOpen, setIsCompensationDialogOpen] =
    useState(false);

  const { hasRole } = useAuthorizationHook();

  const fields: { label: string; value: any; action?: any }[] = [
    {
      label: t('orderDetails.header.customerName'),
      value: emptyValueReplacement(order.billingAddress.name),
    },
    {
      label: t('orderDetails.header.totalAmount'),
      value: (
        <Tooltip
          title={
            <>
              <div>
                {`${t('orderDetails.header.totalDiscount')}: ${priceFormatter(order.totalDiscount)}`}
              </div>
              <div>
                {`${t('orderDetails.header.deliveryPrice')}: ${priceFormatter(order.deliveryPrice)}`}
              </div>
              <div>
                {`${t('orderDetails.header.totalAmount')}: ${priceFormatter(order.total)}`}
              </div>
            </>
          }
        >
          <span>
            {priceFormatter(
              order.total + order.deliveryPrice - order.totalDiscount,
            )}
          </span>
        </Tooltip>
      ),
      action:
        order.transactionId &&
        order.paymentProvider === 'PGW' &&
        hasRole(Role.PAYMENT_READ) ? (
          <Tooltip title={t('payment.buttonTooltip')} enterDelay={500}>
            <IconButton
              color="primary"
              onClick={() => setIsPaymentDialogOpen(true)}
            >
              <Payments />
            </IconButton>
          </Tooltip>
        ) : null,
    },
    {
      label: t('orderDetails.header.orderDate'),
      value: order.orderDate ? formatDate(order.orderDate) : 'N/A',
    },
    {
      label: t('orderDetails.header.salesOffice'),
      value: order.salesOfficeDescription
        ? `${order.salesOfficeDescription}(${order.salesOfficeId})`
        : 'N/A',
    },
  ];

  return (
    <>
      <>
        {order.transactionId && (
          <PaymentSetDialog
            open={isPaymentDialogOpen}
            onClose={() => setIsPaymentDialogOpen(false)}
            brand={order.brand}
            paymentSetId={order.transactionId!}
          />
        )}
        <CompensationDialog
          brand={'bilka'}
          orderId={order.orderNumber}
          open={isCompensationDialogOpen}
          onClose={() => setIsCompensationDialogOpen(false)}
        />
      </>
      <Stack direction="row" spacing={2} justifyContent="space-between" mb={1}>
        <StyledHeader>
          {t('orderDetails.header.title')} #{order.orderNumber}{' '}
        </StyledHeader>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsCompensationDialogOpen(true)}
          startIcon={<CurrencyExchange />}
        >
          {t('compensation.button')}
        </Button>
      </Stack>

      <StyledBorderCard>
        <Grid container p={2}>
          {fields.map((field, index) => (
            <Grid
              key={`${index}-${field.label}`}
              size={{ xs: 6, md: 3, lg: 2 }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>
                  <StyledDescription display="block">
                    {field.label}
                  </StyledDescription>
                  <Typography
                    fontSize=".8rem"
                    sx={{ fontWeight: 'medium' }}
                    color="primary"
                  >
                    {field.value}
                  </Typography>
                </Box>
                {field.action}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </StyledBorderCard>
    </>
  );
};

export default OrderDetailsHeader;

import { Email, NewReleases } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import { CalendarIcon } from '@mui/x-date-pickers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ListItemWithIcon from '@/components/shared/mui-helpers/ListItemWithIcon';
import { User } from '@/services/customerService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';

interface Props {
  user?: User;
}

const UnverifiedEmailIcon = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('customerDetails.headers.unverified')}>
      <NewReleases
        color="disabled"
        fontSize="small"
        sx={{ verticalAlign: 'middle', marginTop: '-4px' }}
      />
    </Tooltip>
  );
};

const UserProfile: FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <List dense>
      <ListItemWithIcon
        icon={<PersonIcon />}
        primary={t('customerDetails.headers.name')}
        secondary={
          <>
            {user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : '-'}
            {!user.isActive && (
              <Chip
                size="small"
                label={t('customerDetails.inactive')}
                sx={{ marginLeft: 0.5 }}
              />
            )}
          </>
        }
      />

      <ListItemWithIcon
        icon={<Email />}
        primary={t('customerDetails.headers.email')}
        secondary={
          <>
            <>
              {user.email}{' '}
              {(user.unverifiedLoginEmails.filter((e) => user.email === e)
                .length || 0) > 0 && <UnverifiedEmailIcon />}
            </>

            {user.verifiedLoginEmails
              .filter((e) => e !== user.email)
              .map((email) => (
                <React.Fragment key={email}>
                  <br />
                  {email}
                </React.Fragment>
              ))}

            {user.unverifiedLoginEmails
              .filter((e) => e !== user.email)
              .map((email) => (
                <React.Fragment key={email}>
                  <br />
                  {email} <UnverifiedEmailIcon />
                </React.Fragment>
              ))}
          </>
        }
      />

      <ListItemWithIcon
        icon={<CalendarIcon />}
        primary={t('customerDetails.headers.registered')}
        secondary={formatDate(user.registered)}
      />
    </List>
  );
};

export default UserProfile;

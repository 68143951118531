import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '@/configs/appConfig';
import {
  GetNShiftShipmentsParams,
  Shipment,
} from '@/services/nShiftService.interface';

const fetchNShiftShipments = async (
  params: GetNShiftShipmentsParams,
): Promise<Shipment[]> => {
  let url = `${appConfig.sgApiNshiftShipments}${params.query}`;
  if (params.startDate && params.endDate) {
    url += `?startDate=${params.startDate}&endDate=${params.endDate}`;
  }

  const response = await axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': 'application/json',
    },
    timeout: 5000,
  });

  return response.data;
};

export const useNShiftShipments = (
  params: GetNShiftShipmentsParams | undefined,
) => {
  const { t } = useTranslation();

  const nShiftQuery = useQuery<Shipment[]>({
    queryKey: ['nShiftShipments', params],
    queryFn: () => fetchNShiftShipments(params as GetNShiftShipmentsParams),
    enabled: false,
  });

  useEffect(() => {
    if (nShiftQuery.isSuccess) {
      if (!nShiftQuery.data) {
        enqueueSnackbar(t('errors.noDataForTrackingId'));
      }
    }

    if (nShiftQuery.isError || nShiftQuery.isRefetchError) {
      enqueueSnackbar(t('errors.trackingFetchError'), { variant: 'error' });
      console.error('Error fetching tracking details', nShiftQuery.error);
    }
  }, [nShiftQuery.dataUpdatedAt, nShiftQuery.errorUpdatedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  return nShiftQuery;
};

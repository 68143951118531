import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '@/services/orderService.interface';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface ProductTableProps {
  product: Product;
}

const ProductTable: React.FC<ProductTableProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table size="small" aria-label="product details table">
        <TableBody>
          {[
            {
              label: t('orderDetails.product.consignment'),
              value: product.consignmentNumber,
            },
            {
              label: t('orderDetails.product.productId'),
              value: product.productId,
            },
            {
              label: t('orderDetails.product.articleId'),
              value: product.articleId,
            },
            {
              label: t('orderDetails.product.status'),
              value: product.status,
            },
            {
              label: t('orderDetails.product.requestedQuantity'),
              value: product.requestedQuantity,
            },
            {
              label: t('orderDetails.product.quantity'),
              value: product.quantity,
            },
            {
              label: t('orderDetails.product.invoiceDate'),
              value: product.invoiceDate,
            },
            {
              label: t('orderDetails.product.invoiceId'),
              value: emptyValueReplacement(product.invoiceId),
            },
          ].map((row, index) => (
            <TableRow
              key={row.label}
              sx={{
                backgroundColor: index % 2 === 0 ? 'action.hover' : 'inherit',
                '&:hover': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: 'bold', border: 0 }}
              >
                {row.label}
              </TableCell>
              <TableCell sx={{ border: 0 }}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;

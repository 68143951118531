import React, { FC } from 'react';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import {
  SettingKeys,
  SidebarModes,
  ThemeModes,
  TicketNotificationBehavior,
} from '@/components/providers/SettingsProvider.interface';
import { BrandKey } from '@/configs/brandConfig.interface';
import { toLocale } from '@/configs/i18nConfig';
import { toAppSection } from '@/hooks/useAppSection';

const useHandleSettingChange = () => {
  const { settings, setSettings } = useSettingsContext();

  const handleSettingChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const value = event.target.value;
    let newSettings = { ...settings };

    switch (event.target.name) {
      case SettingKeys.LOCALE:
        newSettings.locale = toLocale(value as string);
        break;
      case SettingKeys.SIDEBAR:
        newSettings.sidebar = value as SidebarModes;
        break;
      case SettingKeys.INITIAL_VIEW:
        newSettings.initialView = toAppSection(value as string);
        break;
      case SettingKeys.BRAND:
        newSettings.brand = value as BrandKey;
        break;
      case SettingKeys.THEME:
        newSettings.themeMode = value as ThemeModes;
        break;
      case SettingKeys.TICKET_NOTIFICATION_BEHAVIOR:
        newSettings.ticketNotificationBehavior =
          value as TicketNotificationBehavior;
        break;
      case SettingKeys.TICKET_NOTIFICATION_EXPIRY_INTERVAL: {
        const expiryInterval = parseInt(value as string, 10);
        if (!isNaN(expiryInterval)) {
          newSettings.ticketNotificationExpiryIntervalInMinutes =
            expiryInterval;
        }
        break;
      }
      default:
        console.error('Unknown setting: ' + event.target.name);
    }

    setSettings(newSettings);
  };
  const getOptions = (
    items: { value: string | number; label: string; icon?: FC | string }[],
  ) =>
    items.map((item) => ({
      value: item.value,
      label: item.label,
      icon: item.icon,
    }));
  return { handleSettingChange, getOptions };
};
export default useHandleSettingChange;

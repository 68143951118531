import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompensationDialogActions from '@/components/sections/ecommerce/compensation/CompensationDialogActions';
import CompensationDialogListItem from '@/components/sections/ecommerce/compensation/CompensationDialogListItem';
import QuerySuspense from '@/components/shared/QuerySuspense';
import {
  ORDER_COMPENSATION_QUERY_KEY,
  useOrderCompensation,
  useOrderCompensationQuery,
} from '@/services/compensationService';
import {
  CompensationItem,
  CompensationRequestItem,
} from '@/services/compensationService.interface';

interface Props {
  brand: string;
  orderId: string;

  open: boolean;
  onClose: () => void;
}

export const CompensationDialog: FC<Props> = ({
  brand,
  orderId,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const [selectedCompensation, setSelectedCompensation] = useState<
    CompensationRequestItem[]
  >([]);

  const queryClient = useQueryClient();
  const compensationQuery = useOrderCompensationQuery(open, brand, orderId);
  const compensationMutation = useOrderCompensation();

  useEffect(() => {
    if (compensationMutation.isSuccess) {
      handleClose();
      enqueueSnackbar(t('compensation.snackbar.success'), {
        variant: 'success',
        autoHideDuration: 5000,
      });
    }
  }, [compensationMutation.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!open) {
      // invalidate available compensation query on success
      // noinspection JSIgnoredPromiseFromCall
      queryClient.invalidateQueries({
        queryKey: [ORDER_COMPENSATION_QUERY_KEY],
      });
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (compensationMutation.isError) {
      enqueueSnackbar(t('compensation.snackbar.error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, [compensationMutation.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleSelection = (value: CompensationItem) => {
    const alreadySelected = selectedCompensation.find(
      (item) => item.itemRef === value.itemRef,
    );

    if (alreadySelected) {
      setSelectedCompensation(
        selectedCompensation.filter((item) => item.itemRef !== value.itemRef),
      );
    } else {
      setSelectedCompensation([
        ...selectedCompensation,
        {
          itemRef: value.itemRef,
          quantity: value.quantity,
          uom: value.uom,
          compensationAmount: value.possibleCompensation,
        },
      ]);
    }
  };

  const handleCompensationChange = (
    itemRef: string,
    value: { quantity?: string; compensationAmount?: string },
  ) => {
    setSelectedCompensation((prev) => {
      return prev.map((selected) => {
        if (selected.itemRef === itemRef) {
          return {
            ...selected,
            ...value,
          };
        }
        return selected;
      });
    });
  };

  const handleClose = () => {
    onClose();
    setSelectedCompensation([]);
  };

  return (
    <Dialog open={open} onClose={handleClose} closeAfterTransition={false}>
      <DialogTitle>{t('compensation.dialog.title', { orderId })}</DialogTitle>
      <QuerySuspense query={compensationQuery}>
        <DialogContent>
          {(compensationQuery.isError || compensationQuery.isRefetchError) && (
            <Stack spacing={1}>
              <DialogContentText>
                {t('compensation.dialog.errorText')}
              </DialogContentText>
              <Button
                variant="outlined"
                onClick={() => compensationQuery.refetch()}
              >
                {t('compensation.dialog.errorRetryButton')}
              </Button>
            </Stack>
          )}

          {!compensationQuery.isError &&
            !compensationQuery.isRefetchError &&
            (!compensationQuery.data?.isCompensationAvailable ||
              (compensationQuery.data?.items.length || 0) === 0) && (
              <DialogContentText>
                {t('compensation.dialog.emptyDescription')}
              </DialogContentText>
            )}

          {compensationQuery.data?.isCompensationAvailable &&
            (compensationQuery.data?.items.length || 0) > 0 && (
              <>
                <DialogContentText>
                  {t('compensation.dialog.description')}
                </DialogContentText>
                <List>
                  {compensationQuery.data?.items.map((item) => {
                    const compensationValue = selectedCompensation.find(
                      (selected) => selected.itemRef === item.itemRef,
                    );

                    return (
                      <CompensationDialogListItem
                        key={item.itemRef}
                        value={item}
                        onToggleSelection={() => toggleSelection(item)}
                        compensation={compensationValue}
                        onChange={(value) =>
                          handleCompensationChange(item.itemRef, value)
                        }
                      />
                    );
                  })}
                </List>
              </>
            )}
        </DialogContent>
      </QuerySuspense>
      <CompensationDialogActions
        compensationAvailable={Boolean(
          compensationQuery.data?.isCompensationAvailable &&
            (compensationQuery.data?.items.length || 0) > 0,
        )}
        submitAvailable={selectedCompensation.length > 0}
        onClose={handleClose}
        onSubmit={() => {
          compensationMutation.mutate({
            brand,
            orderId,
            request: selectedCompensation,
          });
        }}
        loading={compensationMutation.isPending}
      />
    </Dialog>
  );
};

export default CompensationDialog;

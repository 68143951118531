import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { BrandKey } from '@/configs/brandConfig.interface';
import useAppSection from '@/hooks/useAppSection';

const useBrandValidation = (
  selectedBrand: BrandKey,
  setSelectedBrand: (brand: BrandKey) => void,
) => {
  const { t } = useTranslation();
  const { supportedBrands } = useAppSection();

  if (!supportedBrands.some((brand) => brand.key === selectedBrand)) {
    enqueueSnackbar(
      t('messages.nonSupportedBrand', {
        brand: selectedBrand,
        defaultBrand: supportedBrands[0].label,
      }),
    );
    setSelectedBrand(supportedBrands[0].key);
  }
  return { supportedBrands };
};

export default useBrandValidation;

import { KeyboardArrowRight } from '@mui/icons-material';
import { CardActionArea, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getBrand } from '@/configs/brandConfig';
import { Brand, BrandKey } from '@/configs/brandConfig.interface';
import useAppSection, { AppSection } from '@/hooks/useAppSection';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import { loyaltyRoute } from '@/routing';
import { User } from '@/services/customerService.interface';
import appState from '@/state/appState';
import { SearchType } from '@/utils/checkInputType';

interface Props {
  user?: User;
  activeBrand?: BrandKey;
}

const PlusMemberships: FC<Props> = ({ user, activeBrand }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isAppSection } = useAppSection();
  const { isLoyaltyAuthorized } = useAuthorizationHook();

  const memberships = useMemo(() => {
    if (user) {
      const memberships: { brand: Brand; membershipNr: string }[] = [];
      if (user.accountData.customerProgram?.cpblkMembershipNr) {
        memberships.push({
          brand: getBrand('bilka'),
          membershipNr: user.accountData.customerProgram.cpblkMembershipNr,
        });
      }

      if (user.accountData.customerProgram?.cpcjrMembershipNr) {
        memberships.push({
          brand: getBrand('carlsjr'),
          membershipNr: user.accountData.customerProgram.cpcjrMembershipNr,
        });
      }

      if (user.accountData.customerProgram?.cpfotMembershipNr) {
        memberships.push({
          brand: getBrand('foetex'),
          membershipNr: user.accountData.customerProgram.cpfotMembershipNr,
        });
      }

      if (user.accountData.customerProgram?.cpndeMembershipNr) {
        memberships.push({
          brand: getBrand('nettode'),
          membershipNr: user.accountData.customerProgram.cpndeMembershipNr,
        });
      }

      if (user.accountData.customerProgram?.cpndkMembershipNr) {
        memberships.push({
          brand: getBrand('nettodk'),
          membershipNr: user.accountData.customerProgram.cpndkMembershipNr,
        });
      }

      if (user.accountData.customerProgram?.cpsalMembershipNr) {
        memberships.push({
          brand: getBrand('salling'),
          membershipNr: user.accountData.customerProgram.cpsalMembershipNr,
        });
      }
      return memberships;
    }

    return [];
  }, [user]);

  if (memberships.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        flex: 1,
        alignSelf: 'flex-start',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 1,
        rowGap: 1,
        marginTop: 1.5,
      }}
    >
      {memberships.map((membership) => {
        const disabled =
          !isLoyaltyAuthorized || activeBrand === membership.brand.key;

        const BrandLogo = membership.brand.logo;

        return (
          <Card
            key={membership.brand.key}
            variant="outlined"
            sx={{
              gridColumnStart: memberships.length > 1 ? undefined : 2,
              minWidth: '9rem',
            }}
          >
            <CardActionArea
              disabled={disabled}
              onClick={() => {
                if (isAppSection(AppSection.Loyalty)) {
                  appState.loyaltySearch.setSearch(
                    membership.brand.key,
                    membership.membershipNr,
                    SearchType.MEMBER_ID,
                  );
                } else {
                  navigate(
                    `${loyaltyRoute}/customer?brand=${membership.brand.key}&email=${user?.email}`,
                  );
                }
              }}
              sx={{ height: '100%' }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <BrandLogo
                      style={{ maxWidth: '5rem', alignSelf: 'flex-start' }}
                    />

                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      sx={{ marginTop: 1, marginBottom: 0.25 }}
                    >
                      <Typography variant="caption">
                        {membership.brand.label}
                      </Typography>
                      <Typography variant="caption" color="textDisabled">
                        {membership.membershipNr}
                      </Typography>
                    </Box>

                    {membership.brand.key === activeBrand && (
                      <Divider
                        flexItem
                        color={theme.palette.primary.main}
                        sx={{
                          height: '3px',
                          width: '2rem',
                          borderRadius: 2,
                        }}
                      />
                    )}
                  </Stack>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      background: disabled
                        ? theme.palette.action.disabledBackground
                        : theme.palette.primary.main,
                      borderRadius: 100,
                      height: '2rem',
                      width: '2rem',
                    }}
                  >
                    <KeyboardArrowRight
                      sx={{
                        color: theme.palette.getContrastText(
                          theme.palette.primary.main,
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </Box>
  );
};

export default PlusMemberships;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import BilkaIcon from '@/assets/logos/icon_bilka.svg?react';
import { BrandIconProps } from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#009FE3';

const BrandIconBilka: FC<BrandIconProps> = ({
  themeAware = true,
  primaryColor,
  ...other
}) => {
  const color = primaryColor ?? DEFAULT_PRIMARY_COLOR;
  return (
    <SvgIcon {...other}>
      <BilkaIcon style={{ color }} />
    </SvgIcon>
  );
};

export default BrandIconBilka;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import EcommerceLayout from '@/components/sections/ecommerce/EcommerceLayout';
import OrderDetailsView from '@/components/sections/ecommerce/OrderDetailsView';
import OrdersView from '@/components/sections/ecommerce/OrdersView';

export const ECommerceRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<EcommerceLayout />}>
        <Route index element={<OrdersView />} />

        <Route
          path="brands/:brand/orders/:orderId"
          element={
            <Navigate
              to={{
                pathname: location.pathname.replace(
                  /\/brands\/[^\/]*\/orders/,
                  '',
                ),
                search: location.search,
              }}
            />
          }
        />

        <Route path=":orderId" element={<OrderDetailsView />} />
      </Route>
    </Routes>
  );
};

export default ECommerceRouter;

import CardContent from '@mui/material/CardContent';
import React from 'react';

import {
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { Shipment } from '@/services/nShiftService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface ShipmentInfoProps {
  shipment: Shipment;
}

const ShipmentInfo: React.FC<ShipmentInfoProps> = ({ shipment }) => (
  <StyledBorderCard variant="outlined">
    <CardContent sx={{ flex: 1 }}>
      <StyledTitle>Forsendelses</StyledTitle>
      <StyledDescription>
        UUID: {emptyValueReplacement(shipment.uuid)}
        <br />
        Pakke(r): {shipment.packages.length}
        <br />
        Placering: {emptyValueReplacement(shipment.locationName)}
        <br />
        Transportør: {emptyValueReplacement(shipment.carrierName)}
        <br />
        Indsendelsesdato: {formatDate(shipment.submitDate)}
        <br />
        Afhentningsdato: {formatDate(shipment.pickupDate)}
        <br />
        Forsendelses-ID: {emptyValueReplacement(shipment.consignmentId)}
      </StyledDescription>
    </CardContent>
  </StyledBorderCard>
);

export default ShipmentInfo;

import { Box, Typography } from '@mui/material';
import List from '@mui/material/List/List';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OrderLineItem from '@/components/sections/loyalty/receipts/OrderLineItem';
import { OrderLine } from '@/services/receiptService.interface';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface ReceiptOrderLinesProps {
  orderLines: OrderLine[] | null;
}

const ReceiptOrderLines: React.FC<ReceiptOrderLinesProps> = ({
  orderLines,
}) => {
  const { t } = useTranslation();

  if (!orderLines) {
    return (
      <Typography>
        {t('receipt.orderLine')}: {emptyValueReplacement(orderLines)}
      </Typography>
    );
  }

  return (
    <List sx={{ textTransform: 'uppercase' }}>
      {orderLines.map((line, index) => (
        <Box
          key={`${index}-${line.articleID}`}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <OrderLineItem line={line} />
        </Box>
      ))}
    </List>
  );
};

export default ReceiptOrderLines;

import { HelpOutline } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import React from 'react';

import { copyToClipboard } from '@/utils/copyToClipboard';

interface Props {
  membershipId: string;
  trackingId: string;
  cdcId: string;
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const CustomerIdTooltip: React.FC<Props> = ({
  membershipId,
  trackingId,
  cdcId,
}) => {
  return (
    <NoMaxWidthTooltip
      title={
        <List>
          <ListItem>
            <ListItemText
              primary="Membership ID"
              secondary={
                <Typography color="inherit" variant="body2">
                  {membershipId}
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => copyToClipboard(membershipId)}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Tracking ID"
              secondary={
                <Typography color="inherit" variant="body2">
                  {trackingId}
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => copyToClipboard(trackingId)}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CDC ID"
              secondary={
                <Typography color="inherit" variant="body2">
                  {cdcId}
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => copyToClipboard(cdcId)}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              }
            />
          </ListItem>
        </List>
      }
    >
      <HelpOutline sx={{ verticalAlign: 'middle' }} color="disabled" />
    </NoMaxWidthTooltip>
  );
};

export default CustomerIdTooltip;

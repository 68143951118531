import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ScrollableCardContent,
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { Tracking } from '@/services/orderService.interface';
import { copyToClipboard } from '@/utils/copyToClipboard';

interface TrackingDetailsProps {
  tracking: Tracking;
}

const ProductTracking: React.FC<TrackingDetailsProps> = ({ tracking }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTitle variant="h6" my={2}>
        {t('orderDetails.tracking.title')}
      </StyledTitle>
      <StyledBorderCard variant="outlined">
        <ScrollableCardContent sx={{ flex: 1 }}>
          {tracking.trackingIds.length === 0 &&
          tracking.trackingUrls.length === 0 ? (
            <StyledDescription>N/A</StyledDescription>
          ) : (
            <>
              {tracking.trackingIds.map((trackingId, index) => (
                <Tooltip
                  key={`${index}-${trackingId}`}
                  placement="bottom"
                  title={t('orderDetails.tracking.copyTooltip')}
                >
                  <StyledDescription
                    onClick={() => copyToClipboard(trackingId)}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    {trackingId}
                  </StyledDescription>
                </Tooltip>
              ))}
              {tracking.trackingUrls.map((trackingUrl, index) => (
                <Tooltip
                  key={`${index}-${trackingUrl}`}
                  placement="bottom-start"
                  title={t('orderDetails.tracking.openTooltip')}
                >
                  <Link
                    href={trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'block' }}
                  >
                    {t('orderDetails.tracking.trackingUrl')}
                  </Link>
                </Tooltip>
              ))}
            </>
          )}
        </ScrollableCardContent>
      </StyledBorderCard>
    </>
  );
};

export default ProductTracking;

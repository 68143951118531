import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import green from '@mui/material/colors/green';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';
import { LinkedArticle as ILinkedArticle } from '@/services/receiptService.interface';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { currencyFormatter } from '@/utils/priceFormatter';

interface LinkedArticleProps {
  article: ILinkedArticle;
  currency: string;
}

const LinkedArticle: React.FC<LinkedArticleProps> = ({ article, currency }) => {
  const { t } = useTranslation();

  return (
    <Box style={{ width: '100%' }}>
      <HoverListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: 0,
        }}
      >
        <Typography color="text.secondary">
          {emptyValueReplacement(article.name)}
          {article.isRefundable && (
            <Tooltip title={t('receipt.refundable')}>
              <Chip
                icon={<ReplyIcon />}
                color="primary"
                size="small"
                sx={{
                  ml: 1,
                  backgroundColor: green[500],
                  color: 'white',
                  '& .MuiChip-icon': {
                    marginLeft: '0.6rem',
                  },
                  height: 'auto',
                  alignItems: 'center',
                  lineHeight: 'inherit',
                }}
              />
            </Tooltip>
          )}
        </Typography>
        <Typography sx={{ textAlign: 'right', marginRight: '0' }}>
          {currencyFormatter(article.totalPrice, currency)}
        </Typography>
      </HoverListItem>
      {article.linkedArticle && (
        <LinkedArticle article={article.linkedArticle} currency={currency} />
      )}
    </Box>
  );
};

export default LinkedArticle;

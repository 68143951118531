import { useTheme } from '@mui/material/styles';
import { CSSProperties, FC } from 'react';

import FoetexLogo from '@/assets/logos/logo_foetex.svg?react';
import {
  BrandLogoProps,
  DEFAULT_BRAND_LOGO_HEIGHT,
} from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#0F223B';

const BrandLogoFoetex: FC<BrandLogoProps> = ({
  themeAware = true,
  primaryColor,
  borderlessScale = false,
  height = DEFAULT_BRAND_LOGO_HEIGHT,
  ...other
}) => {
  const theme = useTheme();

  let fontColor = 'white';
  let borderColor = DEFAULT_PRIMARY_COLOR;
  let backgroundColor = primaryColor ?? DEFAULT_PRIMARY_COLOR;

  if (themeAware && !primaryColor && theme.palette.mode === 'dark') {
    backgroundColor = 'transparent';
    borderColor = 'white';
    fontColor = primaryColor ?? 'white';
  }

  return (
    <FoetexLogo
      {...other}
      height={height}
      style={
        {
          '--background-color': backgroundColor,
          '--border-color': borderColor,
          '--font-color': fontColor,
          ...other.style,
        } as CSSProperties
      }
    />
  );
};

export default BrandLogoFoetex;

import { ListItem } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import React from 'react';

interface HoverListItemProps {
  onClick?: () => void;
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

const HoverListItem: React.FC<HoverListItemProps> = ({
  onClick,
  children,
  sx,
  ...props
}) => {
  if (onClick) {
    return (
      <ListItemButton onClick={onClick} {...props} sx={sx}>
        {children}
      </ListItemButton>
    );
  }
  return (
    <ListItem {...props} sx={{ ...sx }}>
      {children}
    </ListItem>
  );
};
export default HoverListItem;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { TabIds } from '@/components/sections/loyalty/LoyaltyTab.interface';
import ReceiptDetails from '@/components/sections/loyalty/receipts/ReceiptDetails';
import ReceiptList from '@/components/sections/loyalty/receipts/ReceiptList';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import appState from '@/state/appState';

const ReceiptsTab: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const receipts = appState.loyalty.receipts.data.value;

  const urlParams = new URLSearchParams(location.search);
  const receiptIdFromUrl = urlParams.get('receiptId');
  const isOnReceiptsTab = location.pathname.includes(TabIds.RECEIPTS);

  const [selectedReceiptId, setSelectedReceiptId] = useState<string | null>(
    receiptIdFromUrl ?? null,
  );

  useEffect(() => {
    if (!selectedReceiptId && receipts.length > 0) {
      handleSelectReceipt(receipts[0].id);
    }
  }, [receipts, selectedReceiptId, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectReceipt = (receiptId: string) => {
    setSelectedReceiptId(receiptId);
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('receiptId', receiptId);
    navigate({
      pathname: location.pathname,
      search: isOnReceiptsTab
        ? updatedSearchParams.toString()
        : location.search,
    });
  };

  return (
    <Box>
      {!receipts || receipts.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
            textAlign: 'center',
          }}
        >
          <NoContentMessage infoMessage={t('receipt.noReceipts')} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4 }}>
            <ReceiptList
              receipts={receipts}
              onSelectReceipt={handleSelectReceipt}
            />
          </Grid>
          {/*Show ReceiptItem if a receipt is clicked in ReceiptList component */}
          <Grid size={{ xs: 12, md: 8 }}>
            {selectedReceiptId && (
              <ReceiptDetails receiptId={selectedReceiptId} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReceiptsTab;

import NavigateNext from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductHistory } from '@/services/orderService.interface';

interface Props {
  productHistory: ProductHistory[];
}

const ProductStatus: FC<Props> = ({ productHistory }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" sx={{ alignItems: 'center', gap: 1 }}>
      <Typography variant="overline" color="gray">
        {t('orderDetails.product.status')}
      </Typography>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        maxItems={2}
        itemsBeforeCollapse={0}
        itemsAfterCollapse={2}
        sx={{ '& ol': { flexWrap: 'nowrap' } }}
      >
        {productHistory.map((record) => (
          <Typography key={record.status} variant="overline" fontWeight="bold">
            {record.status}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default ProductStatus;

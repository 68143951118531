import { appConfig } from '@/configs/appConfig';
import { getADToken } from '@/utils/request-helpers/authentication';

/**
 * Retrieves common headers required for loyalty-related requests.
 *
 * @returns An object containing the common headers.
 * @throws {Error} If there is an issue obtaining the authorization token.
 */
export const getCommonHeaders = async (): Promise<{
  [key: string]: string;
}> => {
  const { idToken } = await getADToken();
  return {
    Authorization: `Bearer ${idToken}`,
    'SG-APIM-ROUTER': 'B',
    'SG-APIM-IDENTIFIER-ID': appConfig.sgApimIdentifierId,
  };
};

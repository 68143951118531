import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { StyledColoredCard } from '@/components/shared/mui-helpers/customComponentStyles';
import appState from '@/state/appState';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { formatDateWithTimestamp } from '@/utils/date-utils/formatDateWithTimestamp';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { currencyFormatter } from '@/utils/priceFormatter';

const WalletDetailsHeader: React.FC = () => {
  const { t } = useTranslation();

  const profileDetails = appState.customer.profile.value;
  const wallet = appState.loyalty.wallet.data.value;

  const fields = useMemo(() => {
    if (!wallet) return [];
    return [
      {
        label: t('walletHeader.trackingId'),
        value: emptyValueReplacement(wallet.trackingId),
      },
      {
        label: t('walletHeader.balance'),
        value: currencyFormatter(wallet.balance, wallet.currency),
      },
      {
        label: t('walletHeader.currency'),
        value: emptyValueReplacement(wallet.currency),
      },
    ];
  }, [wallet, t]);

  if (!wallet) {
    return <NoContentMessage infoMessage={t('walletHeader.noData')} />;
  }
  return (
    <Box sx={{ px: 3 }}>
      {profileDetails?.isWalletBlocked && (
        <Alert severity="error" sx={{ marginBottom: '1em' }}>
          {t('wallet.blocked', {
            date: formatDateWithTimestamp(profileDetails.walletLastBlockedAt!),
          })}
        </Alert>
      )}
      <StyledColoredCard>
        <CardContent>
          <Grid container>
            {fields.map((field, index) => (
              <Grid
                size={{ xs: 6, sm: 4, md: 2, lg: 1 }}
                key={`${index}-${field.label}`}
              >
                <Typography variant="caption" gutterBottom>
                  {field.label}
                </Typography>
                {field.label === t('walletHeader.trackingId') ? (
                  <Tooltip title={field.value}>
                    <Typography
                      gutterBottom
                      onClick={() => copyToClipboard(field.value.toString())}
                      sx={{
                        cursor: 'pointer',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '75%',
                        flex: 'shrink',
                      }}
                    >
                      {field.value}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography gutterBottom>{field.value}</Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </StyledColoredCard>
    </Box>
  );
};

export default WalletDetailsHeader;

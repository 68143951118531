export interface PaymentEvent {
  id: number;
  type: string;
  amount: number;
  message: string;
  external_id?: string | null;
  intent_id?: number | null;
  created_at: string;
  netsCreatedAt?: string | null;
}

export interface Payment {
  id: number;
  providerId: string;
  amount: number;
  externalId?: string;
  externalDetailsUrl?: string;
  acceptUrl?: string;
  cancelUrl?: string;
  termsUrl?: string;
  paymentMethod?: string;
  state: {
    reserved: number;
  };
  reservationId?: string;
  events: PaymentEvent[];
}

export interface PaymentIntent {
  id: number;
  type: string;
  paymentSetId: number;
  amount: number;
  assignmentId: string;
  settlementId: string;
  createdAt: string;
  cancelled: boolean;
}

export enum PaymentEventDetails {
  SUCCESS,
  PENDING,
  ERROR,
  UNKNOWN,
}

export interface PaymentEventConfiguration {
  labelKey: string;
  type: PaymentEventDetails;
}

const paymentEvents: { [key: string]: PaymentEventConfiguration } = {
  RESERVED_SUCCESS: {
    labelKey: 'payment.eventType.reservationSuccess',
    type: PaymentEventDetails.SUCCESS,
  },
  RESERVED_PENDING: {
    labelKey: 'payment.eventType.reservationPending',
    type: PaymentEventDetails.PENDING,
  },
  RESERVED_FAILED: {
    labelKey: 'payment.eventType.reservationFailed',
    type: PaymentEventDetails.ERROR,
  },
  CANCELLED_INITIATED: {
    labelKey: 'payment.eventType.cancelledInitiated',
    type: PaymentEventDetails.PENDING,
  },
  CANCELLED_PENDING: {
    labelKey: 'payment.eventType.cancelledPending',
    type: PaymentEventDetails.PENDING,
  },
  CANCELLED_SUCCESS: {
    labelKey: 'payment.eventType.cancelledSuccess',
    type: PaymentEventDetails.SUCCESS,
  },
  CANCELLED_FAILED: {
    labelKey: 'payment.eventType.cancelledFailed',
    type: PaymentEventDetails.ERROR,
  },
  CAPTURE_INITIATED: {
    labelKey: 'payment.eventType.captureInitiated',
    type: PaymentEventDetails.PENDING,
  },
  CAPTURE_PENDING: {
    labelKey: 'payment.eventType.capturePending',
    type: PaymentEventDetails.PENDING,
  },
  CAPTURE_SUCCESS: {
    labelKey: 'payment.eventType.captureSuccess',
    type: PaymentEventDetails.SUCCESS,
  },
  CAPTURE_ATTEMPT: {
    labelKey: 'payment.eventType.captureAttempt',
    type: PaymentEventDetails.PENDING,
  },
  CAPTURE_FAILED: {
    labelKey: 'payment.eventType.captureFailed',
    type: PaymentEventDetails.ERROR,
  },
  REFUNDED_INITIATED: {
    labelKey: 'payment.eventType.refundedInitiated',
    type: PaymentEventDetails.PENDING,
  },
  REFUNDED_PENDING: {
    labelKey: 'payment.eventType.refundedPending',
    type: PaymentEventDetails.PENDING,
  },
  REFUNDED_SUCCESS: {
    labelKey: 'payment.eventType.refundedSuccess',
    type: PaymentEventDetails.SUCCESS,
  },
  REFUNDED_ATTEMPT: {
    labelKey: 'payment.eventType.refundedAttempt',
    type: PaymentEventDetails.PENDING,
  },
  REFUNDED_FAILED: {
    labelKey: 'payment.eventType.refundedFailed',
    type: PaymentEventDetails.ERROR,
  },
  RELEASE_INITIATED: {
    labelKey: 'payment.eventType.releaseInitiated',
    type: PaymentEventDetails.PENDING,
  },
  RELEASE_PENDING: {
    labelKey: 'payment.eventType.releasePending',
    type: PaymentEventDetails.PENDING,
  },
  RELEASE_FAILED: {
    labelKey: 'payment.eventType.releaseFailed',
    type: PaymentEventDetails.ERROR,
  },
  RELEASE_ATTEMPT: {
    labelKey: 'payment.eventType.releaseAttempt',
    type: PaymentEventDetails.PENDING,
  },
  RELEASE_SUCCESS: {
    labelKey: 'payment.eventType.releaseSuccess',
    type: PaymentEventDetails.SUCCESS,
  },
  WRITTEN_OFF: {
    labelKey: 'payment.eventType.writtenOff',
    type: PaymentEventDetails.SUCCESS,
  },
  WRITTEN_BACK: {
    labelKey: 'payment.eventType.writtenBack',
    type: PaymentEventDetails.ERROR,
  },
  BANK_TRANSFER_IN: {
    labelKey: 'payment.eventType.bankTransferIn',
    type: PaymentEventDetails.SUCCESS,
  },
  BANK_TRANSFER_OUT: {
    labelKey: 'payment.eventType.bankTransferOut',
    type: PaymentEventDetails.SUCCESS,
  },
};

export const getPaymentEventDetailsForType = (
  type: string,
): PaymentEventConfiguration => {
  return paymentEvents[type] ?? { type: PaymentEventDetails.UNKNOWN };
};

export interface PaymentSet {
  id: number;
  merchant: string;
  orderId: string;
  currency: string;
  amount: number;
  destroyed: boolean;
  sapMerchantId: string;
  state: {
    reserved: number;
  };
  payments: Payment[];
  intents: PaymentIntent[];
}

export const getPaymentProviderLabelKey = (provider: string): string => {
  switch (provider) {
    case 'nets':
      return 'payment.providers.nets';
    case 'gift-card':
      return 'payment.providers.giftCard';
    default:
      return 'payment.providers.unknown';
  }
};

import { batch, signal } from '@preact/signals';

import { BrandKey } from '@/configs/brandConfig.interface';
import { SearchType } from '@/utils/checkInputType';

export const createSearchState = () => {
  const brand = signal<BrandKey | undefined>(undefined);
  const term = signal<string | undefined>(undefined);
  const type = signal<SearchType | undefined>(undefined);

  const clearSearch = () => {
    brand.value = undefined;
    term.value = undefined;
    type.value = undefined;
  };

  const setSearch = (
    brandKey: BrandKey,
    searchTerm: string,
    searchType: SearchType,
  ) => {
    batch(() => {
      brand.value = brandKey;
      term.value = searchTerm;
      type.value = searchType;
    });
  };

  return { brand, term, type, setSearch, clearSearch };
};

export const loyaltySearchState = createSearchState();

import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CompensationAvailabilityResponseItem,
  CompensationItem,
  CompensationRequestItem,
} from '@/services/compensationService.interface';
import { priceFormatter } from '@/utils/priceFormatter';

interface Props {
  value: CompensationAvailabilityResponseItem;
  compensation?: CompensationRequestItem;

  onToggleSelection: (item: CompensationItem) => void;
  onChange: (value: { quantity?: string; compensationAmount?: string }) => void;
}

export const CompensationDialogListItem: FC<Props> = ({
  value,
  compensation,

  onToggleSelection,
  onChange,
}) => {
  const { t } = useTranslation();

  const labelId = `checkbox-list-label-${value.itemRef}`;

  return (
    <ListItem
      key={value.itemRef}
      sx={{ paddingLeft: 0, paddingRight: 0 }}
      divider={true}
    >
      <Stack
        direction="column"
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <ListItemButton onClick={() => onToggleSelection(value)}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={Boolean(compensation)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={
              <>
                <Typography display="inline">
                  {parseFloat(value.quantity)}x
                </Typography>{' '}
                <Typography display="inline" sx={{ fontWeight: 'bold' }}>
                  {value.productName}
                </Typography>
              </>
            }
            secondary={priceFormatter(parseFloat(value.possibleCompensation))}
          />
        </ListItemButton>

        <Collapse in={Boolean(compensation)}>
          <Stack direction="row" spacing="1rem" sx={{ marginLeft: '4rem' }}>
            <TextField
              label={t('compensation.dialog.quantityInput.label')}
              type="number"
              slotProps={{
                htmlInput: { min: 0, max: value.quantity },
                inputLabel: { shrink: true },
              }}
              helperText={t('compensation.dialog.quantityInput.helperText', {
                maxQuantity: parseFloat(value.quantity),
              })}
              value={parseFloat(compensation?.quantity || '0')}
              onChange={(e) => {
                onChange({
                  quantity: e.target.value,
                });
              }}
            />
            <TextField
              label="Compensation"
              type="number"
              slotProps={{
                htmlInput: {
                  min: 0,
                  max: value.possibleCompensation,
                  step: 0.01,
                },
                inputLabel: { shrink: true },
                input: {
                  endAdornment: (
                    <InputAdornment position="end">kr.</InputAdornment>
                  ),
                },
              }}
              helperText={t('compensation.dialog.amountInput.helperText', {
                maxAmount: value.possibleCompensation,
              })}
              value={compensation?.compensationAmount || ''}
              onChange={(e) => {
                onChange({
                  compensationAmount: e.target.value,
                });
              }}
            />
          </Stack>
        </Collapse>
      </Stack>
    </ListItem>
  );
};

export default CompensationDialogListItem;

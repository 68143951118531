import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';

import { appConfig } from '@/configs/appConfig';
import {
  MemberPunchCard,
  PunchCardResponse,
} from '@/services/tradeDriverService.interface';
import appState from '@/state/appState';
import { CustomError } from '@/utils/request-helpers/CustomError';
import { performADRequest } from '@/utils/request-helpers/apiHelper';

interface PunchCardsRequestParams {
  activeAt: Dayjs | null;
  isRewardClaimed?: boolean | null;
  isExpired?: boolean | null;
  isCommenced?: boolean | null;
  isConcluded?: boolean | null;
}

const buildPunchCardQueryParams = (
  params: PunchCardsRequestParams,
): URLSearchParams => {
  const queryParams = new URLSearchParams();
  const { activeAt, isRewardClaimed, isExpired, isCommenced, isConcluded } =
    params;

  if (activeAt !== null && activeAt !== undefined)
    queryParams.append('activeAt', activeAt.toISOString());
  if (isRewardClaimed !== null && isRewardClaimed !== undefined)
    queryParams.append('isRewardClaimed', String(isRewardClaimed));
  if (isExpired !== null && isExpired !== undefined)
    queryParams.append('isExpired', String(isExpired));
  if (isCommenced !== null && isCommenced !== undefined)
    queryParams.append('isCommenced', String(isCommenced));
  if (isConcluded !== null && isConcluded !== undefined)
    queryParams.append('isConcluded', String(isConcluded));

  return queryParams;
};

/**
 * Fetch punch cards for a specific customer
 *
 * @param trackingId - The tracking ID of the customer.
 * @param brand - The brand to fetch punch cards for.
 * @param params - The parameters to filter punch cards by.
 * @returns A promise of punch cards.
 * @throws CustomError if the request fails.
 *
 */
export const fetchPunchCards = async (
  trackingId: string,
  brand: string,
  params: PunchCardsRequestParams,
): Promise<PunchCardResponse> => {
  const queryParams = buildPunchCardQueryParams(params);
  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/punch-cards?${queryParams.toString()}`;

  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch active punch cards`);
  }
};

export const usePunchCardsRequest = (
  params: PunchCardsRequestParams,
  trackingId?: string,
  customerBrand?: string,
  initialData: MemberPunchCard[] = [],
) => {
  return useQuery({
    queryKey: [
      'punchCards',
      customerBrand,
      appState.customer.details.value?.email,
    ],
    queryFn: () =>
      fetchPunchCards(trackingId as string, customerBrand as string, params),
    retry: 1,
    initialData: { memberPunchCards: initialData },
    enabled: !!trackingId && !!customerBrand,
    staleTime: 60000,
  });
};

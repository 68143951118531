import { Badge } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/system/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ListItemWithIcon from '@/components/shared/mui-helpers/ListItemWithIcon';
import {
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';

interface ProfileDetailsProps {
  agentInfo: {
    name: string;
    email: string;
    salaryId: string;
    roles: string[];
  };
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ agentInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card variant="outlined" sx={{ padding: 2 }}>
        <StyledTitle>{t('settings.details.title')}</StyledTitle>
        <List>
          <ListItemWithIcon
            icon={<PersonIcon />}
            primary={t('settings.details.name')}
            secondary={agentInfo.name}
          />
          <Divider variant="middle" />
          <ListItemWithIcon
            icon={<EmailIcon />}
            primary={t('settings.details.email')}
            secondary={agentInfo.email}
          />
          <Divider variant="middle" />
          <ListItemWithIcon
            icon={<Badge />}
            primary={t('settings.details.salaryId')}
            secondary={agentInfo.salaryId}
          />
        </List>
      </Card>

      <br />
      <Card variant="outlined" sx={{ padding: 2 }}>
        <StyledTitle>{t('settings.details.roles')}</StyledTitle>
        <Box sx={{ padding: 1 }}>
          {agentInfo.roles.length > 0 && (
            <>
              {agentInfo.roles.map((role, index) => (
                <StyledDescription mb={1} key={`${index}-${role}`}>
                  {role}
                </StyledDescription>
              ))}
            </>
          )}
        </Box>
      </Card>
    </>
  );
};

export default ProfileDetails;

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrandSelect } from '@/components/sections/loyalty/search/BrandSelect';
import { StyledAppBar } from '@/components/shared/mui-helpers/customComponentStyles';
import theme from '@/configs/themeConfig';
import useSearchHandler from '@/hooks/useSearchHandler';
import { checkLoyaltyInputType } from '@/utils/checkInputType';

const LoyaltySearchBar = () => {
  const {
    selectedBrand,
    setSelectedBrand,
    searchQuery,
    handleInputChange,
    handleSearchClick,
    validationError,
  } = useSearchHandler(checkLoyaltyInputType);
  const { t } = useTranslation();

  const validationColor = validationError ? theme.palette.error.main : 'white';

  const searchBarStyles = () => {
    return {
      width: '38ch',

      '& .MuiOutlinedInput-root': {
        '& input': { color: validationColor },
        '& fieldset': { borderColor: validationColor },
        '&:hover fieldset': { borderColor: validationColor },
        '&.Mui-focused fieldset': { borderColor: validationColor },
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: validationColor,
      },
      '&.Mui-focused .MuiOutlinedInput-input': { borderColor: validationColor },
      '& .MuiInputLabel-root': { color: validationColor },
      '& .MuiFormHelperText-root': { color: validationColor },
      '& label.Mui-focused': { color: validationColor },
      '& input:-webkit-autofill': {
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
        WebkitTextFillColor: theme.palette.primary.contrastText,
        transition: 'background-color 0s 600000s, color 0s 600000s !important',
      },
    };
  };

  return (
    <StyledAppBar
      position="static"
      sx={{
        minHeight: '90px',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: 2,
            alignItems: 'baseline',
          }}
        >
          <BrandSelect
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
          <TextField
            id="search-bar"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              event.key === 'Enter' && handleSearchClick()
            }
            label={t('messages.searchByLoyaltyParameters')}
            variant="outlined"
            size="small"
            placeholder="bob@gmail.com or 121222"
            sx={searchBarStyles()}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleSearchClick}
                    sx={{ cursor: 'pointer' }}
                  >
                    <SearchIcon sx={{ color: validationColor }} />
                  </InputAdornment>
                ),
              },
            }}
            helperText={validationError}
            error={!!validationError}
          />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default LoyaltySearchBar;

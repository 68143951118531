import FormGroup from '@mui/material/FormGroup';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import {
  SIDEBAR_OPTIONS,
  SettingKeys,
  ThemeModes,
} from '@/components/providers/SettingsProvider.interface';
import SettingsSelect from '@/components/sections/settings/preferences/SettingsSelect';
import { StyledTitle } from '@/components/shared/mui-helpers/customComponentStyles';
import { getFlag, locales } from '@/configs/i18nConfig';
import { AppSection, getSupportedBrands } from '@/hooks/useAppSection';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import useHandleSettingChange from '@/hooks/useHandleSettingChange';

const AppPreference: FC = () => {
  const { t } = useTranslation();
  const { isEcomAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();
  const { settings } = useSettingsContext();
  const { handleSettingChange, getOptions } = useHandleSettingChange();

  return (
    <>
      <StyledTitle sx={{ marginBottom: 2 }}>
        {t('settings.preference.title')}
      </StyledTitle>
      <FormGroup sx={{ gap: 2 }}>
        <SettingsSelect
          labelId="profile-theme-selector"
          label={t('settings.preference.theme.title')}
          name="theme"
          value={settings.themeMode}
          onChange={handleSettingChange}
          options={getOptions(
            Object.keys(ThemeModes).map((mode) => ({
              value: mode,
              label: t(`settings.preference.theme.${mode}`),
            })),
          )}
          helperText={t('settings.preference.theme.description')}
        />

        <SettingsSelect
          labelId="profile-locale-selector"
          label={t('settings.preference.language.title')}
          name={SettingKeys.LOCALE}
          value={settings.locale}
          onChange={handleSettingChange}
          options={getOptions(
            locales.map((lang) => ({
              value: lang,
              label: t(`languages.${lang}`),
              icon: getFlag(lang),
            })),
          )}
          helperText={t('settings.preference.language.description')}
        />

        <SettingsSelect
          labelId="profile-initial-view"
          label={t('settings.preference.initialView.title')}
          name={SettingKeys.INITIAL_VIEW}
          value={settings.initialView}
          onChange={handleSettingChange}
          options={getOptions(
            [
              {
                value: AppSection.Ecommerce,
                label: t('settings.preference.initialView.values.ecommerce'),
              },
              {
                value: AppSection.Stock,
                label: t('settings.preference.initialView.values.stock'),
              },
              {
                value: AppSection.Tracking,
                label: t('settings.preference.initialView.values.tracking'),
              },
            ].concat(
              isLoyaltyAuthorized
                ? [
                    {
                      value: AppSection.Loyalty,
                      label: t(
                        'settings.preference.initialView.values.loyalty',
                      ),
                    },
                  ]
                : [],
            ),
          )}
          helperText={t('settings.preference.initialView.description')}
          disabled={!isEcomAuthorized || !isLoyaltyAuthorized}
        />

        <SettingsSelect
          labelId="profile-brand"
          label={t('settings.preference.brand.title')}
          name={SettingKeys.BRAND}
          value={settings.brand}
          onChange={handleSettingChange}
          options={getOptions(
            getSupportedBrands(settings.initialView).map((brand) => ({
              value: brand.key,
              label: brand.label,
              icon: brand.icon,
            })),
          )}
          helperText={t('settings.preference.brand.description')}
        />
        <SettingsSelect
          labelId="profile-sidebar"
          label={t('settings.preference.sidebar.title')}
          name="sidebar"
          value={settings.sidebar}
          onChange={handleSettingChange}
          options={SIDEBAR_OPTIONS.map((option) => ({
            value: option,
            label: t(`settings.preference.sidebar.${option}`),
          }))}
          helperText={t('settings.preference.sidebar.description')}
        />
      </FormGroup>
    </>
  );
};

export default AppPreference;

import { useMsal } from '@azure/msal-react';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Menu from '@mui/icons-material/Menu';
import { CSSObject, Stack, ToggleButton, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Theme, useTheme } from '@mui/material/styles';
import styled from '@mui/system/styled';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppLogo from '@/assets/360.svg';
import AppVersion from '@/components/layout/navigation/AppVersion';
import { NavigationItem } from '@/components/layout/navigation/NavigationItem';
import { useSettingsContext } from '@/components/providers/SettingsContext';
import ArchiveBox from '@/components/shared/icons/ArchiveBox';
import ArrowRightEndOnRectangle from '@/components/shared/icons/ArrowRightEndOnRectangle';
import Identification from '@/components/shared/icons/Identification';
import Settings from '@/components/shared/icons/Settings';
import ShoppingCart from '@/components/shared/icons/ShoppingCart';
import Truck from '@/components/shared/icons/Truck';
import { eCommerceRoute, loyaltyRoute } from '@/routing/AppRouter.interface';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)})`,
});

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }: { open: boolean }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }: { open: boolean }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

interface Props {
  isAuthorizedForOrders: boolean;
  isAuthorizedForLoyalty: boolean;
}

export const Navigation: FC<Props> = ({
  isAuthorizedForOrders,
  isAuthorizedForLoyalty,
}) => {
  const { t } = useTranslation();
  const { settings } = useSettingsContext();

  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const userName = account?.name ?? 'Unknown User';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sideBarSettings = settings.sidebar;

  const [expanded, setExpanded] = useState(sideBarSettings === 'open');
  useEffect(() => {
    if (sideBarSettings === 'open') {
      setExpanded(true);
    }
  }, [sideBarSettings]);

  const handleLogout = () => {
    // noinspection JSIgnoredPromiseFromCall
    instance.logoutRedirect({
      authority: 'https://login.microsoftonline.com/common',
    });
  };

  return (
    <>
      {isMobile && (
        <AppBar position="static">
          <Toolbar sx={{ alignItems: 'center' }}>
            <IconButton onClick={() => setExpanded(true)}>
              <Menu color="secondary" />
            </IconButton>

            <Box display="flex">
              <img
                alt="360° Logo"
                src={AppLogo}
                style={{ maxHeight: '1.5rem' }}
              />
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={expanded}
        sx={{ height: '100vh' }}
        onClose={() => setExpanded(false)}
      >
        {!isMobile && (
          <Tooltip title={t('navigation.expandAndCollapse')} enterDelay={500}>
            <ToggleButton
              value="expanded"
              aria-label={t('navigation.expandAndCollapse')}
              selected={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              {expanded && <KeyboardDoubleArrowLeft />}
              {!expanded && <KeyboardDoubleArrowRight />}
            </ToggleButton>
          </Tooltip>
        )}

        <Box
          display="flex"
          justifyContent="center"
          sx={{ paddingTop: '1em', paddingBottom: '1em' }}
        >
          <img
            alt="360° Logo"
            src={AppLogo}
            style={{ maxHeight: '3rem', maxWidth: '80%' }}
          />
        </Box>

        <Stack
          direction="column"
          sx={{
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: '1rem',
          }}
        >
          <List
            component="nav"
            sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            disablePadding
          >
            {isAuthorizedForLoyalty && (
              <NavigationItem
                title={t('navigation.menu.loyalty')}
                expanded={expanded}
                icon={<Identification />}
                to={loyaltyRoute}
              />
            )}

            {isAuthorizedForOrders && (
              <>
                <NavigationItem
                  title={t('navigation.menu.orders')}
                  expanded={expanded}
                  icon={<ShoppingCart />}
                  to={eCommerceRoute}
                />
                <NavigationItem
                  title={t('navigation.menu.tracking')}
                  expanded={expanded}
                  icon={<Truck />}
                  to="/tracking"
                />
                <NavigationItem
                  title={t('navigation.menu.stock')}
                  expanded={expanded}
                  icon={<ArchiveBox />}
                  to="/stocks"
                />
              </>
            )}

            {account && (
              <>
                <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />

                <NavigationItem
                  expanded={expanded}
                  title={t('navigation.menu.settings')}
                  icon={<Settings />}
                  to="/profile"
                />
                <NavigationItem
                  expanded={expanded}
                  title={t('navigation.menu.signOut')}
                  icon={<ArrowRightEndOnRectangle />}
                  onClick={handleLogout}
                />
              </>
            )}
          </List>

          <Stack direction="column" alignItems="center" spacing=".5rem">
            {account && (
              <Tooltip title={t('navigation.userTooltip', { user: userName })}>
                <Avatar>{userName.charAt(0)}</Avatar>
              </Tooltip>
            )}
            {expanded && <AppVersion />}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CustomerTab from '@/components/sections/loyalty/CustomerTab';
import LoyaltyTab from '@/components/sections/loyalty/LoyaltyTab';
import { TabIds } from '@/components/sections/loyalty/LoyaltyTab.interface';
import LoyaltyView from '@/components/sections/loyalty/LoyaltyView';
import OffersTab from '@/components/sections/loyalty/OffersTab';
import PunchCardTab from '@/components/sections/loyalty/PunchCardTab';
import ReceiptsTab from '@/components/sections/loyalty/ReceiptsTab';
import WalletTab from '@/components/sections/loyalty/WalletTab';
import { loyaltyRoute } from '@/routing/AppRouter.interface';
import appState from '@/state/appState';

export const LoyaltyRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={`${loyaltyRoute}/${TabIds.CUSTOMER_PROFILE}`} replace />
        }
      />
      <Route path="/" element={<LoyaltyView />}>
        <Route
          path={TabIds.CUSTOMER_PROFILE}
          element={
            <LoyaltyTab tab={TabIds.CUSTOMER_PROFILE}>
              <CustomerTab />
            </LoyaltyTab>
          }
        />
        <Route
          path={TabIds.WALLET}
          element={
            <LoyaltyTab
              tab={TabIds.WALLET}
              prerequisiteRequests={[
                appState.loyalty.wallet.request,
                appState.loyalty.walletTransactions.request,
              ]}
            >
              <WalletTab />
            </LoyaltyTab>
          }
        />
        <Route
          path={TabIds.MEMBER_OFFERS}
          element={
            <LoyaltyTab
              tab={TabIds.MEMBER_OFFERS}
              prerequisiteRequests={[appState.loyalty.memberOffers.request]}
            >
              <OffersTab />
            </LoyaltyTab>
          }
        />
        <Route
          path={TabIds.PUNCH_CARDS}
          element={
            <LoyaltyTab
              tab={TabIds.PUNCH_CARDS}
              prerequisiteRequests={[appState.loyalty.punchCards.request]}
            >
              <PunchCardTab />
            </LoyaltyTab>
          }
        />
        <Route
          path={TabIds.RECEIPTS}
          element={
            <LoyaltyTab
              tab={TabIds.RECEIPTS}
              prerequisiteRequests={[appState.loyalty.receipts.request]}
            >
              <ReceiptsTab />
            </LoyaltyTab>
          }
        />
      </Route>
    </Routes>
  );
};

export default LoyaltyRouter;

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { priceFormatter } from '@/utils/priceFormatter';

interface Props {
  totalPrice: number;
  netPrice: number;
}

export const ProductPricePreview: FC<Props> = ({ totalPrice, netPrice }) => {
  return (
    <Stack
      sx={{
        marginLeft: 2,
        display: { xs: 'none', md: 'initial' },
      }}
    >
      {totalPrice !== netPrice && (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            textDecoration: 'line-through',
          }}
        >
          {priceFormatter(totalPrice)}
        </Typography>
      )}
      <Typography sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
        {priceFormatter(netPrice)}
      </Typography>
    </Stack>
  );
};

export default ProductPricePreview;

import { SearchType } from '@/utils/checkInputType';

const updateSearchParams = (
  searchParams: URLSearchParams,
  brand: string | string[],
  searchType: SearchType,
  searchTerm: string,
) => {
  const params = new URLSearchParams(searchParams);

  if (Array.isArray(brand)) {
    params.delete('brand');
    brand.forEach((b) => params.append('brand', b));
  } else {
    params.set('brand', brand as string);
  }

  if (searchType === SearchType.EMAIL) {
    params.set('email', searchTerm);
  } else {
    params.delete('email');
  }

  if (searchType === SearchType.MEMBER_ID) {
    params.set('memberId', searchTerm);
  } else {
    params.delete('memberId');
  }

  if (searchType === SearchType.GIGYA_ID) {
    params.set('gigyaId', searchTerm);
  } else {
    params.delete('gigyaId');
  }

  if (searchType === SearchType.ORDER_ID) {
    params.set('orderId', searchTerm);
  } else {
    params.delete('orderId');
  }

  return params;
};

export default updateSearchParams;

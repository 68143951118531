import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useAppSection from '@/hooks/useAppSection';
import eCommerceState from '@/state/eCommerceState';

export enum OrderPeriodFilterOption {
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  LAST_YEAR,
  ALL_TIME,
}

interface Props {
  totalOrders?: number;
}

const OrderFilter: FC<Props> = ({ totalOrders }) => {
  const { t } = useTranslation();

  const { supportedBrands } = useAppSection();

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ marginBottom: 2 }}
    >
      <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
        <Typography>
          {totalOrders !== undefined &&
            t('orderFilter.period.label', { count: totalOrders })}
          {totalOrders === undefined && t('orderFilter.period.undefinedLabel')}
        </Typography>
        <Select
          value={eCommerceState.filter.period.value}
          onChange={(e) =>
            (eCommerceState.filter.period.value = e.target
              .value as OrderPeriodFilterOption)
          }
          variant="standard"
        >
          <MenuItem value={OrderPeriodFilterOption.LAST_3_MONTHS}>
            {t('orderFilter.period.3months')}
          </MenuItem>
          <MenuItem value={OrderPeriodFilterOption.LAST_6_MONTHS}>
            {t('orderFilter.period.6months')}
          </MenuItem>
          <MenuItem value={OrderPeriodFilterOption.LAST_YEAR}>
            {t('orderFilter.period.12months')}
          </MenuItem>
          <MenuItem value={OrderPeriodFilterOption.ALL_TIME}>
            {t('orderFilter.period.allTime')}
          </MenuItem>
        </Select>
      </Box>

      <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
        <ToggleButtonGroup
          color="primary"
          value={eCommerceState.filter.brands.value}
          onChange={(e, value) => (eCommerceState.filter.brands.value = value)}
        >
          {supportedBrands.map((brand) => (
            <ToggleButton key={brand.key} value={brand.key}>
              {brand.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default OrderFilter;

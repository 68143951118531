import { FC } from 'react';

import BilkaLogo from '@/assets/logos/logo_bilka.svg?react';
import {
  BrandLogoProps,
  DEFAULT_BRAND_LOGO_HEIGHT,
} from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#009FE3';

const BrandLogoBilka: FC<BrandLogoProps> = ({
  themeAware = true,
  primaryColor,
  borderlessScale = false,
  height = DEFAULT_BRAND_LOGO_HEIGHT,
  ...other
}) => {
  const additionalProps: any = {};
  if (!borderlessScale) {
    additionalProps.viewBox = '0 -34 479 196';
  }

  const color = primaryColor ?? DEFAULT_PRIMARY_COLOR;

  return (
    <BilkaLogo
      {...other}
      height={height}
      style={{ color, ...other.style }}
      {...additionalProps}
    />
  );
};

export default BrandLogoBilka;

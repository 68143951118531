import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Store } from '@/services/stockService.interface';

const useStyles = makeStyles({
  autocomplete: {
    width: '100%',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface StoreAutocompleteProps {
  stores: Store[];
  selectedStores: Store[];
  setSelectedStores: (stores: Store[]) => void;
}

const StoreAutocomplete: React.FC<StoreAutocompleteProps> = ({
  stores,
  selectedStores,
  setSelectedStores,
}) => {
  const classes = useStyles();

  const checkOption = (option: Store) => {
    return selectedStores.some((store) => store.sapSiteId === option.sapSiteId);
  };

  const checkGroup = (group: string) => {
    const groupLength = stores.filter((store) => store.brand === group).length;
    const selectedGroupLength = selectedStores.filter(
      (store) => store.brand === group,
    ).length;
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group: string) => {
    const groupedStores = stores.filter((store) => store.brand === group);
    const selectedGroupStores = selectedStores.filter(
      (store) => store.brand === group,
    );

    if (selectedGroupStores.length > 0) {
      setSelectedStores(
        selectedStores.filter((store) => store.brand !== group),
      );
    } else {
      setSelectedStores([...selectedStores, ...groupedStores]);
    }
  };

  const unselectOption = (sapSiteId: string) => {
    setSelectedStores(
      selectedStores.filter((store) => store.sapSiteId !== sapSiteId),
    );
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      id="store-select-demo"
      options={stores}
      classes={{
        option: classes.option,
      }}
      onChange={(_, option) => setSelectedStores([...(option as Store[])])}
      value={selectedStores}
      autoHighlight
      multiple
      disableCloseOnSelect
      isOptionEqualToValue={(option: Store, value: Store) =>
        option.sapSiteId === value.sapSiteId
      }
      getOptionLabel={(option: Store) => option.name}
      groupBy={(option: Store) => option.brand}
      renderOption={(props, option: Store) => (
        <li {...props} key={option.sapSiteId}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={checkOption(option)}
          />
          {option.name}
        </li>
      )}
      renderGroup={(params) => (
        <div key={params.group}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={checkGroup(params.group)}
            onChange={() => selectGroup(params.group)}
          />
          <span>{params.group}</span>
          {params.children}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a store"
          variant="outlined"
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password',
            },
          }}
        />
      )}
      renderTags={() => {
        return selectedStores.map((tag) => (
          <Button
            size="small"
            variant="contained"
            key={tag.sapSiteId}
            endIcon={<CancelIcon />}
            onClick={() => unselectOption(tag.sapSiteId)}
            sx={{ marginRight: '0.3rem' }}
          >
            {tag.name}
          </Button>
        ));
      }}
    />
  );
};

export default StoreAutocomplete;

import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import {
  SettingKeys,
  TicketNotificationBehavior,
} from '@/components/providers/SettingsProvider.interface';
import SettingsSelect from '@/components/sections/settings/preferences/SettingsSelect';
import { StyledTitle } from '@/components/shared/mui-helpers/customComponentStyles';
import useHandleSettingChange from '@/hooks/useHandleSettingChange';

const AppNotifications: React.FC = () => {
  const { t } = useTranslation();
  const { settings } = useSettingsContext();
  const { handleSettingChange, getOptions } = useHandleSettingChange();

  return (
    <>
      <StyledTitle sx={{ marginBottom: 2 }}>
        {t('settings.notifications.title')}
      </StyledTitle>
      <FormGroup sx={{ gap: 2 }}>
        <SettingsSelect
          labelId="profile-notification-settings"
          label={t('settings.notifications.ticket.title')}
          name={SettingKeys.TICKET_NOTIFICATION_BEHAVIOR}
          value={settings.ticketNotificationBehavior}
          onChange={handleSettingChange}
          options={getOptions(
            Object.values(TicketNotificationBehavior).map((value) => ({
              value,
              label: t('settings.notifications.ticket.values.' + value),
            })),
          )}
          helperText={t('settings.notifications.ticket.description')}
        />

        <SettingsSelect
          labelId="profile-notification-timeout"
          label={t('settings.notifications.expiryInterval.title')}
          name={SettingKeys.TICKET_NOTIFICATION_EXPIRY_INTERVAL}
          value={String(settings.ticketNotificationExpiryIntervalInMinutes)}
          onChange={handleSettingChange}
          options={getOptions(
            [0, 1, 5, 10, 15].map((value) => ({
              value,
              label: t('settings.notifications.expiryInterval.minutes', {
                count: value,
              }),
            })),
          )}
          helperText={t('settings.notifications.expiryInterval.description')}
          disabled={
            settings.ticketNotificationBehavior ===
            TicketNotificationBehavior.IGNORE
          }
        />
      </FormGroup>
    </>
  );
};

export default AppNotifications;

import { AccountBox, Gavel, Newspaper, Policy } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, {
  FC,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Consent from '@/components/shared/customer/Consent';
import { User } from '@/services/customerService.interface';

interface Props {
  user?: User;
}

const ColumnHeader: FC<{ icon: ReactNode; title: string }> = ({
  icon,
  title,
}) => {
  return (
    <Box display="flex" gap={1} sx={{ overflow: 'hidden' }}>
      {icon}
      <Box
        component="span"
        sx={{
          display: { xs: 'none', md: 'inline' },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
    </Box>
  );
};

const Consents: FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  const consents = useMemo(() => {
    if (user) {
      const formats = new Set<string>();
      user.newsletter.forEach((n) => {
        formats.add(n.name);
      });
      user.profiling.forEach((p) => {
        formats.add(p.name);
      });
      user.terms.forEach((t) => {
        formats.add(t.name);
      });
      user.privacy.forEach((p) => {
        formats.add(p.name);
      });

      return [...formats].map((format) => ({
        id: format,
        newsletter: user.newsletter.find((n) => n.name === format),
        profiling: user.profiling.find((p) => p.name === format),
        terms: user.terms.find((t) => t.name === format),
        privacy: user.privacy.find((p) => p.name === format),
      }));
    }

    return [];
  }, [user]);

  // re-render on window resize for responsive layout of <DataGrid />
  // ... otherwise it will just keep a static width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowWidth = useCallback(() => {
    if (windowWidth !== window.innerWidth) {
      setWindowWidth(window.innerWidth);
    }
  }, [windowWidth]);
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, [updateWindowWidth]);

  if (consents.length === 0) {
    return null;
  }

  return (
    <DataGrid
      density="compact"
      hideFooter
      columns={[
        {
          field: 'id',
          headerName: t('customerProgram.consentTitle'),
          flex: 3,
          disableColumnMenu: true,
          minWidth: 120,
          renderCell: (params) => (
            <>{t(`customerProgram.consents.${params.value}`)}</>
          ),
        },
        {
          field: 'newsletter',
          headerAlign: 'center',
          renderHeader: () => (
            <ColumnHeader
              icon={<Newspaper fontSize="small" />}
              title={t('customerProgram.consentNewsletter')}
            />
          ),
          align: 'center',
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => <Consent consent={params.value} />,
        },
        {
          field: 'profiling',
          headerAlign: 'center',
          renderHeader: () => (
            <ColumnHeader
              icon={<AccountBox fontSize="small" />}
              title={t('customerProgram.consentProfiling')}
            />
          ),
          align: 'center',
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => <Consent consent={params.value} />,
        },
        {
          field: 'terms',
          headerName: 'Terms',
          headerAlign: 'center',
          renderHeader: () => (
            <ColumnHeader
              icon={<Gavel fontSize="small" />}
              title={t('customerProgram.consentTerms')}
            />
          ),
          align: 'center',
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => <Consent consent={params.value} />,
        },
        {
          field: 'privacy',
          headerName: 'Privacy',
          headerAlign: 'center',
          renderHeader: () => (
            <ColumnHeader
              icon={<Policy fontSize="small" />}
              title={t('customerProgram.consentPrivacy')}
            />
          ),
          align: 'center',
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => <Consent consent={params.value} />,
        },
      ]}
      rows={consents}
    />
  );
};

export default Consents;

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useECommerceState } from '@/state/eCommerceState';

const EcomSearchBar = ({}) => {
  const { t } = useTranslation();
  const eCommerceState = useECommerceState();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: 3,
        minHeight: '120px',
      }}
    >
      <TextField
        id="search-input"
        sx={{ width: '38ch' }}
        aria-label="search-input"
        label={t('messages.searchByParameters')}
        value={eCommerceState.search.query.value}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) =>
          event.key === 'Enter' && eCommerceState.search.submit()
        }
        placeholder="bob@gmail.com or 121222"
        onChange={(e) => (eCommerceState.search.query.value = e.target.value)}
        helperText={eCommerceState.search.error.value}
        error={Boolean(eCommerceState.search.error.value)}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={eCommerceState.search.submit}
                sx={{ cursor: 'pointer' }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </Box>
  );
};

export default EcomSearchBar;

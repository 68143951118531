import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';

interface Props {
  icon: ReactNode;
  primary: ReactNode;
  secondary: ReactNode;
  onClick: () => void;
}

const SettingsListItem: FC<Props> = ({ icon, primary, secondary, onClick }) => {
  const theme = useTheme();

  return (
    <HoverListItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
        slotProps={{
          primary: {
            style: {
              color: theme.palette.primary.main,
              textTransform: 'capitalize',
            },
          },
          secondary: {
            variant: 'caption',
            style: { color: 'darkgray' },
          },
        }}
      />
    </HoverListItem>
  );
};

export default SettingsListItem;

import { batch } from '@preact/signals';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ICreditCardDetail } from '@/components/sections/loyalty/credit-cards/ICreditCardDetail';
import { appConfig } from '@/configs/appConfig';
import { getBrand } from '@/configs/brandConfig';
import {
  CustomerDetailsByBrand,
  GetMemberOffers,
  OffersData,
  User,
} from '@/services/customerService.interface';
import appState from '@/state/appState';
import { SearchType } from '@/utils/checkInputType';
import { CustomError } from '@/utils/request-helpers/CustomError';
import { performADRequest } from '@/utils/request-helpers/apiHelper';
import { getCommonHeaders } from '@/utils/request-helpers/loyalty/getLoyaltyCommonHeaders';
import updateSearchParams from '@/utils/updateSearchParams';

export const useCustomerDetailsQuery = (
  email: string | undefined,
  showError: boolean = true,
) => {
  const { t } = useTranslation();

  const userQuery = useQuery<User>({
    queryKey: ['customerDetails', email],
    queryFn: () => fetchCustomerDetails(email as string),
    enabled: Boolean(email),
    staleTime: 60000,
  });

  useEffect(() => {
    if (userQuery.isError && showError) {
      console.error('CustomerFetchDetails', JSON.stringify(userQuery.error));
      enqueueSnackbar(t('errors.customerDetailsFetch'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery.dataUpdatedAt, userQuery.errorUpdatedAt]);

  return userQuery;
};

/**
 * Fetches customer details by the values in the search state.
 *
 * @param queryOptions - The options for the query.
 * @returns the query result object.
 */
export const useLoyaltyCustomerQuery = (
  queryOptions: Partial<
    Omit<UseQueryOptions<CustomerDetailsByBrand>, 'queryFn' | 'enabled'>
  > = {},
) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const brand = appState.loyaltySearch.brand.value;
  const searchTerm = appState.loyaltySearch.term.value;
  const searchType = appState.loyaltySearch.type.value;

  const query = useQuery({
    queryKey: ['customer', brand, searchTerm],
    queryFn: () => {
      switch (searchType) {
        case SearchType.EMAIL:
          return fetchCustomerDetailsWithBrand(searchTerm!, brand!);
        case SearchType.MEMBER_ID:
          return fetchLoyaltyProfileByMembershipId(searchTerm!);
        case SearchType.GIGYA_ID:
          return fetchLoyaltyProfileByGigyaId(searchTerm!, brand!);
      }

      return Promise.reject(new Error('Invalid search type'));
    },
    enabled: Boolean(brand && searchTerm && searchType !== SearchType.ORDER_ID),
    ...queryOptions,
    staleTime: 60000,
  });

  useEffect(() => {
    if (query.isSuccess) {
      if (query.data) {
        batch(() => {
          appState.customer.brand.value = query.data.brand ?? undefined;
          appState.customer.details.value = query.data.user;
          appState.customer.profile.value = query.data.profile ?? undefined;

          appState.loyalty.customer.data.value = query.data;
          appState.loyalty.customer.request.value = {
            isFetching: false,
            isFetched: true,
            isError: false,
          };
        });

        setSearchParams(
          updateSearchParams(searchParams, brand!, searchType!, searchTerm!),
        );

        if (query.data.brand && brand !== query.data.brand) {
          enqueueSnackbar(
            t('messages.switchingBrand', {
              brand: getBrand(query.data.brand).label,
            }),
            {
              variant: 'warning',
              autoHideDuration: 4000,
              preventDuplicate: true,
            },
          );
        }
      } else {
        enqueueSnackbar(
          t('errors.customerNotInLoyaltyProgram', {
            brand: brand && getBrand(brand).label,
          }),
        );
        appState.customer.reset();
      }
    } else if (query.isError || query.isRefetchError) {
      appState.loyalty.customer.request.value = {
        isFetching: false,
        isFetched: false,
        isError: true,
      };
      appState.customer.reset();
      console.error('Error fetching customer details: ', query.error);
      switch (searchType) {
        case SearchType.EMAIL:
          enqueueSnackbar(t('errors.failedToFetchByEmail'));
          break;
        case SearchType.MEMBER_ID:
          enqueueSnackbar(t('errors.failedToFetchByMembershipId'));
          break;
        case SearchType.GIGYA_ID:
          enqueueSnackbar(t('errors.failedToFetchByGigyaId'));
          break;
        default:
          enqueueSnackbar(t('errors.selectSearchType'));
      }
    } else if (query.isFetching || query.isRefetching) {
      appState.loyalty.customer.request.value = {
        isFetching: true,
        isFetched: false,
        isError: false,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.isFetching,
    query.isRefetching,
    query.dataUpdatedAt,
    query.errorUpdatedAt,
  ]);

  return query;
};

export const useAddCustomerCommentMutation = (
  options?: Pick<
    UseMutationOptions<
      unknown,
      Error,
      {
        customerUid: string;
        code: string;
      }
    >,
    'onSuccess' | 'onError'
  >,
) => {
  return useMutation({
    mutationFn: async (params) => {
      const url = `${appConfig.sgApiBaseUrlV1}customer-service/users/${params.customerUid}/comments/${params.code}`;
      return await performADRequest('POST', url);
    },
    ...options,
  });
};

/**
 * Fetches member offers based on various criteria.
 *
 * @param {Object} params - The parameters for fetching member offers.
 * @param {string} params.trackingId - The tracking ID of the member.
 * @param {string} params.brand - The brand associated with the member.
 * @param {boolean} [params.isActive] - Whether the offer is active.
 * @param {boolean} [params.isRedeemable] - Whether the offer can be redeemed.
 * @param {Date} [params.validAt=new Date()] - The date at which the offer is valid.
 * @param {string} [params.validFrom] - The start date of the offer validity.
 * @param {string} [params.validUntil] - The end date of the offer validity.
 * @param {string} [params.type] - The type of the offer.
 * @param {string} [params.offerTacticType] - The tactic type of the offer.
 * @param {string[]} [params.offerTactic] - The tactics of the offer.
 * @returns {Promise<OffersData>} A promise that resolves to the offers data.
 */
export const fetchMemberOffers = async ({
  trackingId,
  brand,
  isActive,
  isRedeemable,
  validAt = new Date(),
  validFrom,
  validUntil,
  type,
  offerTacticType,
  offerTactic,
}: GetMemberOffers): Promise<OffersData> => {
  let queryParams = new URLSearchParams();
  if (isActive !== undefined) queryParams.append('isActive', String(isActive));
  if (validAt) queryParams.append('validAt', validAt.toISOString());
  if (isRedeemable !== undefined)
    queryParams.append('isRedeemable', String(isRedeemable));
  if (validFrom) queryParams.append('validFrom', validFrom);
  if (validUntil) queryParams.append('validUntil', validUntil);
  if (type) queryParams.append('type', type);
  if (offerTacticType) queryParams.append('offerTacticType', offerTacticType);
  if (offerTactic)
    offerTactic.forEach((t) => queryParams.append('offerTactic', t));

  const url = `${appConfig.sgApiBaseUrlV1}loyalty/${brand}/members/${trackingId}/offers?${queryParams}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

export const useMemberOffersRequest = (
  trackingId: string,
  validAt: Date | undefined,
  brand?: string,
  initialOffers?: OffersData,
) => {
  return useQuery<OffersData>({
    queryKey: ['memberOffers', trackingId, brand, validAt],
    queryFn: () =>
      fetchMemberOffers({
        trackingId,
        brand: brand as string,
        validAt,
      }),
    retry: 1,
    initialData: initialOffers,
    enabled: !!trackingId && !!brand,
    staleTime: 60000,
  });
};

/**
 * Fetches the credit card details for a customer.
 *
 * @param cdcId - The SAP Customer Data Cloud ID.
 * @returns A promise that resolves to an array of credit card details.
 * @throws CustomError if the request fails.
 */
export const fetchCardDetails = async (
  cdcId: string,
): Promise<ICreditCardDetail[]> => {
  const url = `${appConfig.sgApiBaseUrlV1}customers/${cdcId}/cards`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch card details`);
  }
};

/**
 * Deletes a loyalty customer by unsubscribing them from the loyalty program.
 *
 * @param brand - The brand associated with the customer.
 * @param gigyaId - The customer's Gigya ID.
 * @returns A promise that resolves when the customer has been successfully unsubscribed.
 * @throws An error if the request to delete the loyalty customer fails.
 */
export const deleteLoyaltyCustomer = async (brand: string, gigyaId: string) => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/unsubscribe/${gigyaId}`;
  try {
    return await performADRequest('POST', url);
  } catch (error) {
    throw new Error(`Failed to delete loyalty: ${error}`);
  }
};

/**
 * Fetches customer details based on the provided email address.
 *
 * @param {string} customerEmail - The email address of the customer to look up.
 * @returns {Promise<User>} A promise of type IUser.
 */
const fetchCustomerDetails = async (customerEmail: string): Promise<User> => {
  const options: AxiosRequestConfig = {
    method: `GET`,
    url: `${appConfig.sgApiBaseUrlV1}customer-service/customers/lookup?email=${customerEmail}`,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': `application/json`,
    },
    timeout: 5000,
  };
  return axios(options).then((response) => {
    return response.data;
  });
};

/**
 * Fetches customer details associated with the specified brand.
 *
 * @param customerEmail - The customer's email.
 * @param brand - The selected brand.
 * @returns A promise that resolves to the customer details for the specified brand.
 */
const fetchCustomerDetailsWithBrand = async (
  customerEmail: string,
  brand: string,
): Promise<CustomerDetailsByBrand> => {
  const encodedEmail = encodeURIComponent(customerEmail);
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile?email=${encodedEmail}`;
  const headers = await getCommonHeaders();
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers,
    timeout: 5000,
  };

  return axios(url, options).then((response) => {
    return response.data;
  });
};

/**
 * Fetches the loyalty profile of a customer by their membership ID.
 *
 * @param memberId - The membership ID of the customer.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
const fetchLoyaltyProfileByMembershipId = async (
  memberId: string,
): Promise<CustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/loyalty/member/${memberId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by member ID:`);
  }
};

/**
 * Fetches the loyalty profile of a customer by their Gigya ID.
 *
 * @param gigyaId - The customer's Gigya ID.
 * @param brand - The loyalty brand being searched for.
 * @returns A promise that resolves to the customer's details by brand.
 * @throws Will throw an error if the request fails.
 */
const fetchLoyaltyProfileByGigyaId = async (
  gigyaId: string,
  brand: string,
): Promise<CustomerDetailsByBrand> => {
  const url = `${appConfig.sgApiBaseUrlV1}customer-service/${brand}/loyalty/profile/users/${gigyaId}`;
  try {
    return await performADRequest('GET', url);
  } catch (error) {
    throw CustomError.fromError(error, `Failed to fetch member by gigya ID:`);
  }
};

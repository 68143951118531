import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deFlag from '@/assets/flags/de.svg';
import daFlag from '@/assets/flags/dk.svg';
import enFlag from '@/assets/flags/en.svg';
import plFlag from '@/assets/flags/pl.svg';
import da from '@/locales/da.json';
import de from '@/locales/de.json';
import en from '@/locales/en.json';
import pl from '@/locales/pl.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  da: {
    translation: da,
  },
  pl: {
    translation: pl,
  },
} as const;

const flags = {
  en: enFlag,
  da: daFlag,
  de: deFlag,
  pl: plFlag,
} as const;

export type Locale = keyof typeof resources;

export const locales = Object.keys(resources) as ReadonlyArray<Locale>;

const isLocale = (locale: string): locale is Locale =>
  locales.find((value) => value === locale) !== undefined;

export const toLocale: (locale: string) => Locale = (locale: string) =>
  isLocale(locale) ? locale : 'en';

export const getFlag = (locale: Locale) => {
  return flags[locale];
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  supportedLngs: locales,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import { StyledBorderCard } from '@/components/shared/mui-helpers/customComponentStyles';

const ReceiptItemSkeleton: React.FC = () => {
  return (
    <StyledBorderCard>
      <CardContent>
        <Skeleton variant="text" width="60%" sx={{ margin: '0 auto' }} />
        <Skeleton variant="text" width="40%" sx={{ margin: '0 auto' }} />
        <Skeleton variant="text" width="80%" sx={{ margin: '0 auto' }} />
        <Divider sx={{ my: 2 }} />
        <List sx={{ textTransform: 'uppercase' }}>
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListItemButton
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: 0,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="text" width="60%" />
                </Box>
                <Skeleton variant="text" width="20%" />
              </ListItemButton>
            </Box>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <Skeleton variant="text" width="30%" sx={{ marginLeft: 'auto' }} />
      </CardContent>
    </StyledBorderCard>
  );
};

export default ReceiptItemSkeleton;

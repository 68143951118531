import { Check, HourglassEmpty, QuestionMark } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getPaymentProviderLabelKey } from '@/services/paymentService.interface';
import {
  Payment,
  PaymentEventDetails,
  getPaymentEventDetailsForType,
} from '@/services/paymentService.interface';
import { formatDateWithTimestamp } from '@/utils/date-utils/formatDateWithTimestamp';
import { currencyFormatter } from '@/utils/priceFormatter';

interface Props {
  payment: Payment;
  currency: string;
  defaultExpanded?: boolean;
}

const getIconForPaymentEventType = (type: PaymentEventDetails) => {
  switch (type) {
    case PaymentEventDetails.SUCCESS:
      return (
        <Avatar
          sx={{ backgroundColor: '#128000', width: '1.8rem', height: '1.8rem' }}
        >
          <Check fontSize="small" />
        </Avatar>
      );
    case PaymentEventDetails.PENDING:
      return (
        <Avatar
          sx={{
            backgroundColor: '#d3d3d3',
            width: '1.8rem',
            height: '1.8rem',
          }}
        >
          <HourglassEmpty fontSize="small" />
        </Avatar>
      );
    case PaymentEventDetails.ERROR:
      return (
        <Avatar
          sx={{ backgroundColor: '#fd381b', width: '1.8rem', height: '1.8rem' }}
        >
          <WarningAmber sx={{ fontSize: '1.2rem', marginBottom: '1px' }} />
        </Avatar>
      );
    default:
      return (
        <Avatar
          sx={{
            backgroundColor: '#d3d3d3',
            width: '1.8rem',
            height: '1.8rem',
          }}
        >
          <QuestionMark fontSize="small" />
        </Avatar>
      );
  }
};

export const PaymentSetDialogPaymentDetails: FC<Props> = ({
  payment,
  currency,
  defaultExpanded,
}) => {
  const { t } = useTranslation();

  const lastState = getPaymentEventDetailsForType(
    payment.events[payment.events.length - 1]?.type ??
      PaymentEventDetails.PENDING,
  );

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${payment.id}-content`}
        id={`panel-${payment.id}-header`}
      >
        <Stack
          direction="row"
          spacing={4}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ flex: '1 1 100%' }}
          >
            {lastState.type === PaymentEventDetails.ERROR && <WarningAmber />}
            <Typography variant="button">
              {t(getPaymentProviderLabelKey(payment.providerId))}
            </Typography>
            {payment.paymentMethod && (
              <Typography variant="overline">
                ({payment.paymentMethod})
              </Typography>
            )}
          </Stack>
          <Typography>{currencyFormatter(payment.amount, currency)}</Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        {payment.events.length === 0 && (
          <Typography>{t('payment.noEvents')}</Typography>
        )}
        {payment.events.length > 0 && (
          <Stepper orientation={'vertical'}>
            {payment.events.map((event, index) => {
              const details = getPaymentEventDetailsForType(event.type);
              return (
                <Step
                  key={event.id}
                  active={index === payment.events.length - 1}
                >
                  <StepLabel icon={getIconForPaymentEventType(details.type)}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>{t(details.labelKey)}</Typography>
                      <Typography>
                        {currencyFormatter(event.amount, currency)}
                      </Typography>
                    </Stack>

                    <Typography sx={{ fontSize: '.8rem' }}>
                      {formatDateWithTimestamp(event.created_at)}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentSetDialogPaymentDetails;

import ListItem from '@mui/material/ListItem';
import React from 'react';

import { StyledDescription } from '@/components/shared/mui-helpers/customComponentStyles';

interface DetailItemProps {
  label: string;
  value: React.ReactNode;
  styles?: React.CSSProperties;
  onClick?: () => void;
}

const DetailItem = React.forwardRef<HTMLLIElement, DetailItemProps>(
  ({ label, value, styles, onClick, ...props }, ref) => (
    <ListItem
      disablePadding
      onClick={onClick}
      sx={{ ...styles }}
      {...props} // this ensures all other props are passed down to the ListItem including the ones from tooltip
      ref={ref}
    >
      <StyledDescription>
        <strong>{label}:</strong> {value}
      </StyledDescription>
    </ListItem>
  ),
);
DetailItem.displayName = 'DetailItem';

export default DetailItem;

import { FirestoreDataConverter, getDoc } from '@firebase/firestore';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';

import { SettingsContext } from '@/components/providers/SettingsContext';
import {
  Settings,
  SidebarModes,
  ThemeModes,
  TicketNotificationBehavior,
} from '@/components/providers/SettingsProvider.interface';
import { DEFAULT_BRAND } from '@/configs/brandConfig';
import { db } from '@/configs/firebaseConfig';
import { toLocale } from '@/configs/i18nConfig';
import {
  DEFAULT_SECTION,
  isSupportedBrand,
  toAppSection,
} from '@/hooks/useAppSection';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';

const STORAGE_KEY = 'sg-ds-360-user-settings-cache';

// Firestore data converter
const settingsConverter: FirestoreDataConverter<Settings> = {
  toFirestore: (settings) => {
    return {
      settings: {
        locale: settings.locale || null,
        brand: settings.brand || null,
        initialView: settings.initialView || null,
        themeMode: settings.themeMode ?? ThemeModes.system,
        ticketNotificationBehavior: settings.ticketNotificationBehavior || null,
        ticketNotificationExpiryIntervalInMinutes:
          settings.ticketNotificationExpiryIntervalInMinutes || 0,
        sidebar: settings.sidebar ?? 'open',
      },
    };
  },
  fromFirestore: (snapshot, options): Settings => {
    const settings = snapshot.data(options).settings;

    const initialView = toAppSection(settings?.initialView);

    return {
      locale: toLocale(settings?.locale),
      initialView: initialView,
      brand: isSupportedBrand(initialView, settings?.brand)
        ? settings?.brand
        : DEFAULT_BRAND,
      themeMode: settings?.themeMode ?? ThemeModes.system,
      ticketNotificationBehavior:
        settings?.ticketNotificationBehavior ??
        TicketNotificationBehavior.PROMPT,
      ticketNotificationExpiryIntervalInMinutes:
        settings?.ticketNotificationExpiryIntervalInMinutes ?? 0,
      sidebar: (settings?.sidebar as SidebarModes) ?? 'open',
    };
  },
};

export const SettingsProvider = (props: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const { accountId } = useAuthorizationHook();
  const [settings, setSettings] = useLocalStorage<Settings>(STORAGE_KEY, {
    locale: toLocale(i18n.language),
    brand: DEFAULT_BRAND.key,
    initialView: DEFAULT_SECTION,
    themeMode: ThemeModes.system,
    ticketNotificationBehavior: TicketNotificationBehavior.PROMPT,
    ticketNotificationExpiryIntervalInMinutes: 0,
    sidebar: 'collapse',
  });

  // A local cache of the server settings; this is used to determine whether we
  // need to write to Firestore. The Firestore library also provides a cache,
  // maybe we could use that instead?
  const [serverSettings, setServerSettings] = useState<Settings | null>(null);

  const value = useMemo(
    () => ({ settings, setSettings: setSettings }),
    [settings, setSettings],
  );

  useMemo(() => {
    if (!accountId) {
      console.warn('No account ID; cannot obtain settings');
      return;
    }

    const docRef = doc(db, 'users', accountId).withConverter(settingsConverter);

    // Clean up the listener when the component unmounts
    return onSnapshot(
      docRef,
      (snapshot) => {
        const settings = snapshot.data();

        if (settings) {
          if (!snapshot.metadata.hasPendingWrites) {
            console.debug('Received data from Firestore', settings);
            setSettings(settings);
          }

          setServerSettings(settings);
        }
      },
      (error) => {
        console.error('Error getting document From firestore: ', error);
      },
    );
  }, [accountId, setSettings, setServerSettings]);

  useMemo(async () => {
    if (accountId) {
      const docRef = doc(db, 'users', accountId).withConverter(
        settingsConverter,
      );

      // Only write to Firestore if we haven't read but the document exists, or
      // if the settings have changed
      const shallWrite: boolean = serverSettings
        ? !isDeepEqual(settings, serverSettings)
        : !(await getDoc(docRef)).exists();

      if (shallWrite) {
        console.debug('Saving settings to Firestore', settings);
        await setDoc(docRef, settings, { merge: true });
      }
    }
  }, [accountId, settings, serverSettings]).catch((error) => {
    console.error('Error saving settings to Firestore: ', error);
  });

  useMemo(async () => {
    if (settings.locale) {
      await i18n.changeLanguage(settings.locale);
    }
  }, [settings.locale, i18n]).catch((error) => {
    console.error('Error changing language: ', error);
  });

  return (
    <SettingsContext.Provider value={value}>
      {props.children}
    </SettingsContext.Provider>
  );
};

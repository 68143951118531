import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import CarlsJrIcon from '@/assets/logos/icon_carls_jr.svg?react';
import { BrandIconProps } from '@/components/shared/logos/props';

const BrandIconCarlsJr: FC<BrandIconProps> = ({
  themeAware = true,
  primaryColor,
  ...other
}) => {
  return (
    <SvgIcon {...other}>
      <CarlsJrIcon />
    </SvgIcon>
  );
};

export default BrandIconCarlsJr;

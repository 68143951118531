import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateWithTimestamp } from '@/utils/date-utils/formatDateWithTimestamp';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface Props {
  isWalletExisting: boolean;
  isWalletBlocked?: boolean;
  walletLastBlockedAt?: string;
}

const HasWalletValue: FC<Props> = ({
  isWalletExisting,
  isWalletBlocked,
  walletLastBlockedAt,
}) => {
  const { t } = useTranslation();

  const hasWalletValue = emptyValueReplacement(isWalletExisting, t);

  if (isWalletBlocked) {
    return (
      <Tooltip
        title={t('wallet.tooltip.blocked', {
          date: formatDateWithTimestamp(walletLastBlockedAt!),
        })}
      >
        <Box sx={{ color: 'warning.main' }}>
          <span>{hasWalletValue}</span>
          <WarningAmber
            data-test
            sx={{ marginLeft: '.15em', verticalAlign: 'middle' }}
          />
        </Box>
      </Tooltip>
    );
  }

  return <span>{emptyValueReplacement(hasWalletValue)}</span>;
};

export default HasWalletValue;

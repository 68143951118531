import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import {
  eCommerceRoute,
  loyaltyRoute,
  stocksRoute,
  trackingRoute,
} from '@/routing/index';

const NotFoundView = () => {
  const { t } = useTranslation();
  const { isEcomAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{ typography: { xs: 'h4', sm: 'h3' } }}
        color="error"
        gutterBottom
      >
        {t('messages.notFound')}
      </Typography>
      <Typography px={2} variant="body1" gutterBottom>
        {t('messages.pageNotFound')}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        {isLoyaltyAuthorized ? <Link href={loyaltyRoute}>Loyalty</Link> : null}
        {isEcomAuthorized ? (
          <>
            <Link href={eCommerceRoute}>Ecommerce</Link>
            <Link href={trackingRoute}>Tracking</Link>
            <Link href={stocksRoute}>Stocks</Link>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default NotFoundView;

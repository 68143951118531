import { algoliasearch } from 'algoliasearch';

const algoliaConfig = {
  apiKey:
    import.meta.env.VITE_ALGOLIA_API_KEY ||
    '8291abf114a6b5eb41e3b17260f95b29',
  appID:
    import.meta.env.VITE_ALGOLIA_APP_ID ||
    'DRP4O45G5T',
  bilkaIndex:
    import.meta.env.VITE_ALGOLIA_BILKA_INDEX ||
    'dev_BILKA_PRODUCTS',
  foetexIndex:
    import.meta.env.VITE_ALGOLIA_FOETEX_INDEX ||
    'dev_FOETEX_PRODUCTS',
  brIndex:
    import.meta.env.VITE_ALGOLIA_BR_INDEX ||
    'dev_BR_PRODUCTS',
};

const algoliaClient = algoliasearch(algoliaConfig.appID, algoliaConfig.apiKey);

export { algoliaClient, algoliaConfig };

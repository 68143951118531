import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import React from 'react';

import { getBrandSiteUrl } from '@/configs/brandConfig';
import { BrandKey } from '@/configs/brandConfig.interface';

interface IProductLinkProps {
  brand: BrandKey;
  productId?: string;
  children?: React.ReactNode;
}

const ProductLink: React.FC<IProductLinkProps> = React.forwardRef<
  HTMLAnchorElement,
  IProductLinkProps
>(({ brand, productId, children, ...other }, ref) => {
  if (Boolean(children)) {
    return (
      <Button
        size="small"
        variant="outlined"
        href={getBrandSiteUrl(brand, productId)}
        target="_blank"
        component={Link}
        rel="noopener noreferrer"
        endIcon={<OpenInNewIcon fontSize="small" />}
        {...other}
        ref={ref}
      >
        {children}
      </Button>
    );
  }

  return (
    <IconButton
      href={getBrandSiteUrl(brand, productId)}
      target="_blank"
      component={Link}
      rel="noopener noreferrer"
      {...other}
      ref={ref}
    >
      <OpenInNewIcon fontSize="small" />
    </IconButton>
  );
});
ProductLink.displayName = 'ProductLink';

export default ProductLink;

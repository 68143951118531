import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import OrderBreadCrumbs from '@/components/sections/ecommerce/breadcrumbs/OrderBreadCrumbs';
import EcomSearchBar from '@/components/sections/ecommerce/search/EcomSearchBar';

const EcommerceLayout = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box px={3} sx={{ width: '100%', maxWidth: '1440px' }}>
        <EcomSearchBar />
        <OrderBreadCrumbs />
        <Outlet />
      </Box>
    </Box>
  );
};

export default EcommerceLayout;

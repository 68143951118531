import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { appConfig } from '@/configs/appConfig';
import { BrandKey } from '@/configs/brandConfig.interface';
import useAppSection from '@/hooks/useAppSection';
import { eCommerceRoute } from '@/routing';
import {
  CustomerRemarkPost,
  GetOrderDetailsParams,
  GetOrderListParams,
  OrderDetailsResult,
  OrderResult,
  PostCustomerRemark,
} from '@/services/orderService.interface';
import eCommerceState, {
  OrderPeriodFilterOption,
} from '@/state/eCommerceState';
import { SearchType } from '@/utils/checkInputType';
import isErrorInstance from '@/utils/error-handler/IsErrorInstance';
import updateSearchParams from '@/utils/updateSearchParams';

interface IOrderService {
  getShortOrderDetails(params: GetOrderDetailsParams): Promise<OrderResult[]>;

  getFullOrderDetails(
    params: GetOrderDetailsParams,
  ): Promise<OrderDetailsResult>;

  getOrderList(params: GetOrderListParams): Promise<OrderResult[]>;

  postCustomerRemark(params: PostCustomerRemark): Promise<void>;
}

export const useOrderQueries = () => {
  const orderService = useMemo(() => new OrderService(), []);
  const [searchParams, setSearchParams] = useSearchParams();

  const { supportedBrands } = useAppSection();

  let filteredBrands = eCommerceState.filter.brands.value;
  if (filteredBrands.length === 0) {
    filteredBrands = supportedBrands.map((brand) => brand.key);
  }

  const currentDate = useMemo(() => new Date(), []);
  const periodFilter = useMemo(() => {
    let from: Date | undefined;

    switch (eCommerceState.filter.period.value) {
      case OrderPeriodFilterOption.LAST_3_MONTHS:
        from = new Date(currentDate);
        from.setMonth(from.getMonth() - 3);
        break;
      case OrderPeriodFilterOption.LAST_6_MONTHS:
        from = new Date(currentDate);
        from.setMonth(from.getMonth() - 6);
        break;
      case OrderPeriodFilterOption.LAST_YEAR:
        from = new Date(currentDate);
        from.setFullYear(from.getFullYear() - 1);
        break;
      case OrderPeriodFilterOption.ALL_TIME:
        break;
    }

    return { from, to: undefined };
  }, [eCommerceState.filter.period.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const ordersQuery = useQueries({
    queries: filteredBrands.map((brand) => ({
      queryKey: [
        'orders',
        'list',
        brand,
        eCommerceState.context.query.value,
        JSON.stringify(eCommerceState.filter.period.value),
      ],
      queryFn: async () => {
        return orderService.getOrderList({
          identifier: SearchType.EMAIL,
          input: eCommerceState.context.query.value,
          brand,
          validFrom: periodFilter?.from,
          validTo: periodFilter?.to,
        });
      },
      select: (data: OrderResult[]) =>
        data.map((order) => ({
          ...order,
          brand,
        })),
      enabled: eCommerceState.context.type.value === SearchType.EMAIL,
    })),
    combine: (results) => ({
      data: results
        .filter((results) => results !== undefined)
        .flatMap((result) => result.data!)
        .filter((order) => order !== undefined),
      isSuccess: results.every((result) => result.isSuccess),
      isError: results.some((result) => result.isError),
      isLoading: results.some((result) => result.isLoading),
      isRefetching: results.some((result) => result.isRefetching),
      error: results.find((result) => result.error),
      dataUpdatedAt: results.reduce(
        (acc, result) => Math.max(acc, result.dataUpdatedAt),
        0,
      ),
      errorUpdatedAt: results.reduce(
        (acc, result) => Math.max(acc, result.errorUpdatedAt),
        0,
      ),
    }),
  });

  useEffect(() => {
    if (eCommerceState.context.query.value && ordersQuery.isSuccess) {
      if (ordersQuery.data.length > 0) {
        if (eCommerceState.context.type.value === SearchType.EMAIL) {
          const newSearchParams = updateSearchParams(
            searchParams,
            filteredBrands,
            SearchType.EMAIL,
            eCommerceState.context.query.value,
          );
          setSearchParams(newSearchParams);
        }
      }
    }

    if (ordersQuery.isError) {
      if (isErrorInstance(ordersQuery.error)) {
        enqueueSnackbar(
          ordersQuery.error.responseJSON
            ? ordersQuery.error.responseJSON.message
            : 'Ukendt fejl',
          {
            variant: 'error',
            autoHideDuration: 4000,
          },
        );
      }
      console.error(ordersQuery.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersQuery.dataUpdatedAt, ordersQuery.errorUpdatedAt]);

  return ordersQuery;
};

export const useOrdersQuery = (
  search: { brand: BrandKey; type: SearchType; query: string } | undefined,
) => {
  const orderService = useMemo(() => new OrderService(), []);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const ordersQuery = useQuery<OrderResult[]>({
    queryKey: ['orders', 'list', search?.brand, search?.query],
    queryFn: async () => {
      switch (search!.type) {
        case SearchType.ORDER_ID:
          return orderService.getShortOrderDetails({
            orderId: search!.query,
            brand: search!.brand,
          });
        case SearchType.EMAIL:
          return orderService.getOrderList({
            identifier: SearchType.EMAIL,
            input: search!.query,
            brand: search!.brand,
          });
      }

      return Promise.reject(new Error('Invalid search type'));
    },
    enabled: Boolean(search),
    staleTime: 60000,
  });

  useEffect(() => {
    if (ordersQuery.isSuccess) {
      if (ordersQuery.data.length === 0) {
        navigate(`${eCommerceRoute}/`);
        enqueueSnackbar(
          `Ingen ordre fundet i ${search?.brand} til ${search?.query}`,
        );
      } else {
        const newSearchParams = updateSearchParams(
          searchParams,
          search!.brand,
          search!.type,
          search!.query,
        );
        setSearchParams(newSearchParams);

        if (search?.type !== SearchType.ORDER_ID) {
          navigate(`${eCommerceRoute}?${newSearchParams}`);
        } else {
          navigate(
            `${eCommerceRoute}/brands/${search?.brand}/orders/${search?.query}?${newSearchParams}`,
          );
        }
      }
    }

    if (ordersQuery.isError) {
      if (isErrorInstance(ordersQuery.error)) {
        enqueueSnackbar(
          ordersQuery.error.responseJSON
            ? ordersQuery.error.responseJSON.message
            : 'Ukendt fejl',
          {
            variant: 'error',
            autoHideDuration: 4000,
          },
        );
      }
      console.error(ordersQuery.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersQuery.dataUpdatedAt, ordersQuery.errorUpdatedAt]);

  return ordersQuery;
};

export const useOrderQuery = (orderId: string | undefined) => {
  const orderService = useMemo(() => new OrderService(), []);

  const orderQuery = useQuery<OrderDetailsResult>({
    queryKey: ['orders', 'details', orderId],
    queryFn: async () => {
      return orderService.getFullOrderDetails({
        orderId: orderId!,
        brand: 'bilka',
      });
    },
    staleTime: 60000,
    enabled: Boolean(orderId),
  });

  useEffect(() => {
    if (orderQuery.isError) {
      enqueueSnackbar(`Ingen detaljer fundet for ordre-id: ${orderId}`);
      console.error('Error fetching order details', orderQuery.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderQuery.dataUpdatedAt, orderQuery.errorUpdatedAt]);

  return orderQuery;
};

export const useCustomerRemarkMutation = () => {
  const orderService = useMemo(() => new OrderService(), []);

  const mutation = useMutation({
    mutationKey: ['customerRemark'],
    mutationFn: async (params: PostCustomerRemark) => {
      return orderService.postCustomerRemark(params);
    },
  });

  useEffect(() => {
    if (mutation.isError) {
      enqueueSnackbar(
        `Kunne ikke oprette kommentar på ordren.  ${mutation.error}`,
        {
          variant: 'error',
          autoHideDuration: 4000,
        },
      );
    }
  }, [mutation.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return mutation;
};

/**
 * Retrieves short order details based on the provided parameters.
 *
 * @param params - The parameters required to get the order details.
 * @returns A promise that resolves to an array of order results containing
 *          orderId, price, email, phone, and orderTime.
 * @throws Will reject the promise if an error occurs while fetching the order details.
 */
class OrderService implements IOrderService {
  public getShortOrderDetails = async (
    params: GetOrderDetailsParams,
  ): Promise<OrderResult[]> => {
    try {
      const orderDetails = await this.getFullOrderDetails(params);
      const orderObject: OrderResult = {
        orderId: orderDetails.orderNumber,
        price:
          orderDetails.total +
          orderDetails.deliveryPrice -
          orderDetails.totalDiscount,
        email: orderDetails.billingAddress.email,
        phone: orderDetails.billingAddress.mobile,
        orderTime: new Date(orderDetails.orderDate),
      };
      return Promise.resolve([orderObject]);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Retrieves the full details of an order based on the provided parameters.
   *
   * @param params - The parameters required to fetch the order details.
   * @param params.brand - The brand associated with the order. Defaults to 'bilka' if not provided.
   * @param params.orderId - The unique identifier of the order.
   *
   * @returns A promise that resolves to the order details.
   *
   * @throws Throws an error if the request fails or times out.
   */
  public getFullOrderDetails = async (
    params: GetOrderDetailsParams,
  ): Promise<OrderDetailsResult> => {
    const brand = params.brand ? params.brand : 'bilka';
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: `${appConfig.sgApiNextOrdersUrlV2}${brand}/orders/${params.orderId}`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      timeout: 5000,
    };
    const response = await axios(options);
    return response.data;
  };

  /**
   * Retrieves a list of orders based on the provided parameters.
   *
   * @param params - The parameters required to fetch the order list.
   * @returns A promise that resolves to an array of order results.
   */
  public getOrderList = async (
    params: GetOrderListParams,
  ): Promise<OrderResult[]> => {
    const url = `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders?${params.identifier}=${params.input}`;
    const options: AxiosRequestConfig = {
      method: `GET`,
      url: url,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': `application/json`,
      },
      params: {
        validFrom: formatISODate(params.validFrom),
        validTo: formatISODate(params.validTo),
      },
      timeout: 5000,
    };
    const response = await axios(options);
    return response.data;
  };

  /**
   * Posts a customer remark(comments from the customer) to the specified order.
   *
   * @param params - The parameters required to post a customer remark.
   * @returns A promise that resolves when the customer remark has been posted.
   * @throws Will throw an error if the request fails.
   *
   */
  public postCustomerRemark = async (
    params: PostCustomerRemark,
  ): Promise<void> => {
    const options: AxiosRequestConfig<CustomerRemarkPost> = {
      method: 'POST',
      url: `${appConfig.sgApiNextOrdersUrlV1}${params.brand}/orders/${params.orderId}/remarks/`,
      headers: {
        Authorization: `Bearer ${appConfig.sgBearer}`,
        'Content-Type': 'application/json',
      },
      data: {
        erpOrderNumber: params.erpOrderNumber,
        customerRemarks: params.customerRemarks,
      },
      timeout: 5000,
    };
    const response = await axios(options);
    return response.data;
  };
}

const formatISODate = (date?: Date) => {
  if (!date) {
    return null;
  }

  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');

  return `${year}-${month}-${day}`;
};

import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useMatch,
  useSearchParams,
} from 'react-router-dom';

import { eCommerceRoute } from '@/routing';
import eCommerceState from '@/state/eCommerceState';

const OrderBreadCrumbs = () => {
  const { t } = useTranslation();

  const isOrderListPath = useMatch('/ecommerce');
  const isOrderDetailsPath = useMatch('/ecommerce/:orderId');

  const [searchParams] = useSearchParams();

  const customerEmail = searchParams.get('email');

  const handleResetSearch = () => {
    eCommerceState.search.reset();
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: 3 }}>
      <HomeIcon
        fontSize="small"
        sx={{ display: 'flex', alignItems: 'center' }}
      />

      {(isOrderListPath || isOrderDetailsPath) && (
        <Link
          component={RouterLink}
          to={eCommerceRoute}
          underline="hover"
          onClick={handleResetSearch}
        >
          {t('orderDetails.breadcrumbs.orders')}
        </Link>
      )}

      {customerEmail && (
        <Link
          component={RouterLink}
          to={`${eCommerceRoute}?&email=${customerEmail}`}
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('orderDetails.breadcrumbs.customer')}
          <Chip
            component="span"
            size="small"
            label={customerEmail}
            sx={{ marginLeft: '.25rem' }}
          />
        </Link>
      )}

      {isOrderDetailsPath && !customerEmail && (
        <Tooltip
          title={t('orderDetails.breadcrumbs.anonymousCustomer')}
          role="tooltip"
        >
          <span>
            <Link
              component={RouterLink}
              to="#"
              underline="hover"
              sx={{ pointerEvents: 'none', color: 'text.disabled' }}
            >
              {t('orderDetails.breadcrumbs.customer')}
            </Link>
          </span>
        </Tooltip>
      )}

      {isOrderDetailsPath && (
        <Typography
          color="text.primary"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('orderDetails.breadcrumbs.order')}
          <Chip
            size="small"
            label={isOrderDetailsPath.params.orderId}
            sx={{ marginLeft: '.25rem' }}
            component="span"
          />
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default OrderBreadCrumbs;

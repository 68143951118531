import React from 'react';
import { useTranslation } from 'react-i18next';

import PaymentDetail from '@/components/sections/loyalty/receipts/PaymentDetail';
import PaymentSummary from '@/components/sections/loyalty/receipts/PaymentSummary';
import { PaymentType } from '@/components/sections/loyalty/receipts/PaymentType';
import { Payment } from '@/services/receiptService.interface';

interface IPayments {
  payments?: Payment[];
}

const ReceiptPayment: React.FC<IPayments> = ({ payments }) => {
  const { t } = useTranslation();

  const isEmployeeDiscount = (paymentType: string): boolean =>
    paymentType === PaymentType.EMPLOYEE_DISCOUNT;

  const getPaymentLabel = (payment: Payment): string => {
    switch (payment.paymentType) {
      case PaymentType.CARD_ONLINE:
      case PaymentType.CARD_ONLINE_ALT:
        return t('receipt.paymentType.card');
      case PaymentType.CASH_PAYMENT:
      case PaymentType.CASH_PAYMENT_ALT:
        return t('receipt.paymentType.cash');
      case PaymentType.VOUCHER:
        return t(`receipt.paymentType.voucher`);
      case PaymentType.PANT_VOUCHER:
      case PaymentType.PANT_VOUCHER_ALT:
        return t('receipt.paymentType.pantVoucher');
      case PaymentType.GIFTCARD:
      case PaymentType.GIFTCARD_ALT:
        return t('receipt.paymentType.giftCard');
      case PaymentType.WALLET_PAYMENT:
      case PaymentType.WALLET_PAYMENT_ALT:
        return t('receipt.paymentType.wallet');
      default:
        return payment.paymentTypeLabel ?? payment.paymentType;
    }
  };

  const summaries: React.ReactNode[] = [];

  if (payments) {
    const totalAmount = payments.reduce(
      (sum: number, currentValue: Payment): number => sum + currentValue.price,
      0,
    );

    const employeeDiscountAmount = payments.reduce(
      (sum, payment) =>
        isEmployeeDiscount(payment.paymentType) ? sum + payment.price : sum,
      0,
    );

    // All payments should have the same currency, so we can just take the first one
    const currency = payments.map((payment) => payment.currency)[0];

    if (employeeDiscountAmount > 0) {
      summaries.push(
        <PaymentSummary
          key="employeeDiscountSummary"
          label={t('receipt.subtotal')}
          amount={totalAmount}
          currency={currency}
        />,
        <PaymentDetail
          key="employeeDiscountDetail"
          label={t('receipt.employeeDiscount')}
          amount={employeeDiscountAmount}
          currency={currency}
        />,
      );
    }

    summaries.push(
      <PaymentSummary
        key="totalSummary"
        label={t('receipt.total')}
        amount={totalAmount - employeeDiscountAmount}
        currency={currency}
      />,
      ...payments
        .filter((payment) => !isEmployeeDiscount(payment.paymentType))
        .map((payment, index) => (
          <PaymentDetail
            key={payment.paymentType + index}
            label={getPaymentLabel(payment)}
            amount={payment.price}
            currency={payment.currency}
          />
        )),
    );
  }

  return <>{summaries}</>;
};

export default ReceiptPayment;

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  expanded: boolean;
  title: string;
  icon: ReactNode;
  to?: string;
  onClick?: () => void;
}

export const NavigationItem = ({
  expanded,
  title,
  icon,
  to,
  onClick,
}: Props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Boolean(to) ? RouterLink : 'button'}
        to={to}
        onClick={onClick}
        sx={[
          expanded
            ? {
                justifyContent: 'initial',
                alignItems: 'center',
              }
            : {
                justifyContent: 'center',
                alignItems: 'center',
              },
        ]}
      >
        <ListItemIcon
          sx={[
            {
              justifyContent: 'center',
            },
          ]}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={[
            {
              margin: 0,
            },
            expanded
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                },
          ]}
        />
      </ListItemButton>
    </ListItem>
  );
};

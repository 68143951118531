import React from 'react';

import CustomerDetails from '@/components/shared/customer/CustomerDetails';
import Loader from '@/components/shared/mui-helpers/Loader';
import appState from '@/state/appState';

const CustomerTab = () => {
  const customerDetails = appState.customer.details.value;
  const profileDetails = appState.customer.profile.value;
  const creditCard = appState.loyalty.creditCard.data.value;
  const activeBrand = appState.loyaltySearch.brand.value;

  if (appState.loyalty.isFetchingMemberData.value) {
    return <Loader />;
  }

  return (
    <CustomerDetails
      user={customerDetails}
      plusProfile={profileDetails}
      creditCards={creditCard}
      activeBrand={activeBrand}
    />
  );
};

export default CustomerTab;

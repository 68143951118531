import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductHistory from '@/components/sections/ecommerce/details/ProductHistory';
import ProductLink from '@/components/sections/ecommerce/details/ProductLink';
import ProductPrice from '@/components/sections/ecommerce/details/ProductPrice';
import ProductTable from '@/components/sections/ecommerce/details/ProductTable';
import ProductTracking from '@/components/sections/ecommerce/details/ProductTracking';
import CustomImage from '@/components/shared/mui-helpers/CustomImage';
import { BrandKey } from '@/configs/brandConfig.interface';
import theme from '@/configs/themeConfig';
import { Product } from '@/services/orderService.interface';

interface ProductDialogProps {
  selectedProduct: Product | null;
  handleCloseDialog: () => void;
  productImage: string;
  brand: BrandKey | null | undefined;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  selectedProduct,
  handleCloseDialog,
  productImage,
  brand,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={Boolean(selectedProduct)}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      closeAfterTransition={false}
    >
      {selectedProduct && (
        <>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            sx={{ paddingLeft: 3, paddingTop: 3 }}
          >
            <DialogTitle
              id="dialog-title"
              sx={{ color: theme.palette.primary.main, padding: 0 }}
            >
              {selectedProduct.name}
            </DialogTitle>
            {brand && selectedProduct?.articleId && (
              <Tooltip
                sx={{ zIndex: 99 }}
                title={t('orderDetails.product.site')}
              >
                <ProductLink
                  brand={brand}
                  productId={selectedProduct.productId}
                />
              </Tooltip>
            )}
          </Box>
          <DialogContent id="dialog-description">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Paper variant={'outlined'} sx={{ alignContent: 'center' }}>
                <CustomImage
                  height={200}
                  title="product image"
                  imageId={productImage || ''}
                />
              </Paper>
              <Box sx={{ flex: 1 }}>
                <ProductTable product={selectedProduct} />
              </Box>
            </Stack>
            <ProductPrice product={selectedProduct} />
            <ProductTracking tracking={selectedProduct.tracking} />
            <ProductHistory
              history={selectedProduct.history}
              productId={selectedProduct.productId}
            />
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button
          autoFocus
          aria-label="Close product details dialog"
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;

import CommentIcon from '@mui/icons-material/Comment';
import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import { FC, useState } from 'react';

import CustomerRemarks from '@/components/sections/ecommerce/remarks/CustomerRemarks';
import { OrderDetailsResult } from '@/services/orderService.interface';

interface CustomerRemarksFABProps {
  order: OrderDetailsResult;
}

const CustomerRemarksFab: FC<CustomerRemarksFABProps> = ({ order }) => {
  const [open, setOpen] = useState(false);
  const [messagesLength, setMessagesLength] = useState<number>(
    order.customerRemarks.length,
  );

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const onAddedNote = () => {
    setMessagesLength(messagesLength + 1);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          zIndex: '10',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Badge color="warning" badgeContent={messagesLength}>
            <Fab color="primary" aria-label="edit" onClick={toggleDrawer(true)}>
              <CommentIcon />
            </Fab>
          </Badge>
        </Box>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <CustomerRemarks
          anchor="right"
          selectedOrderDetails={order}
          onAddedNote={onAddedNote}
        />
      </Drawer>
    </>
  );
};

export default CustomerRemarksFab;

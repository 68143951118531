import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DetailItem from '@/components/shared/mui-helpers/DetailItem';
import { PunchHistory } from '@/services/tradeDriverService.interface';
import { formatDate } from '@/utils/date-utils/formatDate';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface HistoryDetailsProps {
  punchHistory?: PunchHistory[] | null;
}

const PunchCardHistory: React.FC<HistoryDetailsProps> = ({ punchHistory }) => {
  const { t } = useTranslation();
  if (!punchHistory || punchHistory.length === 0) return null;

  return (
    <Box>
      <Divider sx={{ my: 2 }} />
      {punchHistory.map((history, index) => {
        const { sourceId, sourceType, punchesApplied, punchCreatedAt } =
          history;
        return (
          <Box key={`${index}-${sourceId}`} sx={{ marginBottom: '1rem' }}>
            <DetailItem
              label={t('punchCard.sourceId')}
              value={emptyValueReplacement(sourceId)}
            />
            <DetailItem
              label={t('punchCard.sourceType')}
              value={emptyValueReplacement(sourceType)}
            />
            <DetailItem
              label={t('punchCard.punchesApplied')}
              value={emptyValueReplacement(punchesApplied)}
            />
            <DetailItem
              label={t('punchCard.date')}
              value={punchCreatedAt ? formatDate(punchCreatedAt) : 'N/A'}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default PunchCardHistory;

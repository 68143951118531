import { useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '@/configs/appConfig';
import { AppSection, getSupportedBrands } from '@/hooks/useAppSection';
import {
  ShopRequestParams,
  Stock,
  StockGet,
  StockRequestParams,
  Store,
} from '@/services/stockService.interface';

const fetchStoresByBrand = async (
  params: ShopRequestParams,
): Promise<Store[]> => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${appConfig.sgApiStoreUrl}?brand=${params.brand}&per_page=${params.storesPerPage}`,
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': 'application/json',
    },
    timeout: 5000,
  };
  const response = await axios(config);
  return response.data;
};

const fetchStocks = async (params: StockRequestParams): Promise<Stock> => {
  const config: AxiosRequestConfig<StockGet> = {
    method: 'POST',
    url: `${appConfig.sgApiBaseUrlV1}stock-levels/articles/${params.articleId}/sites`,
    data: {
      sites: params.siteId,
    },
    headers: {
      Authorization: `Bearer ${appConfig.sgBearer}`,
      'Content-Type': 'application/json',
    },
    timeout: 5000,
  };
  const response = await axios(config);
  return response.data;
};

export const useStoresByBrand = (params: ShopRequestParams) => {
  const { t } = useTranslation();

  const storesQuery = useQuery<Store[]>({
    queryKey: ['storesByBrand', params],
    queryFn: () => fetchStoresByBrand(params),
    enabled: false,
  });

  useEffect(() => {
    if (storesQuery.isSuccess && !storesQuery.data) {
      enqueueSnackbar(t('errors.noStoresFound'));
    }

    if (storesQuery.isError || storesQuery.isRefetchError) {
      enqueueSnackbar(t('errors.storeFetchError'), { variant: 'error' });
      console.error('Error fetching stores', storesQuery.error);
    }
  }, [storesQuery.dataUpdatedAt, storesQuery.errorUpdatedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  return storesQuery;
};

export const useStocks = (params: StockRequestParams) => {
  const { t } = useTranslation();

  const stocksQuery = useQuery<Stock>({
    queryKey: ['stocks', params],
    queryFn: () => fetchStocks(params),
    enabled: false,
    retry: 1,
  });

  useEffect(() => {
    if (stocksQuery.isSuccess && !stocksQuery.data) {
      enqueueSnackbar(t('errors.noStocksFound'));
    }

    if (stocksQuery.isError || stocksQuery.isRefetchError) {
      enqueueSnackbar(t('errors.stockFetchError'), { variant: 'error' });
      console.error('Error fetching stocks', stocksQuery.error);
    }
  }, [stocksQuery.dataUpdatedAt, stocksQuery.errorUpdatedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  return stocksQuery;
};
const useFetchStores = () => {
  const [stores, setStores] = useState<Store[]>([]);
  const storesPerPage = 200;

  const foetexQuery = useStoresByBrand({ brand: 'foetex', storesPerPage });
  const bilkaQuery = useStoresByBrand({ brand: 'bilka', storesPerPage });
  const brQuery = useStoresByBrand({ brand: 'br', storesPerPage });

  useEffect(() => {
    getSupportedBrands(AppSection.Ecommerce).forEach((brand) => {
      if (brand.key === 'foetex') {
        foetexQuery.refetch();
      } else if (brand.key === 'bilka') {
        bilkaQuery.refetch();
      } else if (brand.key === 'br') {
        brQuery.refetch();
      }
    });

    // Add online warehouses manually as they cannot be fetched from the API
    setStores((prev) => [
      ...prev,
      { brand: 'online', sapSiteId: '9002', name: 'Årslev' },
      { brand: 'online', sapSiteId: '9010', name: 'Skejby' },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (foetexQuery.isSuccess) {
      setStores((prev) => [...prev, ...(foetexQuery.data || [])]);
    }
    if (bilkaQuery.isSuccess) {
      setStores((prev) => [...prev, ...(bilkaQuery.data || [])]);
    }
    if (brQuery.isSuccess) {
      setStores((prev) => [...prev, ...(brQuery.data || [])]);
    }
  }, [
    foetexQuery.data,
    bilkaQuery.data,
    brQuery.data,
    foetexQuery.isSuccess,
    bilkaQuery.isSuccess,
    brQuery.isSuccess,
  ]);

  return stores;
};
export default useFetchStores;

export const terminalMap = new Map<string, string>([
  ['801', 'PickUp HQ (ToGo)'],
  ['802', 'PickUp Skejby (ToGo)'],
  ['803', 'PickUp Køge (ToGo)'],
  ['804', 'PickUp Rødovre (ToGo)'],
  ['805', 'PickUp Holbæk (ToGo)'],
  ['806', 'PickUp Fredericia (ToGo)'],
  ['807', 'PickUp Tønder (ToGo)'],
  ['808', 'Hjørring (Pickup 1)'],
  ['809', 'Hjørring (Pickup 2)'],
  ['901', 'Bilka ToGo'],
  ['902', 'Bilka ToGo'],
  ['903', "MUH + Carl's JR"],
  ['904', 'Salling.dk'],
  ['906', 'BilkaTOGO Home Delivery'],
  ['907', 'NeXT GiftCard'],
  ['908', 'ReclaimIT Freight (return center 1803)'],
  ['909', 'Seamless Shopping (FotexGo)'],
  ['910', 'ToGo Pickup from Truck'],
  ['991', 'Scan&Go'],
  ['998', 'Digital Return'],
]);

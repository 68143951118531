import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomImage from '@/components/shared/mui-helpers/CustomImage';
import {
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';

interface ProofOfDeliveryProps {
  proofOfDeliveryUrls: string[];
}

const customImageStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

const renderProofOfDeliveryUrls = (urls: string[]) => (
  <Box sx={{ overflowX: 'auto' }}>
    <Grid container spacing={1} sx={{ flexWrap: 'nowrap' }}>
      {urls.map((url) => (
        <Grid key={url} size={12} sx={{ minWidth: '150px' }}>
          <StyledBorderCard variant="outlined" sx={{ height: '100%' }}>
            <Link
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block', height: '100%' }}
            >
              <CustomImage
                title="Proof of Delivery"
                styles={customImageStyles}
                fallbackImgUrl={url}
              />
            </Link>
          </StyledBorderCard>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const ProofOfDelivery: React.FC<ProofOfDeliveryProps> = ({
  proofOfDeliveryUrls,
}) => {
  const { t } = useTranslation();
  const hasProofOfDelivery = proofOfDeliveryUrls?.length > 0;

  return (
    <StyledBorderCard variant="outlined" sx={{ margin: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <StyledTitle>
          {hasProofOfDelivery
            ? t('tracking.proofOfDelivery')
            : t('tracking.noProofOfDelivery')}
        </StyledTitle>
        <StyledDescription>
          {hasProofOfDelivery && renderProofOfDeliveryUrls(proofOfDeliveryUrls)}
        </StyledDescription>
      </CardContent>
    </StyledBorderCard>
  );
};

export default ProofOfDelivery;

import Typography from '@mui/material/Typography';
import React from 'react';

import { appConfig } from '@/configs/appConfig';

const AppVersion = () => {
  // Hide the version if it's set to the default value containing 'PLACEHOLDER'
  // Those values should usually be replaced within the CI/CD pipeline
  if (
    !appConfig.releaseVersion ||
    appConfig.releaseVersion.includes('PLACEHOLDER')
  ) {
    return null;
  }

  return <Typography variant="caption">{appConfig.releaseVersion}</Typography>;
};

export default AppVersion;

/**
 * CustomError class is used to check if an error is of a specific type.
 * This allows us to determine if a request can be retried based on the error details.
 * We also map the error details to the CustomError class.
 */
export class CustomError extends Error {
  errorCode: number;
  path: string;

  constructor(message: string, errorCode: number, path: string) {
    super(message);
    this.errorCode = errorCode;
    this.path = path;
  }

  static fromError(error: any, defaultMessage: string): CustomError | Error {
    if (error.response && this.isCustomErrorPayload(error.response.data)) {
      const payload = error.response.data;
      return new CustomError(payload.message, payload.errorCode, payload.path);
    } else {
      return new Error(`${defaultMessage}: ${error.message}`);
    }
  }

  static isCustomErrorPayload(payload: unknown): payload is CustomError {
    if (typeof payload !== 'object' || payload === null) {
      return false;
    }
    const p = payload as { [key: string]: unknown };
    return (
      typeof p.path === 'string' &&
      typeof p.errorCode === 'number' &&
      typeof p.message === 'string'
    );
  }
}

import { useQuery } from '@tanstack/react-query';

export enum AlgoliaAttributes {
  PRIMARY_IMAGE_ID = 'primary_image_id',
}

export interface IAlgoliaProduct {
  primary_image_id: string;
}

export interface IProductImageParams {
  client: any;
  indexName?: string;
  objectID?: string;
  attributesToRetrieve: AlgoliaAttributes[];
}

const fetchProductImage = async (params: IProductImageParams) => {
  const { client, indexName, objectID, attributesToRetrieve } = params;
  return await client.getObject({
    indexName,
    objectID,
    attributesToRetrieve,
  });
};

export const useProductQuery = (params: IProductImageParams) => {
  const { objectID, indexName } = params;

  return useQuery<IAlgoliaProduct | undefined, Error, string | undefined>({
    queryKey: ['order', 'detail', 'image', objectID],
    queryFn: () => fetchProductImage(params),
    select: (data: IAlgoliaProduct | undefined) =>
      data?.primary_image_id ?? undefined,
    enabled: Boolean(objectID) && Boolean(indexName),
    retry: 1,
  });
};

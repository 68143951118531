import { batch, effect, signal } from '@preact/signals';

import { BrandKey } from '@/configs/brandConfig.interface';
import { Profile, User } from '@/services/customerService.interface';
import { BrandOrderResult } from '@/services/orderService.interface';

export const createDefaultCustomerState = () => {
  const customerState = {
    brand: signal<BrandKey | undefined>(undefined),
    details: signal<User | undefined>(undefined),
    profile: signal<Profile | undefined>(undefined),
    address: signal<string>(''),
    email: signal<string>(''),
    orders: signal<BrandOrderResult[] | undefined>(undefined),
    reset: () => {
      batch(() => {
        customerState.brand.value = undefined;
        customerState.details.value = undefined;
        customerState.profile.value = undefined;
        customerState.address.value = '';
        customerState.email.value = '';
        customerState.orders.value = undefined;
      });
    },
  };

  effect(() => {
    if (customerState.details.value) {
      customerState.email.value = customerState.details.value.email;
    }
  });

  return customerState;
};

const customerState = createDefaultCustomerState();

export default customerState;

import { Launch } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next';
import { FC, useEffect, useMemo } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import OrderFilter from '@/components/sections/ecommerce/search/OrderFilter';
import QuerySuspense from '@/components/shared/QuerySuspense';
import DataGridWrapper from '@/components/shared/mui-helpers/DataGridWrapper';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { getBrand } from '@/configs/brandConfig';
import { eCommerceRoute } from '@/routing';
import { useOrderQueries } from '@/services/orderService';
import { OrderResult } from '@/services/orderService.interface';
import { useECommerceState } from '@/state/eCommerceState';
import { formatDate } from '@/utils/date-utils/formatDate';
import { currencyFormatter } from '@/utils/priceFormatter';

const OrdersView: FC = () => {
  const [searchParams] = useSearchParams();

  const eCommerceState = useECommerceState();

  useEffect(() => {
    if (searchParams.get('email')) {
      eCommerceState.search.query.value = searchParams.get('email')!;
      eCommerceState.search.submit();
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const ordersQueries = useOrderQueries();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'brand',
        headerName: 'Brand',
        filterable: false,
        renderCell: (params) => getBrand(params.value)?.label,
      },
      {
        field: 'orderId',
        headerName: t('orders.table.orderId'),
        flex: 1,
        renderCell: (params) => (
          <Button
            component={RouterLink}
            variant="text"
            to={`${eCommerceRoute}/${params.value}?email=${eCommerceState.context.query.value}`}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: 'orderStatus',
        headerName: 'Status',
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          let color: 'warning' | 'success' | undefined = undefined;
          if (params.value === 'CANCELLED') {
            color = 'warning';
          } else if (params.value === 'COMPLETED') {
            color = 'success';
          }

          return (
            <Chip
              color={color}
              label={t(
                `orderDetails.status.${params.value}.label`,
                t('orderDetails.status.unknown.label'),
              )}
            />
          );
        },
      },
      {
        field: 'price',
        headerName: t('orders.table.price'),
        flex: 1,
        sortable: true,
        valueFormatter: (params) => currencyFormatter(params, 'DKK'),
      },
      {
        field: 'orderTime',
        headerName: t('orders.table.orderTime'),
        flex: 1,
        sortable: true,
        sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        valueFormatter: (params) => formatDate(params),
      },
      {
        field: 'actions',
        type: 'actions',
        renderCell: (params) => (
          <IconButton
            component={RouterLink}
            to={`${eCommerceRoute}/${params.row.orderId}?email=${eCommerceState.context.query.value}`}
          >
            <Launch />
          </IconButton>
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const rows = useMemo(
    () =>
      ordersQueries.data?.map((order: OrderResult) => ({
        ...order,
        id: order.orderId,
      })) || [],
    [eCommerceState.context.query.value, ordersQueries.data], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <OrderFilter
        totalOrders={ordersQueries.isSuccess ? rows.length : undefined}
      />
      <QuerySuspense query={ordersQueries}>
        {!ordersQueries.data || ordersQueries.data?.length === 0 ? (
          <NoContentMessage height="50vh" />
        ) : (
          <DataGridWrapper
            rows={rows}
            columns={columns}
            initialSortField="orderTime"
            initialSortOrder="desc"
            pageSize={10}
            pageSizeOptions={[10, 20, 30]}
          />
        )}
      </QuerySuspense>
    </>
  );
};

export default OrdersView;

enum Role {
  GDPR = 'CS.GDPR',
  CUSTOMER_COMMENT_WRITE = 'CustomerComment.Write',
  CS_ADMIN = 'CS.Admin',
  TEAM_LEAD = 'CS.TeamLead',
  TEAM_COORDINATOR = 'CS.TeamCoordinator',
  CS_AGENT = 'CS.Agent',
  LOYALTY_SUPPORT = 'Support.Loyalty',
  PAYMENT_READ = 'Payment.Read',
}

const RoleAccess = {
  LOYALTY: [Role.CUSTOMER_COMMENT_WRITE, Role.LOYALTY_SUPPORT],
  ECOMMERCE: [
    Role.CS_ADMIN,
    Role.CS_AGENT,
    Role.TEAM_LEAD,
    Role.TEAM_COORDINATOR,
    Role.GDPR,
  ],
};

export { Role, RoleAccess };

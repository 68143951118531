import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/da';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ProofOfDelivery from '@/components/sections/tracking/nshift/ProofOfDelivery';
import ShipmentDetails from '@/components/sections/tracking/nshift/ShipmentDetails';
import ShipmentSteps from '@/components/sections/tracking/nshift/ShipmentSteps';
import QuerySuspense from '@/components/shared/QuerySuspense';
import DateRangePicker from '@/components/shared/mui-helpers/DateRangePicker';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { useNShiftShipments } from '@/services/nShiftService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(locale);

const currentDate = dayjs();

interface NShiftProps {
  trackingId: string | null;
  invoiceDate: string | null;
}

const NShift: React.FC<NShiftProps> = ({ trackingId, invoiceDate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    invoiceDate ? dayjs(invoiceDate) : currentDate.subtract(30, 'day'),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    startDate ? startDate.add(30, 'day') : currentDate,
  );
  const [searchTerm, setSearchTerm] = useState<string>(trackingId || '');

  const nShiftQuery = useNShiftShipments({
    query: searchTerm,
    startDate: startDate?.startOf('day').toISOString() || null,
    endDate: endDate?.endOf('day').toISOString() || null,
  });

  useEffect(() => {
    if (trackingId) {
      handleSearch();
    }
  }, [trackingId, invoiceDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = (startDate: dayjs.Dayjs | null) => {
    setStartDate(startDate);

    let newEndDate = startDate?.add(30, 'day') ?? null;
    if (newEndDate && newEndDate.isAfter(currentDate)) {
      newEndDate = currentDate;
    }
    setEndDate(newEndDate);
  };

  const handleSearch = async () => {
    if (!searchTerm) {
      enqueueSnackbar(t('tracking.errors.noTrackingId'), {
        variant: 'warning',
      });
      return;
    }

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('date', startDate.toISOString());

    const url = `/tracking/${searchTerm}`;
    const queryString = queryParams.toString();
    const finalUrl = queryString ? `${url}?${queryString}` : url;

    const { data: shipments } = await nShiftQuery.refetch();

    if (shipments && shipments.length > 0) {
      navigate(finalUrl);
    } else {
      enqueueSnackbar(
        t('errors.noDataForTrackingId', { trackingId: searchTerm }),
        { variant: 'warning' },
      );
    }
  };

  const generateShipmentStepsAndDetails = () => {
    const shipments = nShiftQuery.data;
    if (shipments && shipments.length > 0) {
      return shipments.map((shipment, index) => (
        <React.Fragment key={shipment.uuid}>
          <Stack
            direction="row"
            spacing={4}
            sx={{ width: '100%', flexWrap: 'wrap-reverse', gap: 4 }}
            key={`${index + '-' + shipment.uuid}`}
          >
            <ShipmentDetails shipment={shipment} />
            <ShipmentSteps shipment={shipment} />
          </Stack>
          <ProofOfDelivery proofOfDeliveryUrls={shipment.proofOfDeliveryUrls} />
        </React.Fragment>
      ));
    } else {
      return <NoContentMessage />;
    }
  };

  return (
    <>
      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ gap: '2rem' }}
        >
          <DateRangePicker
            start={{
              date: startDate ?? dayjs().subtract(30, 'day'),
              minDate: dayjs().subtract(365, 'day'),
              maxDate: dayjs(),
            }}
            end={{
              date: endDate ?? dayjs(),
              disabled: true,
            }}
            onDateChange={handleDateChange}
          />
          <TextField
            fullWidth
            label={t('tracking.searchLabel')}
            placeholder="ES114757463001"
            value={searchTerm}
            sx={{
              margin: '0 !important',
              minWidth: '240px',
              maxWidth: '420px',
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton aria-label="Search" onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              },
            }}
          />
        </Stack>
      </Paper>
      <QuerySuspense query={nShiftQuery}>
        <Box py={4}>{generateShipmentStepsAndDetails()}</Box>
      </QuerySuspense>
    </>
  );
};

export default NShift;

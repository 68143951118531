import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import AppLayout from '@/components/layout/AppLayout';
import { useSettingsContext } from '@/components/providers/SettingsContext';
import SettingsView from '@/components/sections/settings/SettingsView';
import StockView from '@/components/sections/stock/StockView';
import TrackingView from '@/components/sections/tracking/TrackingView';
import Loader from '@/components/shared/mui-helpers/Loader';
import { AppSection } from '@/hooks/useAppSection';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import ECommerceRouter from '@/routing/ECommerceRouter';
import LoyaltyRouter from '@/routing/LoyaltyRouter';
import NotFoundView from '@/routing/NotFoundView';
import {
  eCommerceRoute,
  loyaltyRoute,
  stocksRoute,
  trackingRoute,
} from '@/routing/index';

const AppRouter = () => {
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();
  const { isEcomAuthorized, isLoyaltyAuthorized } = useAuthorizationHook();
  const { settings } = useSettingsContext();

  // If the user is authorized and loyalty is authorized, the default route is
  // whatever the initial view is set to in the settings. Otherwise, use the
  // only route the user has access to.
  const defaultRoute = (() => {
    if (isEcomAuthorized && isLoyaltyAuthorized) {
      switch (settings.initialView) {
        case AppSection.Loyalty:
          return loyaltyRoute;
        case AppSection.Ecommerce:
          return eCommerceRoute;
        case AppSection.Stock:
          return stocksRoute;
        case AppSection.Tracking:
          return trackingRoute;
      }
    } else if (isEcomAuthorized) {
      return eCommerceRoute;
    } else if (isLoyaltyAuthorized) {
      return loyaltyRoute;
    } else {
      return null;
    }
  })();

  if (
    (isEcomAuthorized === null || isLoyaltyAuthorized === null) &&
    isAuthenticated
  ) {
    return <Loader loadingMessage={t('messages.loading')} />;
  }

  if (!isAuthenticated) {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div>Redirecting to login...</div>
      </MsalAuthenticationTemplate>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout
              isAuthorizedForEcom={Boolean(isEcomAuthorized)}
              isAuthorizedForLoyalty={Boolean(isLoyaltyAuthorized)}
            />
          }
        >
          {defaultRoute && (
            <Route path="/" element={<Navigate to={defaultRoute} replace />} />
          )}

          {isLoyaltyAuthorized && (
            <Route path={`${loyaltyRoute}/*`} element={<LoyaltyRouter />} />
          )}
          {isEcomAuthorized && (
            <>
              {/* Redirect from the old path to the new /ecommerce route - delete this redirect someday, once users have adapted */}
              <Route
                path="/order-search/*"
                element={
                  <Navigate
                    to={{
                      pathname: location.pathname.replace(
                        '/order-search',
                        eCommerceRoute,
                      ),
                      search: location.search,
                    }}
                  />
                }
              />
              <Route
                path={`${eCommerceRoute}/*`}
                element={<ECommerceRouter />}
              />
              <Route path="/stocks" element={<StockView />} />
              <Route path="/tracking/:trackingId?" element={<TrackingView />} />
            </>
          )}
          {(isEcomAuthorized || isLoyaltyAuthorized) && (
            <Route path="profile" element={<SettingsView />} />
          )}

          <Route path="*" element={<NotFoundView />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;

import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTitle } from '@/components/shared/mui-helpers/customComponentStyles';
import { User } from '@/services/customerService.interface';

interface CustomerOrganizationsProps {
  customerDetails?: User;
}

const CustomerOrganizations: React.FC<CustomerOrganizationsProps> = ({
  customerDetails,
}) => {
  const { t } = useTranslation();

  if (!customerDetails || customerDetails.organizations.length === 0) {
    return null;
  }

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <StyledTitle>{t('customerDetails.organizations')}</StyledTitle>

      <List>
        {customerDetails.organizations.map((org) => (
          <ListItem key={org.bpid}>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={org.orgName}
              secondary={`${t('customerDetails.sapCustomerId')}: ${org.bpid}`}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default CustomerOrganizations;

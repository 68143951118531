import { BrandKey } from '@/configs/brandConfig.interface';

export interface GetOrderDetailsParams {
  orderId: number | string;
  brand: string;
}

export interface GetOrderListParams {
  identifier: string;
  brand: string;
  input: string | number;
  validFrom?: Date;
  validTo?: Date;
}

export interface Address {
  additionalStreetInfo: string;
  city: string;
  country: string;
  email: string;
  mobile: string;
  name: string;
  postalCode: string;
  street: string;
}

export interface Tracking {
  carrierName: string | null;
  trackingIds: string[];
  trackingUrls: string[];
}

export interface ProductHistory {
  status: string;
  invoiceDate: string | null;
  invoiceId: string;
  consignmentNumber: string;
  expectedDeliveryDate: string | null;
  quantity: number;
  totalDiscount: number;
  totalPrice: number;
  tracking: Tracking;
}

export interface Product {
  name: string;
  consignmentNumber: string;
  productId: string;
  articleId: string;
  itemRef: string;
  status: string;
  requestedQuantity: number | null;
  quantity: number;
  totalDiscount: number;
  totalPrice: number;
  unitPrice: number;
  invoiceDate: string | null;
  invoiceId: string;
  netPrice: number;
  rejectionStatus: string | null;
  itemCategory: string | null;
  tracking: Tracking;
  expectedDeliveryDate: string | null;
  history: ProductHistory[];
}

export interface Consignment {
  consignmentNumber: string;
  trackingId: string;
  status: string;
  carrierName: string;
  trackingUrl: string;
}

export interface OrderDetailsResult {
  total: number;
  totalDiscount: number;
  deliveryPrice: number;
  vat: number;
  orderDate: string;
  orderStatus: string;
  orderNumber: string;
  erpOrderNumber: string | number;
  salesOfficeId: number;
  paymentProvider?: string;
  transactionId?: string;
  paymentMethod?: string;
  paymentDescription?: string;
  salesOfficeDescription: string;
  customerRemarks: string[];
  products: Product[];
  consignments: Consignment[];
  shippingAddress: Address;
  billingAddress: Address;
  brand: BrandKey | undefined | null;
}

export interface BrandOrderResult {
  brand: BrandKey;
  orderId: number | string;
  price: number;
  email: string;
  phone: number | string;
  orderTime: Date;
}

export type OrderResult = Omit<BrandOrderResult, 'brand'>;

interface ItemCategory {
  labelKey?: string;
  bundle?: boolean;
}

const DSV_LABEL_KEY = 'orderDetails.product.itemCategory.dsv';
const CLICK_N_COLLECT_LABEL_KEY = 'orderDetails.product.itemCategory.cnc';
const itemCategories: { [key: string]: ItemCategory } = {
  // ZDSV ECOM DSV normal
  ZDSV: {
    labelKey: DSV_LABEL_KEY,
  },
  // ZDSS DSV Bundle sales set
  ZDSS: {
    labelKey: DSV_LABEL_KEY,
    bundle: true,
  },
  // ZDSC DSV Bundle component
  ZDSC: {
    labelKey: DSV_LABEL_KEY,
    bundle: true,
  },
  // ZDCS Sales set
  ZDCS: {
    bundle: true,
  },
  // ZCNC Click n Collect
  ZCNC: {
    labelKey: CLICK_N_COLLECT_LABEL_KEY,
  },
  // ZCCS CnC Sales Set
  ZCCS: {
    labelKey: CLICK_N_COLLECT_LABEL_KEY,
    bundle: true,
  },
  // ZCCC CnC Sales Set Comp
  ZCCC: {
    labelKey: CLICK_N_COLLECT_LABEL_KEY,
    bundle: true,
  },
  // ZPTS PTO Sales set
  ZPTS: {
    bundle: true,
  },
  // ZDCC Sales set item
  ZDCC: {
    bundle: true,
  },
  // ZPTC PTO Sales set item
  ZPTC: {
    bundle: true,
  },

  // ... other item categories
  // ZCCU EC Pick Store/SiT check mark button
  // ZPTO Ecom PTO
  // ZDCO EC DC normal
  // ZDHW Text not maintained
  // ZSUB Subscription Cat
  // ZFEE CnC Service Fee
  // ZGFT Giftwrap Fee
  // ZPAP Ecom Pick & Pack check mark button
};
export const getItemCategoryDetails = (itemCategory: string | null) => {
  if (itemCategory && itemCategories[itemCategory]) {
    return {
      ...itemCategories[itemCategory],
      bundle: Boolean(itemCategories[itemCategory].bundle),
    };
  }
  return { bundle: false };
};

export interface CustomerRemarkPost {
  erpOrderNumber: string | number;
  customerRemarks: string[];
}

export interface PostCustomerRemark {
  orderId: number | string;
  brand: string;
  erpOrderNumber: number | string;
  customerRemarks: string[];
}

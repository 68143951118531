import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import CarlsJrLogo from '@/assets/logos/logo_carls_jr.svg?react';
import {
  BrandLogoProps,
  DEFAULT_BRAND_LOGO_HEIGHT,
} from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#373535';

const CarlsJrLogoSalling: FC<BrandLogoProps> = ({
  themeAware = true,
  primaryColor,
  borderlessScale = false,
  height = DEFAULT_BRAND_LOGO_HEIGHT,
  ...other
}) => {
  const theme = useTheme();
  let color = primaryColor ?? DEFAULT_PRIMARY_COLOR;
  if (themeAware && !primaryColor && theme.palette.mode === 'dark') {
    color = '#fff';
  }

  return (
    <CarlsJrLogo {...other} height={height} style={{ color, ...other.style }} />
  );
};

export default CarlsJrLogoSalling;

import { Stack } from '@mui/material';
import React from 'react';

import AddressDetails from '@/components/sections/tracking/nshift/AddressDetails';
import ShipmentInfo from '@/components/sections/tracking/nshift/ShipmentInfo';
import { Shipment } from '@/services/nShiftService.interface';

interface ShipmentDetailsProps {
  shipment: Shipment;
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ shipment }) => {
  return (
    <Stack spacing={2} sx={{ flex: '1 1 auto' }}>
      <ShipmentInfo shipment={shipment} />
      <AddressDetails title="Modtager" address={shipment.receiver} />
      <AddressDetails title="Afsender" address={shipment.sender} />
    </Stack>
  );
};

export default ShipmentDetails;

import React from 'react';

import WalletDetailsHeader from '@/components/sections/loyalty/wallet/WalletDetailsHeader';
import WalletTransaction from '@/components/sections/loyalty/wallet/WalletTransactions';

const WalletTab = () => {
  return (
    <>
      <WalletDetailsHeader />
      <WalletTransaction />
    </>
  );
};

export default WalletTab;

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@mui/system/styled';
import { FC, PropsWithChildren } from 'react';

const WrappingBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  minHeight: '6rem',
});

interface Props extends PropsWithChildren {
  query: { isLoading?: boolean; isRefetching?: boolean };
}

const QuerySuspense: FC<Props> = ({ query, children }) => {
  if (query.isLoading || query.isRefetching) {
    return (
      <WrappingBox>
        <CircularProgress />
      </WrappingBox>
    );
  }

  return <>{children}</>;
};

export default QuerySuspense;

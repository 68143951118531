import Typography from '@mui/material/Typography';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

const isDarkMode = (theme: Theme) => theme.palette.mode === 'dark';

export const StyledBalanceDebit = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme) ? red['A200'] : red[500],
}));

export const StyledBalanceCredit = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme) ? green['A700'] : green[500],
}));

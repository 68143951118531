import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';

import AppErrorBoundaryProvider from '@/components/providers/AppErrorBoundaryProvider';
import ReactQueryProvider from '@/components/providers/ReactQueryProvider';
import ReactThemeProvider from '@/components/providers/ReactThemeProvider';
import { SettingsProvider } from '@/components/providers/SettingsProvider';
import SnackbarCloseButton from '@/components/shared/SnackbarCloseButton';
import '@/configs/i18nConfig';
import AppRouter from '@/routing/AppRouter';
import { msalInstance } from '@/utils/request-helpers/authentication';

const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult = event.payload as AuthenticationResult;
    const account = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.enableAccountStorageEvents();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AppErrorBoundaryProvider>
    <MsalProvider instance={msalInstance}>
      <SettingsProvider>
        <ReactThemeProvider>
          <CssBaseline enableColorScheme />
          <AppErrorBoundaryProvider>
            <ReactQueryProvider>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={6000}
                variant="error"
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                action={(snackbarKey) => (
                  <SnackbarCloseButton snackbarKey={snackbarKey} />
                )}
              >
                <AppRouter />
              </SnackbarProvider>
            </ReactQueryProvider>
          </AppErrorBoundaryProvider>
        </ReactThemeProvider>
      </SettingsProvider>
    </MsalProvider>
  </AppErrorBoundaryProvider>,
);

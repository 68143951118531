import BrandIconBR from '@/components/shared/logos/BrandIconBR';
import BrandIconBilka from '@/components/shared/logos/BrandIconBilka';
import BrandIconCarlsJr from '@/components/shared/logos/BrandIconCarlsJr';
import BrandIconFoetex from '@/components/shared/logos/BrandIconFoetex';
import BrandIconNetto from '@/components/shared/logos/BrandIconNetto';
import BrandIconSalling from '@/components/shared/logos/BrandIconSalling';
import BrandLogoBR from '@/components/shared/logos/BrandLogoBR';
import BrandLogoBilka from '@/components/shared/logos/BrandLogoBilka';
import BrandLogoCarlsJr from '@/components/shared/logos/BrandLogoCarlsJr';
import BrandLogoFoetex from '@/components/shared/logos/BrandLogoFoetex';
import BrandLogoNetto from '@/components/shared/logos/BrandLogoNetto';
import BrandLogoSalling from '@/components/shared/logos/BrandLogoSalling';
import { algoliaConfig } from '@/configs/algoliaConfig';
import {
  Brand,
  BrandKey,
  BrandProperties,
} from '@/configs/brandConfig.interface';
import { brandSiteConfig } from '@/configs/brandSiteConfig';

const brandProperties: readonly BrandProperties[] = [
  {
    key: 'bilka',
    label: 'Bilka',
    logo: BrandLogoBilka,
    icon: BrandIconBilka,
    algoliaIndex: algoliaConfig.bilkaIndex,
    brandSite: brandSiteConfig.bilkaUrl,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpblkMembershipNr',
        signupDateKey: 'cpblkSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: true,
  },

  {
    key: 'carlsjr',
    label: "Carl's Jr.",
    logo: BrandLogoCarlsJr,
    icon: BrandIconCarlsJr,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpcjrMembershipNr',
        signupDateKey: 'cpcjrSignupDate',
      },
      areMemberOffersBurnable: true,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'foetex',
    label: 'Føtex',
    logo: BrandLogoFoetex,
    icon: BrandIconFoetex,
    algoliaIndex: algoliaConfig.foetexIndex,
    brandSite: brandSiteConfig.foetexUrl,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpfotMembershipNr',
        signupDateKey: 'cpfotSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: true,
  },

  {
    key: 'nettode',
    label: 'Netto DE',
    logo: BrandLogoNetto,
    icon: BrandIconNetto,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpndeMembershipNr',
        signupDateKey: 'cpndeSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'nettodk',
    label: 'Netto DK',
    logo: BrandLogoNetto,
    icon: BrandIconNetto,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpndkMembershipNr',
        signupDateKey: 'cpndkSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: true,
      isProductDataAvailable: true,
    },

    orders: false,
  },

  {
    key: 'nettopl',
    label: 'Netto PL',
    logo: BrandLogoNetto,
    icon: BrandIconNetto,

    loyalty: false,
    orders: false,
  },

  {
    key: 'salling',
    label: 'Salling',
    logo: BrandLogoSalling,
    icon: BrandIconSalling,

    loyalty: {
      customerProgram: {
        membershipNrKey: 'cpsalMembershipNr',
        signupDateKey: 'cpsalSignupDate',
      },
      areMemberOffersBurnable: false,
      isWalletAvailable: false,
      isProductDataAvailable: false,
    },

    orders: false,
  },

  {
    key: 'br',
    label: 'BR',
    logo: BrandLogoBR,
    icon: BrandIconBR,
    algoliaIndex: algoliaConfig.brIndex,
    brandSite: brandSiteConfig.brUrl,

    loyalty: false,
    orders: true,
  },
] as const;

export const brands = brandProperties.map(
  (brand: BrandProperties) => new Brand(brand),
);

export const getBrand = (brandKey: BrandKey): Brand => {
  const brand = brands.find((brand) => brand.key === brandKey);
  if (!brand) {
    throw new Error(`Brand with key '${brandKey}' not found`);
  }
  return brand;
};

export const getSupportedBrandsForLoyalty = (): Brand[] => {
  return brands.filter((brand) => brand.isSupportedForLoyalty);
};

export const getSupportedBrandsForOrders = (): Brand[] => {
  return brands.filter((brand) => brand.isSupportedForOrders);
};

export const DEFAULT_BRAND = brands[0];

const brandConfig = {
  brands,
  DEFAULT_BRAND,
  getBrand,
  getSupportedBrandsForLoyalty,
  getSupportedBrandsForOrders,
};

export const getAlgoliaIndex = (brandKey: BrandKey): string | undefined => {
  const brand = brands.find((brand) => brand.key === brandKey);
  return brand?.algoliaIndex;
};

export const getBrandSiteUrl = (
  brandKey: BrandKey,
  productId?: string,
): string | undefined => {
  const brand = brands.find((brand) => brand.key === brandKey);
  return productId
    ? `${brand?.brandSite}produkter/${productId}`
    : brand?.brandSite;
};

export default brandConfig;

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { FC } from 'react';

import { BrandIconProps } from '@/components/shared/logos/props';

interface SettingSelectProps {
  labelId: string;
  label: string;
  name: string;
  value: string | number;
  onChange: any;
  options: {
    value: string | number;
    label: string;
    icon?: string | React.FC;
  }[];
  helperText: string;
  disabled?: boolean;
}

const getOptionIcon = (option: SettingSelectProps['options'][any]) => {
  if (typeof option.icon === 'string') {
    return (
      <img
        src={option.icon}
        alt={option.label}
        style={{
          height: '1em',
          marginLeft: '0.4rem',
          verticalAlign: 'middle',
        }}
      />
    );
  } else if (option.icon) {
    const Icon: FC<BrandIconProps> = option.icon;
    return (
      <Icon
        titleAccess={option.label}
        sx={{
          height: '1em',
          width: 'auto',
          marginLeft: '0.4rem',
          verticalAlign: 'text-top',
        }}
      />
    );
  } else {
    return null;
  }
};

const SettingsSelect: React.FC<SettingSelectProps> = ({
  labelId,
  label,
  name,
  value,
  onChange,
  options,
  helperText,
  disabled = false,
}) => {
  const validValues = options.map((option) => option.value);
  const isValidValue = validValues.includes(value);

  return (
    <FormControl variant="outlined" disabled={disabled}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        name={name}
        value={isValidValue ? value : validValues[0]}
        onChange={onChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {option.label}
              {getOptionIcon(option)}
            </Box>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SettingsSelect;

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandKey } from '@/configs/brandConfig.interface';
import useBrandValidation from '@/hooks/useBrandValidation';

interface BrandSelectProps {
  selectedBrand: BrandKey;
  setSelectedBrand: (brand: BrandKey) => void;
}

const brandSelectStyles = () => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '&.Mui-focused .MuiOutlinedInput-input': {
    borderColor: 'white',
  },
  '& .MuiSvgIcon': {
    borderColor: 'white',
  },
  color: 'white',
  '& .MuiSelect-icon': {
    color: 'white',
  },
  width: '15ch',
});

export const BrandSelect: FC<BrandSelectProps> = ({
  selectedBrand,
  setSelectedBrand,
}) => {
  const { t } = useTranslation();

  const { supportedBrands } = useBrandValidation(
    selectedBrand,
    setSelectedBrand,
  );

  return (
    <Select
      size="small"
      sx={brandSelectStyles}
      value={selectedBrand}
      onChange={(e: SelectChangeEvent<BrandKey>) =>
        setSelectedBrand(e.target.value as BrandKey)
      }
      variant="outlined"
      inputProps={{ 'aria-label': 'Select Brand' }}
    >
      <MenuItem value="" disabled>
        {t('messages.selectBrand')}
      </MenuItem>
      {supportedBrands.map((brand) => {
        const BrandIcon = brand.icon;
        return (
          <MenuItem key={brand.key} value={brand.key}>
            <Box display="flex" justifyContent="space-between" width="100%">
              {brand.label}
              <BrandIcon
                titleAccess={`Logo of ${brand.label}`}
                sx={{
                  height: '1em',
                  width: 'auto',
                  marginLeft: '0.4rem',
                  verticalAlign: 'text-bottom',
                }}
              />
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};

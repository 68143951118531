import axios, { AxiosRequestConfig, Method } from 'axios';

import { getCommonHeaders } from '@/utils/request-helpers/loyalty/getLoyaltyCommonHeaders';

/**
 * Performs an API request including common headers. Used for requests relating to loyalty.
 *
 * @template T - The expected response type.
 * @template D - The type of the request data.
 *
 * @param {Method} method - The HTTP method to use for the request.
 * @param {string} url - The URL to send the request to.
 * @param {D} [data] - The data to include in the request body (optional).
 * @param {number} [timeout=5000] - Request timeout.
 * @returns {Promise<T>} - A promise that resolves to the response data of type T.
 */
export const performADRequest = async <T, D>(
  method: Method,
  url: string,
  data?: D,
  timeout: number = 5000,
): Promise<T> => {
  const headers = await getCommonHeaders();
  const requestOptions: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: headers,
    data: data,
    timeout: timeout,
  };

  const response = await axios(requestOptions);
  return response.data;
};

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Timestamp,
  deleteField,
  doc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSettingsContext } from '@/components/providers/SettingsContext';
import { TicketNotificationBehavior } from '@/components/providers/SettingsProvider.interface';
import { BrandKey } from '@/configs/brandConfig.interface';
import { db } from '@/configs/firebaseConfig';
import useAppSection, { AppSection } from '@/hooks/useAppSection';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import { eCommerceRoute, loyaltyRoute } from '@/routing';

export interface ZendeskTicket {
  ticketId: string;
  brandName: BrandKey;
  requesterEmail: string;
  requesterPhoneNumber: string;
  orderNumber?: string;
  timestamp: Timestamp;
}

interface Redirect {
  loyalty?: string;
  eCommerce?: string;
}

const TicketProvider = () => {
  const { t } = useTranslation();
  const { isAppSection } = useAppSection();
  const navigate = useNavigate();

  const { accountId, isEcomAuthorized, isLoyaltyAuthorized } =
    useAuthorizationHook();
  const { settings, setSettings } = useSettingsContext();

  const [rememberChoice, setRememberChoice] = useState(false);
  const [ticket, setTicket] = useState<ZendeskTicket | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const ticketDocRef = useMemo(() => {
    if (
      settings.ticketNotificationBehavior ===
        TicketNotificationBehavior.IGNORE ||
      !accountId ||
      (!isEcomAuthorized && !isLoyaltyAuthorized)
    ) {
      return undefined;
    }

    return doc(db, 'users', accountId);
  }, [accountId, isEcomAuthorized, isLoyaltyAuthorized, settings]);

  useEffect(() => {
    if (ticketDocRef) {
      const unsubscribe = onSnapshot(
        ticketDocRef,
        (snapshot) => {
          const ticket = snapshot.get('ticket') as ZendeskTicket | undefined;

          if (snapshot.metadata.hasPendingWrites || !ticket) {
            return;
          }

          const timestamp = ticket.timestamp.toDate();
          const oldest = new Date();

          oldest.setMinutes(
            oldest.getMinutes() -
              settings.ticketNotificationExpiryIntervalInMinutes,
          );

          const hasInput =
            ticket.orderNumber ||
            ticket.requesterEmail ||
            ticket.requesterPhoneNumber;

          if (
            (settings.ticketNotificationExpiryIntervalInMinutes &&
              timestamp < oldest) ||
            !hasInput
          ) {
            // noinspection JSIgnoredPromiseFromCall
            clearCurrentTicket();
          } else {
            setTicket(ticket);
          }
        },
        (error) => {
          console.error('Error getting document From firestore: ', error);
        },
      );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [ticketDocRef, settings]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect: Redirect | undefined = useMemo(() => {
    let redirect: Redirect | undefined = undefined;
    if ((isEcomAuthorized || isLoyaltyAuthorized) && ticket) {
      const params = new URLSearchParams();
      if (ticket?.requesterEmail) {
        params.set('email', ticket.requesterEmail);
      }
      if (ticket?.brandName) {
        params.set('brand', ticket.brandName);
      }

      if (isEcomAuthorized && ticket?.orderNumber) {
        // redirect to eCommerce if order number is specified in the ticket
        redirect = {
          eCommerce: `${eCommerceRoute}/${ticket.orderNumber}?${params}`,
        };
      } else if (ticket.requesterEmail && !ticket.orderNumber) {
        if (
          isLoyaltyAuthorized &&
          ticket?.brandName &&
          (isAppSection(AppSection.Loyalty) || !isEcomAuthorized)
        ) {
          // redirect to loyalty if user is in Loyalty section or not authorized for eCommerce
          redirect = {
            loyalty: `${loyaltyRoute}/customer?${params}`,
          };
        } else if (
          isEcomAuthorized &&
          (isAppSection(AppSection.Ecommerce) || !isLoyaltyAuthorized)
        ) {
          // redirect to eCommerce if user is in eCommerce section or not authorized for Loyalty
          redirect = {
            eCommerce: `${eCommerceRoute}?${params}`,
          };
        } else if (
          ticket?.brandName &&
          isEcomAuthorized &&
          isLoyaltyAuthorized
        ) {
          // set both redirects if user is authorized for both sections
          redirect = {
            loyalty: `${loyaltyRoute}/customer?${params}`,
            eCommerce: `${eCommerceRoute}?${params}`,
          };
        }
      }
    }

    return redirect;
  }, [ticket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (redirect) {
      if (Object.keys(redirect).length > 1) {
        setOpen(true);
      } else {
        switch (settings.ticketNotificationBehavior) {
          case TicketNotificationBehavior.PROMPT:
            setOpen(true);
            break;
          case TicketNotificationBehavior.SHOW:
            // noinspection JSIgnoredPromiseFromCall
            handleRedirectToTicket(Object.values(redirect)[0]);
            enqueueSnackbar(
              t('ticketDialog.redirect', { id: ticket?.ticketId }),
              {
                variant: 'info',
              },
            );
            break;
        }
      }
    } else {
      setOpen(false);
    }
  }, [redirect]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearCurrentTicket = async () => {
    if (ticketDocRef) {
      try {
        await updateDoc(ticketDocRef, 'ticket', deleteField());
      } catch (error) {
        console.error('Error clearing ticket from Firestore', error);
      }
    }
    setTicket(undefined);
  };

  const handleRedirectToTicket = async (url: string) => {
    setOpen(false);
    navigate(url);

    await clearCurrentTicket();

    if (redirect && Object.keys(redirect).length === 1 && rememberChoice) {
      setSettings({
        ...settings,
        ticketNotificationBehavior: TicketNotificationBehavior.SHOW,
      });
    }
  };

  const handleIgnoreTicket = async () => {
    setOpen(false);
    await clearCurrentTicket();

    if (redirect && Object.keys(redirect).length === 1 && rememberChoice) {
      setSettings({
        ...settings,
        ticketNotificationBehavior: TicketNotificationBehavior.IGNORE,
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t('ticketDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('ticketDialog.newTicketFound', {
            brand: ticket?.brandName,
            id: ticket?.ticketId,
          })}
        </DialogContentText>

        {redirect && Object.keys(redirect).length === 1 && (
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setRememberChoice(e.target.checked)} />
            }
            label={t('ticketDialog.rememberMyChoice')}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleIgnoreTicket}>{t('ticketDialog.cancel')}</Button>

        {redirect && Object.keys(redirect).length === 1 && (
          <Button
            onClick={() => handleRedirectToTicket(Object.values(redirect)[0])}
          >
            {t('ticketDialog.go')}
          </Button>
        )}

        {redirect &&
          Object.keys(redirect).length > 1 &&
          Boolean(redirect.loyalty) && (
            <Button onClick={() => handleRedirectToTicket(redirect.loyalty!)}>
              {t('ticketDialog.goToLoyalty')}
            </Button>
          )}

        {redirect &&
          Object.keys(redirect).length > 1 &&
          Boolean(redirect.eCommerce) && (
            <Button onClick={() => handleRedirectToTicket(redirect.eCommerce!)}>
              {t('ticketDialog.goToECom')}
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default TicketProvider;

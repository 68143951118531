import LayersOutlined from '@mui/icons-material/LayersOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '@/services/orderService.interface';

interface Props {
  product: Product;
  itemCategory: { labelKey?: string; bundle: boolean };
}

export const ProductDetailsPreview: FC<Props> = ({ product, itemCategory }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        display="flex"
        sx={{
          alignItems: 'center',
          flexShrink: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          display="inline-flex"
          sx={{ gap: 1, flexWrap: 'nowrap', overflow: 'hidden' }}
        >
          <Typography>{product.quantity}x </Typography>
          <Typography
            color="primary"
            sx={{
              display: 'inline',
              verticalAlign: 'middle',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {product.name}
          </Typography>
        </Box>
        {itemCategory.bundle && (
          <Tooltip title={t('orderDetails.product.itemCategory.bundle')}>
            <LayersOutlined
              sx={{
                fontSize: '1rem',
                verticalAlign: 'middle',
                marginLeft: '.25rem',
              }}
            />
          </Tooltip>
        )}
      </Box>

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            gap: 0.75,
          }}
        >
          <Typography variant="overline" color="gray">
            {t('orderDetails.product.articleId')}
          </Typography>
          <Typography variant="overline">{product.articleId}</Typography>
        </Box>
        {itemCategory.labelKey && (
          <Chip
            label={t(itemCategory.labelKey)}
            variant="filled"
            size="small"
          />
        )}
      </Box>
    </>
  );
};

export default ProductDetailsPreview;

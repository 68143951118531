import { Delete, Feedback, MoreVert } from '@mui/icons-material';
import { Alert, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from '@/components/shared/mui-helpers/ConfirmationDialog';
import { Role } from '@/configs/userRolesConfig';
import { useAuthorizationHook } from '@/hooks/useAuthorizationHook';
import {
  deleteLoyaltyCustomer,
  useAddCustomerCommentMutation,
} from '@/services/customerService';
import appState from '@/state/appState';

export const LoyaltyCustomerExtendedActions = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuthorizationHook();

  const isCustomerCommentAllowed = hasRole(Role.CUSTOMER_COMMENT_WRITE);
  const isDeletionAllowed = hasRole(Role.GDPR);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [extendedActionsAnchorEl, setExtendedActionsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const extendedActionsOpen = Boolean(extendedActionsAnchorEl);

  const addCustomerCommentMutation = useAddCustomerCommentMutation({
    onSuccess: () => {
      handleCloseCommentDialog();
      enqueueSnackbar(t('profile.comments.dialog.success'), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(t('profile.comments.dialog.error'), { variant: 'error' });
    },
  });

  const brand = appState.customer.brand.value;
  const customerDetails = appState.customer.details.value;

  const handleClickExtendedActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setExtendedActionsAnchorEl(event.currentTarget);
  };

  const handleCloseExtendedActions = () => {
    setExtendedActionsAnchorEl(null);
  };

  const [commentCode, setCommentCode] = useState<string>('');
  const handleOpenCommentDialog = () => setCommentDialogOpen(true);
  const handleCloseCommentDialog = () => setCommentDialogOpen(false);
  const handleCommentCodeChange = (event: SelectChangeEvent) => {
    setCommentCode(event.target.value as string);
  };

  const handleAddComment = () => {
    addCustomerCommentMutation.mutate({
      customerUid: customerDetails!.uid,
      code: commentCode,
    });
  };

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

  const handleDeleteCustomer = async () => {
    try {
      if (brand && customerDetails?.uid) {
        await deleteLoyaltyCustomer(brand, customerDetails?.uid);
        handleCloseDeleteDialog();
      }
    } catch (error) {
      enqueueSnackbar(t('errors.failedToDeleteCustomer'));
    }
  };

  if (!isCustomerCommentAllowed && !isDeletionAllowed) {
    return null;
  }

  return (
    <>
      <IconButton
        id="extended-customer-actions-button"
        aria-controls={
          extendedActionsOpen ? 'extended-customer-actions' : undefined
        }
        aria-haspopup="true"
        aria-expanded={extendedActionsOpen ?? 'true'}
        onClick={handleClickExtendedActions}
      >
        <MoreVert />
      </IconButton>

      <Menu
        id="extended-customer-actions"
        anchorEl={extendedActionsAnchorEl}
        open={extendedActionsOpen}
        onClick={handleCloseExtendedActions}
        MenuListProps={{
          'aria-labelledby': 'extended-customer-actions-button',
        }}
      >
        {isCustomerCommentAllowed && (
          <MenuItem onClick={handleOpenCommentDialog}>
            <ListItemIcon>
              <Feedback />
            </ListItemIcon>
            <ListItemText>{t('profile.comments.openDialog')}</ListItemText>
          </MenuItem>
        )}

        {isDeletionAllowed && (
          <MenuItem onClick={handleOpenDeleteDialog}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>{t('button.deleteCustomer')}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={commentDialogOpen} closeAfterTransition={false}>
        <DialogTitle>{t('profile.comments.dialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 2 }}>
            <Alert severity="warning">
              {t('profile.comments.dialog.description')}
            </Alert>
          </DialogContentText>

          <FormControl fullWidth>
            <InputLabel id="comment-code-select-label">
              {t('profile.comments.dialog.commentCode.label')}
            </InputLabel>
            <Select
              aria-labelledby="comment-code-select-label"
              labelId="comment-code-select-label"
              id="comment-code-select"
              label={t('profile.comments.dialog.commentCode.label')}
              value={commentCode}
              onChange={handleCommentCodeChange}
              slotProps={{
                input: { id: 'comment-code-select-input' },
              }}
            >
              <MenuItem value="FRAUD_SUSPICION_SCAN_AND_GO">
                {t(
                  'profile.comments.dialog.commentCode.FRAUD_SUSPICION_SCAN_AND_GO',
                )}
              </MenuItem>
              <MenuItem value="FRAUD_SUSPICION_REFUND">
                {t(
                  'profile.comments.dialog.commentCode.FRAUD_SUSPICION_REFUND',
                )}
              </MenuItem>
              <MenuItem value="FRAUD_SUSPICION_IN_STORE">
                {t(
                  'profile.comments.dialog.commentCode.FRAUD_SUSPICION_IN_STORE',
                )}
              </MenuItem>
              <MenuItem value="SUSPICION_SCAN_AND_GO_CONTROLS">
                {t(
                  'profile.comments.dialog.commentCode.SUSPICION_SCAN_AND_GO_CONTROLS',
                )}
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommentDialog}>
            {t('profile.comments.dialog.cancel')}
          </Button>
          <Button
            disabled={!commentCode}
            loading={addCustomerCommentMutation.isPending}
            onClick={handleAddComment}
          >
            {t('profile.comments.dialog.submit')}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        isOpen={deleteDialogOpen}
        title={t('confirmationDialog.title')}
        description={t('confirmationDialog.description', {
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerEmail: customerDetails?.email,
          customerBrandName: brand,
        })}
        onConfirm={handleDeleteCustomer}
        onCancel={handleCloseDeleteDialog}
      />
    </>
  );
};

export default LoyaltyCustomerExtendedActions;

import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandKey } from '@/configs/brandConfig.interface';

interface CustomTab {
  id: string;
  name: React.ReactNode;
  content: React.ReactNode;
  noDataTooltip?: string;
  disabled?: boolean;
  failedRequests: boolean;
}

interface CustomTabsProps {
  tabs: CustomTab[];
  activeBrand?: BrandKey;
}

const CustomTabPanel: React.FC<CustomTabsProps> = ({ tabs, activeBrand }) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].id);

  const customerBrand = activeBrand;
  const { t } = useTranslation();

  const handleChange = (_event: React.SyntheticEvent, newTabIndex: string) => {
    setSelectedTab(newTabIndex);
  };

  const getTooltipMessage = (
    isDisabled: boolean,
    retryButton: React.ReactNode,
    noDataTooltip?: string,
  ) => {
    if (!customerBrand) {
      return t('customTabPanel.searchCustomerFirst');
    } else if (isDisabled) {
      return t(noDataTooltip ?? 'customTabPanel.missingProps');
    } else {
      return '';
    }
  };

  return (
    <Box>
      <Tabs variant="scrollable" value={selectedTab} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            style={{ pointerEvents: 'auto' }}
            disabled={tab.disabled ?? false}
            icon={
              tab.failedRequests ? <WarningAmber fontSize="small" /> : undefined
            }
            iconPosition="end"
            label={
              <Tooltip
                title={getTooltipMessage(
                  tab.disabled ?? false,
                  tab.noDataTooltip,
                )}
              >
                <span>{tab.name}</span>
              </Tooltip>
            }
            value={tab.id}
          />
        ))}
      </Tabs>
      <Box>
        {tabs.map((tab) => (
          <div key={tab.id} role="tabpanel" hidden={selectedTab !== tab.id}>
            {selectedTab === tab.id && <Box>{tab.content}</Box>}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default CustomTabPanel;

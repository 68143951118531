import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';

import StoreAutocomplete from '@/components/sections/stock/search/StoreAutocomplete';
import { stockColumns } from '@/components/sections/stock/search/stockColumns';
import DataGridWrapper from '@/components/shared/mui-helpers/DataGridWrapper';
import { StyledHeader } from '@/components/shared/mui-helpers/customComponentStyles';
import useFetchStores, { useStocks } from '@/services/stockService';
import { StockLevels, Store } from '@/services/stockService.interface';
import { requestValidation } from '@/utils/requestValidation';

const StockView = () => {
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const [stocks, setStocks] = useState<StockLevels[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);

  const stores = useFetchStores();

  useEffect(() => {
    const ids = selectedStores.map((store) => store.sapSiteId);
    setSiteIds(ids);
  }, [selectedStores]);

  const {
    data: stocksData,
    isLoading,
    refetch,
  } = useStocks({
    articleId: searchTerm,
    siteId: siteIds,
  });

  useEffect(() => {
    if (stocksData) {
      setStocks(
        stocksData.stockLevels.filter(
          (stockLevel) => stockLevel.currentStock !== 0,
        ),
      );
    }
  }, [stocksData]);

  const onSearch = async (value: string) => {
    try {
      requestValidation(value, siteIds);
      await refetch();
    } catch (error: any) {
      enqueueSnackbar(error?.message || 'Aktier ikke fundet', {
        variant: 'warning',
        autoHideDuration: 4000,
      });
      setStocks([]);
    }
  };

  const rows = useMemo(
    () =>
      stocks?.map((transaction, index) => ({
        ...transaction,
        id: index,
      })) || [],
    [stocks],
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box p={3} sx={{ width: '100%', maxWidth: '1440px' }}>
        <StyledHeader>Stock levels</StyledHeader>
        <Stack spacing={2} pb={2}>
          <StoreAutocomplete
            stores={stores}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
          />
          <TextField
            placeholder="Indsæt artikelnummer her"
            label="Artikelnr"
            variant="outlined"
            fullWidth
            slotProps={{
              input: {
                endAdornment: (
                  <Button
                    onClick={() => onSearch(searchTerm)}
                    startIcon={<SearchIcon />}
                  >
                    Søg
                  </Button>
                ),
              },
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch(searchTerm);
              }
            }}
          />
        </Stack>
        <DataGridWrapper
          rows={rows}
          columns={stockColumns}
          initialSortField="siteId"
          initialSortOrder="asc"
          pageSize={6}
          pageSizeOptions={[6, 12, 24]}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default StockView;

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

interface DetailRowProps {
  label: string;
  value: React.ReactNode;
}

const DetailRow: React.FC<DetailRowProps> = ({ label, value }) => {
  return (
    <Tooltip title={`${label}: ${value}`} arrow>
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        variant="body2"
      >
        {label}: {value}
      </Typography>
    </Tooltip>
  );
};

export default DetailRow;

export interface LinkedArticle {
  ean: string;
  name: string;
  category: string;
  quantity: number;
  articleID: string;
  isRefundable: boolean;
  netPrice: number;
  netPriceVat: number;
  totalPrice: number;
  linkedArticle?: LinkedArticle;
}

export interface Discount {
  name: string;
  quantity: number;
  totalPrice: number;
  offerId?: number;
}

export interface OrderLine {
  ean: string;
  articleID: string | null;
  isRefundable?: boolean;
  name: string;
  category: string | null;
  quantity: number;
  netPrice: number;
  netPriceVat: number;
  totalPrice: number;
  linkedArticle?: LinkedArticle | null;
  discounts: Discount[] | null;
}

export interface Payment {
  paymentType: string;
  paymentTypeLabel: string | null;
  price: number;
  currency: string;
}

interface UserId {
  type: string;
  value: string;
}

export interface Store {
  id: string;
  sapStoreId: string;
  name: string;
  addressLine: string;
  zipCode: string;
  city: string;
  country: string;
}

interface Barcode {
  value: string;
  displayValue: string;
  type: string;
}

export interface ReceiptOrderResult {
  orderDate: string;
  orderNumber: string | null;
  orderPrice: number;
  orderPriceVat: number;
  transactionNo: number;
  currency: string;
  merchantId: string | null;
  terminalId: string;
  templateId: string | null;
  cashierNumber: string | null;
  salesEmployeeId: string | null;
  orderLines: OrderLine[] | null;
  payments?: Payment[] | null;
  userId: UserId[] | null;
  store: Store;
  barcode?: Barcode | null;
}

export interface ReceiptSummaryResult {
  date: string;
  storeName: string;
  amount: number;
  memberDiscount: number;
  id: string;
  currency: string;
  type: ReceiptType;
}

export enum ReceiptType {
  LIGHT = 'LIGHT',
  MERGED = 'MERGED',
  FULL = 'FULL',
}

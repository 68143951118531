import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation } from '@/components/layout/navigation';
import TicketProvider from '@/components/providers/TicketProvider';

interface Props {
  isAuthorizedForEcom: boolean;
  isAuthorizedForLoyalty: boolean;
}

const AppLayout: FC<Props> = ({
  isAuthorizedForEcom,
  isAuthorizedForLoyalty,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box display={isMobile ? 'block' : 'flex'}>
        <Navigation
          isAuthorizedForOrders={isAuthorizedForEcom}
          isAuthorizedForLoyalty={isAuthorizedForLoyalty}
        />
        <main style={{ flex: '1 1 100%', overflowY: 'auto' }}>
          <Outlet />
        </main>
      </Box>

      <TicketProvider />
    </>
  );
};

export default AppLayout;

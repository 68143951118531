import { FirebaseOptions, initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
  apiKey:
    import.meta.env.VITE_FIREBASE_API_KEY ||
    'AIzaSyD9K-s5-N9tOqXRm4UEEwbNIYx3CR6VCDA'',
  authDomain:
    import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ||
    '',
  databaseURL:
    import.meta.env.VITE_FIREBASE_DATABASE_URL ||
    '',
  projectId:
    import.meta.env.VITE_FIREBASE_PROJECT_ID ||
    'zendesk-event-emitter-preprod',
  storageBucket:
    import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ||
    'zendesk-event-emitter-preprod.appspot.com',
  messagingSenderId:
    import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ||
    '765526267089',
  appId:
    import.meta.env.VITE_FIREBASE_APP_ID ||
    '1:765526267089:web:ef1cfdfd2308c141f321f0',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if (import.meta.env.VITE_FIREBASE_EMULATOR_HOST) {
  const [host, port] = import.meta.env.VITE_FIREBASE_EMULATOR_HOST.split(':');
  connectFirestoreEmulator(db, host, port);
}

export { app, db };

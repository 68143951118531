import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';
import { StyledTypography } from '@/components/shared/mui-helpers/customComponentStyles';
import { Discount } from '@/services/receiptService.interface';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { currencyFormatter } from '@/utils/priceFormatter';

interface DiscountsProps {
  discounts: Discount[];
  currency: string;
}

const Discounts: React.FC<DiscountsProps> = ({ discounts, currency }) => {
  const { t } = useTranslation();

  return (
    <Box style={{ width: '100%' }}>
      {discounts.map((discount, index) => (
        <HoverListItem
          key={`${index}-${discount.name}`}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
          }}
        >
          <Tooltip
            title={
              discount.offerId
                ? t('receipt.offerIdTooltip')
                : t('receipt.noOfferId')
            }
          >
            <StyledTypography color="text.secondary">
              {discount.name}
              {discount.offerId && (
                <button
                  style={{
                    cursor: 'pointer',
                    fontSize: '0.7rem',
                    marginLeft: '0.2rem',
                    display: 'inline-block',
                    alignSelf: 'center',
                    background: 'none',
                    border: 'none',
                    color: 'inherit',
                  }}
                  onClick={() => copyToClipboard(String(discount.offerId))}
                >
                  {discount.offerId}
                </button>
              )}
            </StyledTypography>
          </Tooltip>
          <Typography sx={{ textAlign: 'right', marginRight: '0' }}>
            {currencyFormatter(discount.totalPrice, currency)}
          </Typography>
        </HoverListItem>
      ))}
    </Box>
  );
};

export default Discounts;

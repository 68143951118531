import { ShipmentAddress } from '@/services/nShiftService.interface';
import { Address } from '@/services/orderService.interface';
import { Store } from '@/services/receiptService.interface';

export const addressFormatter = (address: Address | Store) => {
  if ('sapStoreId' in address) {
    // Handle IStore type for Loyalty Receipt
    return `${address.addressLine}, ${address.zipCode} ${address.city} ${address.country}`;
  } else {
    // Handle IAddress type
    return `${address.street}, ${address.additionalStreetInfo ? address.additionalStreetInfo + ', ' : ''}${address.postalCode} ${address.city}`;
  }
};

export const shipmentAddressFormatter = (address: ShipmentAddress) => {
  return `${address.street}, ${address.postCode} ${address.city}, ${address.countryCode}`;
};

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import styled from '@mui/system/styled';

import theme from '@/configs/themeConfig';

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

export const ScrollableCardContent = styled(CardContent)(() => ({
  maxHeight: '40vh',
  overflowY: 'auto',
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.4rem',
  marginBottom: '0.4rem',
  fontWeight: 'bolder',
  textTransform: 'capitalize',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  marginBottom: '0.4rem',
  fontWeight: 'bolder',
}));

export const EllipsisStyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.2rem',
  maxWidth: '100%',
}));

export const StyledTypography = styled(Typography)({
  textAlign: 'left',
  marginLeft: '0.8rem',
  display: 'inline-flex',
  alignItems: 'center',
});

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? grey[400] : grey[700],
  fontSize: '0.9rem',
}));

export const StyledBorderCard = styled(Card)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  elevation: 0,
  boxShadow: 'none',
  inset: 'none',
});

export const StyledColoredCard = styled(Card)({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
});

const commonStyles = {
  fontFamily: "'Source Code Pro', monospace",
};

export const StyledAppBar = styled(AppBar)({
  height: '4.9rem',
  marginBottom: '0.5rem',
  backgroundColor: theme.palette.primary.main,
});

export const StyledCard = styled(Card)({
  height: '12rem',
  marginRight: '0.5rem',
  backgroundColor: 'transparent',
  justifyContent: 'space-around',
  '& #ccsingle svg': {
    width: '6.25rem',
    maxHeight: '3.75rem',
  },
  '& .creditcard svg#cardfront': {
    width: '100%',
    borderRadius: '1.375rem',
  },
  '& .creditcard .lightcolor, .creditcard .darkcolor': {
    transition: 'fill .5s',
  },
  '& .creditcard .grey': {
    fill: '#bdbdbd',
  },
  '& .creditcard .greydark': {
    fill: '#616161',
  },
  '& .creditcard .green': {
    fill: '#4CAF50',
  },
  '& .creditcard .greendark': {
    fill: '#388E3C',
  },
  '& .creditcard .red': {
    fill: '#F44336',
  },
  '& .creditcard .reddark': {
    fill: '#D32F2F',
  },
  '& #svgname': {
    textTransform: 'uppercase',
  },
  '& #cardfront .st2': {
    fill: '#FFFFFF',
  },
  '& #cardfront .st3': {
    ...commonStyles,
    fontWeight: 'bold',
  },
  '& #cardfront .st4': {
    fontSize: '3.4rem',
  },
  '& #cardfront .st5': {
    ...commonStyles,
    fontWeight: '400',
  },
  '& #cardfront .st6': {
    fontSize: '2rem',
  },
  '& #cardfront .st7': {
    opacity: '0.6',
    fill: '#FFFFFF',
  },
  '& #cardfront .st8': {
    fontSize: '1.5rem',
  },
  '& #cardfront .st9': {
    fontSize: '2.3rem',
  },
  '& #cardfront .st10': {
    fontSize: '1rem',
  },
  '& #cardfront .st11': {
    fill: '#4C4C4C',
  },
  '& .creditcard': {
    width: '100%',
    maxWidth: '25rem',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.6s',
  },
  '& .creditcard .front': {
    width: '100%',
    backfaceVisibility: 'hidden',
  },
});

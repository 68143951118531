import { useQuery } from '@tanstack/react-query';

import { appConfig } from '@/configs/appConfig';
import { getPaymentGatewayBrand } from '@/configs/brandConfig.interface';
import { PaymentSet } from '@/services/paymentService.interface';
import { performADRequest } from '@/utils/request-helpers/apiHelper';

export const usePaymentSetQuery = (
  brand: string | undefined | null,
  paymentSetId: string,
  enabled: boolean = false,
) => {
  const paymentGatewayBrand = getPaymentGatewayBrand(brand);

  return useQuery({
    queryKey: ['payment'],
    enabled: enabled && Boolean(paymentGatewayBrand),
    queryFn: () => {
      return performADRequest<PaymentSet, void>(
        'GET',
        `${appConfig.sgApiBaseUrlV1}payment-gateway/${paymentGatewayBrand}/payment-set/${paymentSetId}`,
      );
    },
    staleTime: 60000,
  });
};

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/system/Box';
import { GridColDef } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TabIds } from '@/components/sections/loyalty/LoyaltyTab.interface';
import {
  StyledBalanceCredit,
  StyledBalanceDebit,
} from '@/components/sections/loyalty/wallet/StyledBalance';
import DataGridWrapper from '@/components/shared/mui-helpers/DataGridWrapper';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { loyaltyRoute } from '@/routing';
import { WalletTransaction as IWalletTransaction } from '@/services/walletService.interface';
import appState from '@/state/appState';
import { formatDate } from '@/utils/date-utils/formatDate';
import { currencyFormatter } from '@/utils/priceFormatter';

const WalletTransaction: React.FC = () => {
  const { t } = useTranslation();
  const transactions = appState.loyalty.walletTransactions.data.value?.data;

  const renderBalanceState = (
    transactionType: string,
    formattedAmount: string,
  ) => {
    if (transactionType === 'DEBIT') {
      return (
        <StyledBalanceDebit as="span">-{formattedAmount}</StyledBalanceDebit>
      );
    } else if (transactionType === 'CREDIT') {
      return (
        <StyledBalanceCredit as="span">{formattedAmount}</StyledBalanceCredit>
      );
    } else {
      return <></>;
    }
  };

  const transactionColumns: GridColDef<IWalletTransaction>[] = useMemo(
    () => [
      {
        field: 'amount',
        headerName: t('walletTransaction.amount'),
        flex: 1,
        renderCell: (params) => {
          return renderBalanceState(
            params.row.transactionType,
            currencyFormatter(params.value, params.row.currency),
          );
        },
      },
      {
        field: 'newBalance',
        headerName: t('walletTransaction.newBalance'),
        flex: 1,
        renderCell: (params) =>
          currencyFormatter(params.value, params.row.currency),
      },
      {
        field: 'createdAt',
        headerName: t('walletTransaction.createdAt'),
        flex: 1,
        renderCell: (params) => formatDate(params.value),
      },
      {
        field: 'receiptId',
        headerName: t('walletTransaction.receipt'),
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          if (!params.value) return '-';

          const currentSearchParams = new URLSearchParams(location.search);
          currentSearchParams.set('receiptId', params.value);

          return (
            <IconButton
              component={Link}
              to={{
                pathname: `${loyaltyRoute}/${TabIds.RECEIPTS}`,
                search: currentSearchParams.toString(),
              }}
              aria-label="View Receipt"
            >
              <ReceiptLongIcon />
            </IconButton>
          );
        },
      },
    ],
    [transactions, t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const rows = useMemo(
    () =>
      transactions?.map((transaction) => ({
        ...transaction,
        id: transaction.id,
      })) || [],
    [transactions],
  );

  if (!transactions || transactions.length === 0) {
    return <NoContentMessage infoMessage={t('walletTransaction.noData')} />;
  }

  return (
    <Box sx={{ px: 3 }}>
      <DataGridWrapper
        rows={rows}
        columns={transactionColumns}
        initialSortField="createdAt"
        initialSortOrder="desc"
        pageSize={10}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default WalletTransaction;

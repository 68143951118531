import CardContent from '@mui/material/CardContent';
import React from 'react';

import {
  StyledBorderCard,
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { ShipmentAddress } from '@/services/nShiftService.interface';
import { shipmentAddressFormatter } from '@/utils/addressFormatter';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface AddressDetailsProps {
  title: string;
  address: ShipmentAddress;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({ title, address }) => (
  <StyledBorderCard variant="outlined">
    <CardContent sx={{ flex: 1 }}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>
        {emptyValueReplacement(address.name)}
        <br />
        {shipmentAddressFormatter(address)}
        <br />
        Telefon: {emptyValueReplacement(address.phone)}
        <br />
        Mobil: {emptyValueReplacement(address.mobile)}
        <br />
        Email: {emptyValueReplacement(address.email)}
      </StyledDescription>
    </CardContent>
  </StyledBorderCard>
);

export default AddressDetails;

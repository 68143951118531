export enum PaymentType {
  EMPLOYEE_DISCOUNT = '0350',
  CARD_ONLINE = '0219',
  CARD_ONLINE_ALT = 'ZDNK',
  CASH_PAYMENT = '3101',
  CASH_PAYMENT_ALT = 'Z101',
  VOUCHER = '0421',
  PANT_VOUCHER = '0500',
  PANT_VOUCHER_ALT = '500',
  GIFTCARD = '0435',
  GIFTCARD_ALT = '435',
  WALLET_PAYMENT = '0440',
  WALLET_PAYMENT_ALT = '440',
}

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MemberPunchCard } from '@/services/tradeDriverService.interface';

interface PunchCardRewardChipsProps {
  reward: MemberPunchCard['reward'] | null;
}

const PunchCardRewardChips: React.FC<PunchCardRewardChipsProps> = ({
  reward,
}) => {
  const { t } = useTranslation();

  if (!reward) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Tooltip title={t('punchCard.noRewardDescription')}>
          <Chip
            icon={<ErrorOutlineIcon />}
            label={t('punchCard.noReward')}
            color="error"
            sx={{ mx: 1 }}
          />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
      <Tooltip
        title={
          reward.isActive
            ? t('punchCard.activeDescription')
            : t('punchCard.inactiveDescription')
        }
      >
        <Chip
          icon={
            reward.isActive ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />
          }
          label={
            reward.isActive ? t('punchCard.active') : t('punchCard.inactive')
          }
          color={reward.isActive ? 'success' : 'error'}
          sx={{ mx: 1 }}
        />
      </Tooltip>
      <Tooltip
        title={
          reward.isRedeemable
            ? t('punchCard.redeemableDescription')
            : t('punchCard.notRedeemableDescription')
        }
      >
        <Chip
          icon={
            reward.isRedeemable ? (
              <CheckCircleOutlineIcon />
            ) : (
              <ErrorOutlineIcon />
            )
          }
          label={
            reward.isRedeemable
              ? t('punchCard.redeemable')
              : t('punchCard.notRedeemable')
          }
          color={reward.isRedeemable ? 'success' : 'error'}
          sx={{ mx: 1 }}
        />
      </Tooltip>
    </Box>
  );
};

export default PunchCardRewardChips;

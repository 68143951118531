import Box from '@mui/material/Box';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DateRangePickerProps {
  start: {
    date: Dayjs;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    disabled?: boolean;
  };
  end: {
    date: Dayjs;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    disabled?: boolean;
  };
  onDateChange?: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  start,
  end,
  onDateChange,
}) => {
  // Since we cannot use the date range picker from MUI because of the pro license, this component ties two date pickers together.
  const { t } = useTranslation();

  const handleStartDateChange = (date: Dayjs | null) => {
    if (onDateChange) {
      onDateChange(date, end.date);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (onDateChange) {
      onDateChange(start.date, date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
      <Box display="flex" gap={1}>
        <DatePicker
          label={t('dateRangePicker.startDate')}
          minDate={start.minDate}
          maxDate={start.maxDate}
          value={start.date}
          disabled={start.disabled}
          onChange={handleStartDateChange}
          timezone="Europe/Copenhagen"
          sx={{ minWidth: '150px' }}
          slotProps={{ openPickerButton: { id: 'start-date-picker' } }}
        />
        <DatePicker
          label={t('dateRangePicker.endDate')}
          onChange={handleEndDateChange}
          value={end.date}
          minDate={end.minDate}
          maxDate={end.maxDate}
          disabled={end.disabled}
          timezone="Europe/Copenhagen"
          sx={{ minWidth: '150px' }}
          slotProps={{ openPickerButton: { id: 'end-date-picker' } }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;

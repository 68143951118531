import customerState from '@/state/customerState';
import eCommerceState from '@/state/eCommerceState';
import loyaltyState from '@/state/loyaltyState';
import { loyaltySearchState } from '@/state/searchState';

const appState = {
  loyaltySearch: loyaltySearchState,
  customer: customerState,
  loyalty: loyaltyState,
  eCommerce: eCommerceState,
};

export default appState;

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PunchCardHistory from '@/components/sections/loyalty/punch-cards/PunchCardHistory';
import PunchCardInfoList from '@/components/sections/loyalty/punch-cards/PunchCardInfoList';
import PunchCardOfferDetails from '@/components/sections/loyalty/punch-cards/PunchCardOfferDetails';
import PunchCardProductDetails from '@/components/sections/loyalty/punch-cards/PunchCardProductDetails';
import PunchCardRewardChips from '@/components/sections/loyalty/punch-cards/PunchCardRewardChips';
import PunchesRequired from '@/components/sections/loyalty/punch-cards/PunchesRequired';
import {
  StyledDescription,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { MemberPunchCard } from '@/services/tradeDriverService.interface';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';

interface PunchCardDetailsProps {
  memberPunchCard: MemberPunchCard;
}

const PunchCardDetails: React.FC<PunchCardDetailsProps> = ({
  memberPunchCard,
}) => {
  const { t } = useTranslation();

  const punchCard = memberPunchCard.punchCard;

  return (
    <Box>
      <Card sx={{ mb: '1rem', position: 'relative' }} elevation={3}>
        <CardContent>
          <Box
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              marginBottom: '1rem',
            }}
          >
            <StyledTitle>{emptyValueReplacement(punchCard?.name)}</StyledTitle>
            <StyledDescription sx={{ fontStyle: 'italic', my: 1 }}>
              {emptyValueReplacement(punchCard?.description)}
            </StyledDescription>
            <PunchCardRewardChips reward={memberPunchCard.reward} />
            {memberPunchCard.punchCard?.punchesRequired && (
              <PunchesRequired
                punchesRequired={memberPunchCard.punchCard.punchesRequired}
                punchesAcquired={memberPunchCard.punches ?? 0}
              />
            )}
          </Box>
          <List>
            <PunchCardInfoList memberPunchCard={memberPunchCard} />
          </List>
        </CardContent>
      </Card>
      <Card sx={{ mb: '1rem', position: 'relative' }} elevation={3}>
        <CardContent>
          <Box
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              marginBottom: '1rem',
            }}
          >
            <StyledTitle>{t('punchCard.offersTitle')}</StyledTitle>
          </Box>
          <PunchCardOfferDetails offer={memberPunchCard.offer} />
          <PunchCardProductDetails products={memberPunchCard.offer?.products} />
          <PunchCardHistory punchHistory={memberPunchCard.punchHistory} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PunchCardDetails;

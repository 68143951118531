import { useEffect, useRef } from 'react';

const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit,
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(callback, options);
    const { current: observer } = observerRef;
    const { current: target } = targetRef;

    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [callback, options]);

  return targetRef;
};

export default useIntersectionObserver;

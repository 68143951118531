import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import SallingIcon from '@/assets/logos/icon_salling.svg?react';
import { BrandIconProps } from '@/components/shared/logos/props';

const DEFAULT_PRIMARY_COLOR = '#231F20';

const BrandIconSalling: FC<BrandIconProps> = ({
  themeAware = true,
  primaryColor,
  ...other
}) => {
  const theme = useTheme();
  let color = primaryColor ?? DEFAULT_PRIMARY_COLOR;
  if (themeAware && !primaryColor && theme.palette.mode === 'dark') {
    color = '#fff';
  }

  return (
    <SvgIcon {...other}>
      <SallingIcon style={{ color }} />
    </SvgIcon>
  );
};

export default BrandIconSalling;

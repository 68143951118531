import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

export const copyToClipboard = async (copiedValue?: string | null) => {
  if (copiedValue) {
    await navigator.clipboard.writeText(copiedValue);
    return enqueueSnackbar(t('messages.copyToClipboard'), {
      variant: 'success',
      autoHideDuration: 2000,
    });
  }
};

import { batch, effect, signal } from '@preact/signals';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { BrandKey } from '@/configs/brandConfig.interface';
import { eCommerceRoute } from '@/routing';
import { SearchType, checkEcomSearchType } from '@/utils/checkInputType';

export enum OrderPeriodFilterOption {
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  LAST_YEAR,
  ALL_TIME,
}

export const createDefaultECommerceState = () => {
  const eCommerceState = {
    context: {
      query: signal<string>(''),
      type: signal<SearchType | undefined>(undefined),
    },

    search: {
      query: signal<string>(''),
      type: signal<SearchType | undefined>(undefined),
      error: signal<string>(''),
    },

    filter: {
      brands: signal<BrandKey[]>([]),
      period: signal<OrderPeriodFilterOption>(
        OrderPeriodFilterOption.LAST_3_MONTHS,
      ),
    },
  };

  effect(() => {
    const determinedType = checkEcomSearchType(
      eCommerceState.search.query.value,
    );

    batch(() => {
      if (!eCommerceState.search.query.value) {
        eCommerceState.search.error.value = '';
        eCommerceState.search.type.value = undefined;
        return;
      }

      if (
        eCommerceState.search.query.value &&
        eCommerceState.context.query.value ===
          eCommerceState.search.query.value &&
        determinedType === SearchType.INVALID
      ) {
        // Display error if the current search query is submitted and determined as invalid
        eCommerceState.search.error.value = t('messages.invalidEmailOrUserID');
      } else {
        eCommerceState.search.error.value = '';
      }
      eCommerceState.search.type.value = determinedType;
    });
  });

  return {
    ...eCommerceState,
    search: {
      ...eCommerceState.search,
      reset: () => {
        batch(() => {
          eCommerceState.context.query.value = '';
          eCommerceState.context.type.value = undefined;
          eCommerceState.search.query.value = '';
          eCommerceState.search.type.value = undefined;
          eCommerceState.search.error.value = '';
        });
      },
    },

    resetState: () => {
      batch(() => {
        eCommerceState.search.query.value = '';
        eCommerceState.search.type.value = undefined;
        eCommerceState.search.error.value = '';
        eCommerceState.filter.brands.value = [];
        eCommerceState.filter.period.value =
          OrderPeriodFilterOption.LAST_3_MONTHS;
      });
    },
  };
};

const eCommerceState = createDefaultECommerceState();

export const useECommerceState = () => {
  const navigate = useNavigate();

  return {
    ...eCommerceState,
    search: {
      ...eCommerceState.search,
      submit: () => {
        batch(() => {
          eCommerceState.context.query.value =
            eCommerceState.search.query.value;
          eCommerceState.context.type.value = eCommerceState.search.type.value;
        });

        if (eCommerceState.context.type.value === SearchType.ORDER_ID) {
          navigate(`${eCommerceRoute}/${eCommerceState.context.query.value}`);
        } else if (eCommerceState.context.type.value === SearchType.EMAIL) {
          navigate(
            `${eCommerceRoute}?email=${eCommerceState.context.query.value}`,
          );
        }
      },
    },
  };
};

export default eCommerceState;

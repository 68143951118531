import { Signal, batch } from '@preact/signals';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useFailedRequestSnackbar from '@/hooks/useFailedRequestSnackbar';
import {
  fetchCardDetails,
  fetchMemberOffers,
  useLoyaltyCustomerQuery,
} from '@/services/customerService';
import {
  CustomerDetailsByBrand,
  OffersData,
} from '@/services/customerService.interface';
import { fetchReceipts } from '@/services/receiptService';
import { ReceiptSummaryResult } from '@/services/receiptService.interface';
import { fetchPunchCards } from '@/services/tradeDriverService';
import { PunchCardResponse } from '@/services/tradeDriverService.interface';
import {
  fetchWalletDetails,
  fetchWalletTransactions,
} from '@/services/walletService';
import {
  WalletResult,
  WalletTransactionResult,
} from '@/services/walletService.interface';
import appState from '@/state/appState';
import { LoyaltyStateInterface } from '@/state/loyaltyState.interface';

const REQUEST_RETRIES = 1;

const useCustomerRequest = () => {
  return useLoyaltyCustomerQuery({
    retry: false,
  });
};

const useCreditCardRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  return useQuery({
    queryKey: [
      'card',
      appState.customer.brand.value,
      appState.customer.details.value?.email,
    ],
    queryFn: () => {
      return fetchCardDetails(customerRequest.data!.profile!.cdcId);
    },
    retry: REQUEST_RETRIES,
    enabled:
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile),
    staleTime: 60000,
  });
};

const useWalletRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  return useQuery({
    queryKey: [
      'wallet',
      appState.customer.brand.value,
      appState.customer.details.value?.email,
    ],
    queryFn: () => {
      return fetchWalletDetails(
        customerRequest.data!.profile!.trackingId,
        appState.customer.brand.value!,
      );
    },
    retry: REQUEST_RETRIES,
    enabled:
      appState.loyalty.useWallet.value &&
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile) &&
      customerRequest.data.profile?.hasWallet,
  });
};

const useWalletTransactionRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  return useQuery({
    queryKey: [
      'walletTransactions',
      appState.customer.brand.value,
      appState.customer.details.value?.email,
    ],
    queryFn: () => {
      return fetchWalletTransactions(
        customerRequest.data!.profile!.trackingId,
        appState.customer.brand.value!,
      );
    },
    retry: REQUEST_RETRIES,
    enabled:
      appState.loyalty.useWallet.value &&
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile) &&
      customerRequest.data.profile?.hasWallet,
  });
};

const useMemberOfferRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  return useQuery({
    queryKey: [
      'memberOffers',
      appState.customer.brand.value,
      appState.customer.details.value?.email,
    ],
    queryFn: () => {
      return fetchMemberOffers({
        trackingId: customerRequest.data!.profile!.trackingId,
        brand: appState.customer.brand.value!,
      });
    },
    retry: REQUEST_RETRIES,
    enabled:
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile),
    staleTime: 60000,
  });
};

const usePunchCardsRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  return useQuery({
    queryKey: [
      'punchCards',
      appState.customer.brand.value,
      appState.customer.details.value?.email,
    ],
    queryFn: () => {
      return fetchPunchCards(
        customerRequest.data!.profile!.trackingId,
        appState.customer.brand.value!,
        { activeAt: dayjs() },
      );
    },
    retry: REQUEST_RETRIES,
    enabled:
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile),
    staleTime: 60000,
  });
};

const useReceiptsRequest = (
  customerRequest: UseQueryResult<CustomerDetailsByBrand>,
) => {
  const email = appState.customer.details.value?.email;
  return useQuery({
    queryKey: ['receipts', appState.customer.brand.value, email],
    queryFn: () => {
      return fetchReceipts(
        customerRequest.data!.profile!.cdcId,
        appState.customer.brand.value!,
        100,
      );
    },
    retry: REQUEST_RETRIES,
    enabled:
      customerRequest.isFetched &&
      customerRequest.isSuccess &&
      Boolean(customerRequest.data.profile) &&
      !!email,
  });
};

const useUpdateStateByRequestStatus = <T>(
  query: UseQueryResult<T>,
  status: Signal<LoyaltyStateInterface>,
  value: Signal<T | undefined>,
) => {
  useEffect(
    () => {
      if (query.isFetching) {
        status.value = {
          isFetching: true,
          isFetched: false,
          isError: false,
        };
      }

      if (query.isSuccess) {
        batch(() => {
          value.value = query.data;
          status.value = {
            isFetching: false,
            isFetched: true,
            isError: false,
          };
        });
      }

      if (query.isError || query.isRefetchError) {
        status.value = {
          isFetching: false,
          isFetched: false,
          isError: true,
        };
      }
    },
    [query.isFetching, query.dataUpdatedAt, query.errorUpdatedAt], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const useUpdateLoyaltyStateInBatch = (
  memberOffersRequest: UseQueryResult<OffersData>,
  punchCardsRequest: UseQueryResult<PunchCardResponse>,
  receiptsRequest: UseQueryResult<ReceiptSummaryResult[]>,
  walletRequest: UseQueryResult<WalletResult>,
  walletTransactionsRequest: UseQueryResult<WalletTransactionResult>,
) => {
  useEffect(
    () => {
      appState.loyalty.memberOffers.request.value = {
        ...appState.loyalty.memberOffers.request.value,
        isFetching:
          memberOffersRequest.isFetching ||
          memberOffersRequest.isRefetching ||
          memberOffersRequest.isLoading,
      };
      appState.loyalty.punchCards.request.value = {
        ...appState.loyalty.punchCards.request.value,
        isFetching:
          punchCardsRequest.isFetching ||
          punchCardsRequest.isRefetching ||
          punchCardsRequest.isLoading,
      };
      appState.loyalty.receipts.request.value = {
        ...appState.loyalty.receipts.request.value,
        isFetching:
          receiptsRequest.isFetching ||
          receiptsRequest.isRefetching ||
          receiptsRequest.isLoading,
      };
      appState.loyalty.wallet.request.value = {
        ...appState.loyalty.wallet.request.value,
        isFetching:
          walletRequest.isFetching ||
          walletRequest.isRefetching ||
          walletRequest.isLoading,
      };
      appState.loyalty.walletTransactions.request.value = {
        ...appState.loyalty.walletTransactions.request.value,
        isFetching:
          walletTransactionsRequest.isFetching ||
          walletTransactionsRequest.isRefetching ||
          walletTransactionsRequest.isLoading,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      memberOffersRequest.status,
      memberOffersRequest.fetchStatus,
      punchCardsRequest.status,
      punchCardsRequest.fetchStatus,
      receiptsRequest.status,
      receiptsRequest.fetchStatus,
      walletRequest.status,
      walletRequest.fetchStatus,
      walletTransactionsRequest.status,
      walletTransactionsRequest.fetchStatus,
    ],
  );

  useEffect(() => {
    if (
      memberOffersRequest.isFetched &&
      punchCardsRequest.isFetched &&
      receiptsRequest.isFetched &&
      (!appState.loyalty.useWallet.value ||
        !appState.customer.profile.value?.hasWallet ||
        (walletRequest.isFetched && walletTransactionsRequest.isFetched))
    ) {
      batch(() => {
        if (appState.loyalty.useWallet.value) {
          appState.loyalty.wallet.request.value = {
            isFetching: false,
            isFetched: true,
            isError: walletRequest.isError || walletRequest.isRefetchError,
          };
          if (walletRequest.isSuccess) {
            appState.loyalty.wallet.data.value = walletRequest.data;
          }

          appState.loyalty.walletTransactions.request.value = {
            isFetching: false,
            isFetched: true,
            isError:
              walletTransactionsRequest.isError ||
              walletTransactionsRequest.isRefetchError,
          };
          if (walletTransactionsRequest.isSuccess) {
            appState.loyalty.walletTransactions.data.value =
              walletTransactionsRequest.data;
          }
        }

        appState.loyalty.memberOffers.request.value = {
          isFetching: false,
          isFetched: true,
          isError:
            memberOffersRequest.isError || memberOffersRequest.isRefetchError,
        };
        if (memberOffersRequest.isSuccess) {
          appState.loyalty.memberOffers.data.value = memberOffersRequest.data;
        }

        appState.loyalty.punchCards.request.value = {
          isFetching: false,
          isFetched: true,
          isError:
            punchCardsRequest.isError || punchCardsRequest.isRefetchError,
        };
        if (punchCardsRequest.isSuccess) {
          appState.loyalty.punchCards.data.value = punchCardsRequest.data;
        }

        appState.loyalty.receipts.request.value = {
          isFetching: false,
          isFetched: true,
          isError: receiptsRequest.isError || receiptsRequest.isRefetchError,
        };
        if (receiptsRequest.isSuccess) {
          appState.loyalty.receipts.data.value = receiptsRequest.data;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    walletRequest.dataUpdatedAt,
    walletRequest.errorUpdatedAt,
    walletTransactionsRequest.dataUpdatedAt,
    walletTransactionsRequest.errorUpdatedAt,
    memberOffersRequest.dataUpdatedAt,
    memberOffersRequest.errorUpdatedAt,
    punchCardsRequest.dataUpdatedAt,
    punchCardsRequest.errorUpdatedAt,
    receiptsRequest.dataUpdatedAt,
    receiptsRequest.errorUpdatedAt,
  ]);
};
export const useLoyaltyStateLoader = () => {
  const { t } = useTranslation();

  const customerRequest = useCustomerRequest();

  // update customer state data by customer response
  useEffect(() => {
    if (customerRequest.isSuccess) {
      batch(() => {
        appState.customer.details.value = customerRequest.data.user;
        appState.customer.profile.value =
          customerRequest.data.profile ?? undefined;
      });
    }
  }, [customerRequest.dataUpdatedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  const creditCardRequest = useCreditCardRequest(customerRequest);
  useUpdateStateByRequestStatus(
    creditCardRequest,
    appState.loyalty.creditCard.request,
    appState.loyalty.creditCard.data,
  );

  const walletRequest = useWalletRequest(customerRequest);
  const walletTransactionsRequest =
    useWalletTransactionRequest(customerRequest);
  const memberOffersRequest = useMemberOfferRequest(customerRequest);
  const punchCardsRequest = usePunchCardsRequest(customerRequest);
  const receiptsRequest = useReceiptsRequest(customerRequest);

  useUpdateLoyaltyStateInBatch(
    memberOffersRequest,
    punchCardsRequest,
    receiptsRequest,
    walletRequest,
    walletTransactionsRequest,
  );

  useFailedRequestSnackbar(
    t('customTabPanel.retryAvailable'),
    creditCardRequest,
    walletRequest,
    walletTransactionsRequest,
    memberOffersRequest,
    punchCardsRequest,
    receiptsRequest,
  );
};

import Stack from '@mui/material/Stack';
import React from 'react';

import CreditCard from '@/components/sections/loyalty/credit-cards/CreditCard';
import { ICreditCardDetail } from '@/components/sections/loyalty/credit-cards/ICreditCardDetail';

interface CreditCardListProps {
  creditCard?: ICreditCardDetail[] | null;
}

const CreditCardList: React.FC<CreditCardListProps> = ({ creditCard }) => {
  if (!creditCard || creditCard.length === 0) {
    return null;
  }

  return (
    <Stack p={1}>
      {creditCard?.map((card, index) => (
        <CreditCard
          key={`${card.cardNumber}-${index}`}
          cardNumber={card.cardNumber}
          addedDate={card.created}
          expiryMonth={card.expiryMonth}
          expiryYear={card.expiryYear}
          blackListed={card.blacklisted}
        />
      ))}
    </Stack>
  );
};

export default CreditCardList;

import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

interface PunchesRequiredProps {
  punchesRequired: number;
  punchesAcquired: number;
}

const PunchesRequired: React.FC<PunchesRequiredProps> = ({
  punchesRequired,
  punchesAcquired,
}) => {
  const filledStamps = Math.min(punchesAcquired, punchesRequired);

  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        marginBottom={2}
      >
        {[...Array(punchesRequired)].map((punch, index) => (
          <Box key={`${index}-${punch}`}>
            {index < filledStamps ? (
              <VerifiedIcon color="primary" fontSize="small" />
            ) : (
              <NewReleasesIcon color="disabled" fontSize="small" />
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default PunchesRequired;

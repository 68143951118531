import common from '@mui/material/colors/common';
import { createTheme } from '@mui/material/styles';

export const light = createTheme({
  palette: {
    primary: {
      main: '#024a62',
    },
    secondary: {
      main: '#98cbcc',
    },
    error: {
      main: '#d46360',
    },
    warning: {
      main: '#bb8a44',
    },
    info: {
      main: '#7db6d1',
    },
    success: {
      main: '#133f4f',
    },
  },
  colorSchemes: 'light',
  typography: {
    fontFamily: '"League Spartan","Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export const dark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#29b1e3',
      contrastText: common.white,
    },
    secondary: {
      main: '#98cbcc',
    },
    error: {
      main: '#d46360',
    },
    warning: {
      main: '#bb8a44',
    },
    info: {
      main: '#7db6d1',
    },
    success: {
      main: '#29b1e3',
    },
  },
  colorSchemes: 'dark',
  typography: {
    fontFamily: '"League Spartan","Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export default light;

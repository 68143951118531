import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import NettoIcon from '@/assets/logos/icon_netto.svg?react';
import { BrandIconProps } from '@/components/shared/logos/props';

const BrandIconNetto: FC<BrandIconProps> = ({
  themeAware = true,
  primaryColor,
  ...other
}) => {
  return (
    <SvgIcon {...other}>
      <NettoIcon />
    </SvgIcon>
  );
};

export default BrandIconNetto;

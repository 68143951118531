import ReplyIcon from '@mui/icons-material/Reply';
import { Box, ListItemText, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import green from '@mui/material/colors/green';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Discounts from '@/components/sections/loyalty/receipts/Discounts';
import LinkedArticle from '@/components/sections/loyalty/receipts/LinkedArticle';
import HoverListItem from '@/components/shared/mui-helpers/HoverListItem';
import { OrderLine } from '@/services/receiptService.interface';
import appState from '@/state/appState';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { emptyValueReplacement } from '@/utils/emptyValueReplacement';
import { currencyFormatter } from '@/utils/priceFormatter';

const OrderLineItem: React.FC<{ line: OrderLine }> = ({ line }) => {
  const { t } = useTranslation();
  const currency = appState.loyalty.currency.value;

  return (
    <>
      <HoverListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: 0,
          cursor: 'default',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText primary={emptyValueReplacement(line.name)} />
          {line.articleID && (
            <Tooltip title={t('productHeaders.copyArticleId')}>
              <Typography
                variant="caption"
                sx={{
                  cursor: 'pointer',
                  display: 'block',
                  alignSelf: 'center',
                  margin: '0 0.5rem',
                  marginY: 'auto',
                }}
                onClick={() => copyToClipboard(line.articleID)}
              >
                {line.articleID}
              </Typography>
            </Tooltip>
          )}
          {line.isRefundable && (
            <Tooltip title={t('receipt.refundable')}>
              <Chip
                icon={<ReplyIcon />}
                color="primary"
                size="small"
                sx={{
                  backgroundColor: green[500],
                  color: 'white',
                  '& .MuiChip-icon': {
                    marginLeft: '0.6rem',
                  },
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Typography sx={{ textAlign: 'right' }}>
          {currencyFormatter(line.totalPrice, currency)}
        </Typography>
      </HoverListItem>
      {line.discounts && line.discounts.length > 0 && (
        <Discounts discounts={line.discounts} currency={currency} />
      )}
      {line.linkedArticle && (
        <LinkedArticle article={line.linkedArticle} currency={currency} />
      )}
    </>
  );
};

export default OrderLineItem;

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReceiptType } from '@/services/receiptService.interface';

interface ReceiptTypeChipProps {
  type: ReceiptType;
}

const ReceiptTypeChip: React.FC<ReceiptTypeChipProps> = ({ type }) => {
  const { t } = useTranslation();

  return type === ReceiptType.LIGHT ? (
    <Tooltip title={t('receipt.preliminaryTooltip')}>
      <Chip
        label={t('receipt.preliminary')}
        component="span"
        size="small"
        sx={{ marginLeft: '.25rem' }}
      />
    </Tooltip>
  ) : null;
};

export default ReceiptTypeChip;

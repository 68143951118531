/*
 " ATTENTION!
 * This file needs to be separated from the SettingsContext.tsx file, so that Vite HMR can work properly.
 * By their guideline components may only expose a single React component and no additional parts like the context:
 * https://github.com/vitejs/vite-plugin-react/tree/main/packages/plugin-react#consistent-components-exports
 */
import { createContext, useContext } from 'react';

import { Settings } from '@/components/providers/SettingsProvider.interface';

interface SettingsContextType {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}

export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined,
);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error(
      'useSettingsContext must be used within a SettingsProvider',
    );
  }
  return context;
};

import { isNumber } from '@/utils/isNumber';

export enum SearchType {
  EMAIL = 'email',
  MEMBER_ID = 'memberId',
  GIGYA_ID = 'gigyaId',
  ORDER_ID = 'orderId',
  INVALID = 'invalid type',
}

export const checkEcomSearchType = (input: string | number): SearchType => {
  if (typeof input !== 'number') {
    input = input.replace(/\s/g, '');
  }

  if (isNumber(input)) {
    return SearchType.ORDER_ID;
  }

  if (isValidEmail(String(input))) {
    return SearchType.EMAIL;
  }

  return SearchType.INVALID;
};

/**
 * Checks the type of the input within loyalty search.
 * The input can be a member ID, email or gigya ID.
 * @param {string | number} input - The input to check.
 * @returns {string} - The type of the input
 */
export const checkLoyaltyInputType = (input: string | number): SearchType => {
  const sanitizedInput = String(input).replace(/\s/g, '');

  if (isNumber(sanitizedInput)) {
    return SearchType.MEMBER_ID;
  }

  if (isValidEmail(String(input))) {
    return SearchType.EMAIL;
  }

  // Check if it's a Gigya ID (32-character alphanumeric)
  if (/^[a-fA-F0-9]{32}$/.test(sanitizedInput)) {
    return SearchType.GIGYA_ID;
  }

  // Default case (if no match)
  return SearchType.INVALID;
};

/**
 * Checks if the email is valid.
 *
 * We use the browser's built-in email validation, as it's simple and good
 * enough. Even if it allows 'a@b'…
 *
 * @param {string} email - The email to check.
 * @returns {boolean} - Whether the given string is a valid email address.
 */
const isValidEmail = (email: string): boolean => {
  const input = document.createElement('input');

  input.type = 'email';
  input.required = true;
  input.value = email;

  return input.checkValidity();
};

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import dayjs, { Dayjs } from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OfferDetails from '@/components/sections/loyalty/offers/offer/OfferDetails';
import OfferList from '@/components/sections/loyalty/offers/offer/OfferList';
import ProductList from '@/components/sections/loyalty/offers/offer/ProductList';
import QuerySuspense from '@/components/shared/QuerySuspense';
import CustomFilterOptions from '@/components/shared/mui-helpers/CustomFilterOptions';
import NoContentMessage from '@/components/shared/mui-helpers/NoContentMessage';
import { useMemberOffersRequest } from '@/services/customerService';
import { MemberOffer } from '@/services/customerService.interface';
import appState from '@/state/appState';
import { containsSearchTerm } from '@/utils/containsSearchTerm';
import { isOfferMembership } from '@/utils/isOfferMembership';

const OffersTab: React.FC = () => {
  const initialOffers = appState.loyalty.memberOffers.data.value?.memberOffers;

  const [offers, setOffers] = useState<MemberOffer[] | undefined>(
    initialOffers,
  );

  const customerBrand = appState.customer.brand.value;
  const profileDetails = appState.customer.profile.value;

  const [selectedOffer, setSelectedOffer] = useState<MemberOffer | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    validAt: dayjs() as Dayjs | null,
    membership: true,
    personal: true,
  });

  const { t } = useTranslation();

  const noFiltersApplied = useCallback(() => {
    return !filters.membership && !filters.personal;
  }, [filters]);

  const {
    data: fetchedOffers,
    isLoading,
    refetch,
  } = useMemberOffersRequest(
    profileDetails?.trackingId!,
    filters.validAt?.toDate(),
    customerBrand,
    { memberOffers: initialOffers },
  );

  useEffect(() => {
    if (initialOffers) {
      setOffers(initialOffers);
    }
  }, [initialOffers]);

  useEffect(() => {
    if (fetchedOffers) {
      setOffers(fetchedOffers.memberOffers);
    }
  }, [fetchedOffers]);

  useEffect(() => {
    if (Array.isArray(offers) && offers.length > 0) {
      setSelectedOffer(offers[0]);
    }
  }, [offers]);

  useEffect(() => {
    if (noFiltersApplied()) {
      setSearchTerm('');
    }
  }, [noFiltersApplied]);

  const handleApplyFilters = useCallback(async () => {
    if (!filters.validAt) {
      setSelectedOffer(initialOffers ? initialOffers[0] : null);
      setOffers(initialOffers);
      setFilters({
        ...filters,
        membership: true,
        personal: true,
      });
      return;
    }

    if (profileDetails?.trackingId) {
      try {
        const updatedOffers = await refetch();
        if (updatedOffers.data) {
          setOffers(updatedOffers.data.memberOffers);
          setFilters({
            ...filters,
            membership: true,
            personal: true,
          });
        } else {
          enqueueSnackbar(t('offerDetails.noData'));
        }
      } catch (err) {
        console.error('Error fetching offers based on filters:', err);
        enqueueSnackbar(t('offerDetails.noData'));
      }
    }
  }, [filters, initialOffers, refetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClearFilters = () => {
    setFilters({
      validAt: null,
      membership: false,
      personal: false,
    });
    setSelectedOffer(null);
  };

  const filterOffers = (offers: MemberOffer[]): MemberOffer[] => {
    return offers.filter((offerItem) => {
      const matchesSearchTerm = containsSearchTerm(offerItem, searchTerm);
      const isMembershipOffer = isOfferMembership(offerItem.offer);
      const matchesOfferType =
        (filters.membership && isMembershipOffer) ||
        (filters.personal && !isMembershipOffer);
      return matchesSearchTerm && matchesOfferType;
    });
  };

  const checkboxOptions = [
    {
      checked: filters.membership,
      key: 'membership',
      label: 'Membership',
    },
    {
      checked: filters.personal,
      key: 'personal',
      label: 'Personal',
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomFilterOptions
        dateType="validAt"
        dateValue={filters.validAt}
        checkboxes={checkboxOptions}
        handleApplyFilters={handleApplyFilters}
        setFilters={setFilters}
        onClearFilters={handleClearFilters}
      />
      <QuerySuspense query={{ isLoading }}>
        <Box
          sx={{
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          {noFiltersApplied() ? (
            <NoContentMessage infoMessage={t('offerDetails.noData')} />
          ) : (
            <Grid container width="100%">
              <Grid size={{ xs: 12, sm: 4, md: 3, lg: 2.2, xl: 2.3 }}>
                <OfferList
                  offers={offers}
                  selectedOffer={selectedOffer}
                  setSelectedOffer={setSelectedOffer}
                  setSearchTerm={setSearchTerm}
                  filterOffers={filterOffers}
                />
              </Grid>
              {selectedOffer && (
                <>
                  <Grid size={{ xs: 12, sm: 8, md: 3.5, lg: 2.8, xl: 1.9 }}>
                    <OfferDetails offer={selectedOffer} />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 5.5, lg: 7, xl: 7.8 }}>
                    <ProductList products={selectedOffer.products} />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Box>
      </QuerySuspense>
    </Box>
  );
};

export default OffersTab;

import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ShippingAndBillingItem from '@/components/sections/ecommerce/details/ShippingAndBillingItem';
import {
  StyledBorderCard,
  StyledTitle,
} from '@/components/shared/mui-helpers/customComponentStyles';
import { Address } from '@/services/orderService.interface';

interface CustomerAddressProps {
  shippingAddress: Address;
  billingAddress: Address;
}

interface ShippingAndBillingDetail {
  icon: React.ElementType;
  primary: string;
  key: keyof Address;
}

const shippingAndBillingDetails: ShippingAndBillingDetail[] = [
  { icon: PersonIcon, primary: t('customerDetails.address.name'), key: 'name' },
  {
    icon: EmailIcon,
    primary: t('customerDetails.address.email'),
    key: 'email',
  },
  {
    icon: PhoneIphoneIcon,
    primary: t('customerDetails.address.mobile'),
    key: 'mobile',
  },
  {
    icon: LocationCityIcon,
    primary: t('customerDetails.address.street'),
    key: 'street',
  },
];

const CustomerAddress: React.FC<CustomerAddressProps> = ({
  shippingAddress,
  billingAddress,
}) => {
  const { t } = useTranslation();

  const renderShippingAndBillingDetails = (
    details: ShippingAndBillingDetail[],
    address: Address,
  ) => (
    <List sx={{ padding: '0', marginY: '0' }}>
      {details.map(({ icon, primary, key }, index) => (
        <React.Fragment key={key}>
          <ShippingAndBillingItem
            key={key}
            icon={icon}
            primary={primary}
            address={address}
            itemKey={key}
          />
          {index !== details.length - 1 && <Divider variant="fullWidth" />}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Stack spacing={2}>
      <StyledTitle variant="h6" my={2}>
        {t('customerDetails.address.billingTitle')}
      </StyledTitle>
      <StyledBorderCard>
        {renderShippingAndBillingDetails(
          shippingAndBillingDetails,
          billingAddress,
        )}
      </StyledBorderCard>
      <StyledTitle variant="h6" my={2}>
        {t('customerDetails.address.shippingTitle')}
      </StyledTitle>

      <StyledBorderCard>
        {renderShippingAndBillingDetails(
          shippingAndBillingDetails,
          shippingAddress,
        )}
      </StyledBorderCard>
    </Stack>
  );
};

export default CustomerAddress;

import React from 'react';

import ListItemWithIcon from '@/components/shared/mui-helpers/ListItemWithIcon';
import { Address } from '@/services/orderService.interface';
import { addressFormatter } from '@/utils/addressFormatter';

interface DetailItemProps {
  icon: React.ElementType;
  primary: string;
  address: Address;
  itemKey: keyof Address;
}

const ShippingAndBillingItem: React.FC<DetailItemProps> = ({
  icon: Icon,
  primary,
  address,
  itemKey,
}) => {
  const formatSecondaryText = (
    key: keyof Address,
    address: Address,
  ): string => {
    switch (key) {
      case 'street':
        return addressFormatter(address);
      default:
        return address[key] ? address[key].toString() : '';
    }
  };

  return (
    <>
      <ListItemWithIcon
        icon={<Icon color="primary" />}
        primary={primary}
        secondary={formatSecondaryText(itemKey, address)}
      />
    </>
  );
};

export default ShippingAndBillingItem;
